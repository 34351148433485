import { Box, FormControl, FormHelperText, Grid, Link, MenuItem, Select, Typography, FormLabel } from "@mui/material";
import { BasicTooltip } from "raci-react-library";
import React, { useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { SelectLowerExcessMessage } from "../../../../shared/constants/helperText";
import format from "../../../../shared/utils/currency";

export interface ExcessFormControlProps {
  id: string;
  value: number;
  options: number[];
  error?: boolean;
  onChange?: (newValue: number) => void;
  onBlur?: () => void;
  inputRef: React.Ref<any>;
}

export const ExcessFormControl = ({
  id,
  value,
  options,
  error,
  onChange,
  onBlur,
  inputRef,
}: ExcessFormControlProps) => {
  const [state, setState] = useState(value);
  const tooltipId = id ? `${id}-tooltip` : undefined;
  const excessOptions = options.map((excess) => {
    const key = `${id}-${excess}`;
    const displayValue = format(excess);
    return isMobileOnly ? (
      <option key={key} value={excess}>
        {displayValue}
      </option>
    ) : (
      <MenuItem key={key} value={excess}>
        {displayValue}
      </MenuItem>
    );
  });

  return (
    <FormControl
      error={error}
      component={Grid}
      container
      direction="row"
      sx={{ flexDirection: "row", margin: 0 }}
      alignItems="center"
      spacing={1}
    >
      <FormLabel sx={{ paddingLeft: 0 }} component={Grid} item xs={8} htmlFor={id}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          Choose your excess{" "}
          <BasicTooltip
            id={tooltipId}
            title="Choose your excess"
            smallButton={true}
            message={
              <Typography>
                An excess is an amount you may have to contribute when making a claim. If you adjust your excess, your
                policy premium will change. For more details, see our{" "}
                <Link
                  id="pdsLink"
                  data-testid="pdsLink"
                  variant="inherit"
                  color="inherit"
                  href={process.env.REACT_APP_RAC_PDS_URL}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  PED Guide
                </Link>
              </Typography>
            }
          />
        </Box>
      </FormLabel>
      <Grid item xs={4}>
        <Select
          variant="outlined"
          id={id}
          name={id}
          data-testid={id}
          fullWidth
          native={isMobileOnly}
          color="primary"
          aria-label="Choose your excess"
          value={state}
          onChange={(event) => {
            const newValue = Number(event.target.value);
            const currentValue = state;
            if (currentValue !== newValue) {
              window.formotivConfig &&
                window.formotiv?.inputActivity?.(window.formotivConfig, id, "dropdown", newValue);
              onChange && onChange(newValue);
            }
            setState(newValue);
          }}
          onBlur={() => onBlur && onBlur()}
          inputRef={inputRef}
        >
          {excessOptions}
        </Select>
      </Grid>
      <FormHelperText sx={{ margin: 0, paddingLeft: 0 }} component={Grid} item xs={12} hidden={!error}>
        {SelectLowerExcessMessage}
      </FormHelperText>{" "}
    </FormControl>
  );
};

export default ExcessFormControl;
