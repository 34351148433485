import { event, gtm } from "@racwa/analytics";
import { BodyCopy, FinePrint, RacwaDetailedDialog } from "@racwa/react-components";
import { InsurancePhoneNumberLink } from "raci-react-library";
import { useEffect, useState } from "react";
import { StyledButton, StyledDialogActions } from "./styled";

interface ContentProps {
  policyNumber?: string;
}

const PaymentProcessingFailureContent = ({ policyNumber }: ContentProps) => {
  const policyNumberElementId = "try-again-dialog-span-policy-number";
  return (
    <>
      <BodyCopy gutterBottom>
        <strong>For your safety, we issued your policy and emailed your policy documents.</strong>
      </BodyCopy>
      <br />
      <BodyCopy gutterBottom>
        <strong>
          You can check your payment details and try again. Alternatively, call us on{" "}
          <InsurancePhoneNumberLink id="call-us-link" /> to finalise payment.
        </strong>
      </BodyCopy>
      <br />
      <FinePrint gutterBottom>
        Your policy number is{" "}
        <span id={policyNumberElementId} data-testid={policyNumberElementId}>
          {policyNumber}
        </span>
      </FinePrint>
      <br />
    </>
  );
};

const PaymentSetupFailureContent = () => {
  return (
    <>
      <BodyCopy gutterBottom>
        <strong>
          Please check your details and try again. Alternatively, you can pay via bank account or try a different
          payment frequency.
        </strong>
      </BodyCopy>
      <br />
    </>
  );
};

interface ActionProps {
  onDoneClick: () => void;
  onTryAgainClick: () => void;
}

const Actions = ({ onDoneClick, onTryAgainClick }: ActionProps) => {
  const btnDoneId = "btn-done";
  const btnTryAgainId = "btn-try-again";

  return (
    <StyledDialogActions>
      <StyledButton id={btnDoneId} data-testid={btnDoneId} name={btnDoneId} onClick={onDoneClick}>
        Done
      </StyledButton>
      <StyledButton
        id={btnTryAgainId}
        data-testid={btnTryAgainId}
        name={btnTryAgainId}
        onClick={onTryAgainClick}
        color="primary"
      >
        Try Again
      </StyledButton>
    </StyledDialogActions>
  );
};

export interface TryAgainDialogProps {
  open?: boolean;
  policyNumber?: string;
  onDoneClick?: () => void;
  onTryAgainClick?: () => void;
}

export const TryAgainDialog = ({ open, policyNumber, onDoneClick, onTryAgainClick }: TryAgainDialogProps) => {
  const [isOpen, setIsOpen] = useState(open || false);
  const onDoneClickWrapper = () => {
    setIsOpen(false);
    onDoneClick && onDoneClick();
  };
  const onTryAgainClickWrapper = () => {
    setIsOpen(false);
    onTryAgainClick && onTryAgainClick();
  };
  useEffect(() => {
    setIsOpen(open || false);
    if (open && policyNumber === "") {
      gtm(event("Something went wrong - monthly cc"));
    } else if (open) {
      gtm(event("Something went wrong"));
    }
  }, [open, policyNumber]);

  const dialog =
    policyNumber === "" ? (
      <RacwaDetailedDialog
        open={isOpen}
        onClickClose={onTryAgainClickWrapper}
        title="Something went wrong we could not setup your payments"
        titleId="payment-setup-failure-dialog"
        maxWidth="xs"
        customChildren={[
          {
            dialogueContent: false,
            node: <PaymentSetupFailureContent />,
          },
          {
            dialogueContent: false,
            node: (
              <StyledButton
                id="btn-close"
                data-testid="btn-close"
                name="btn-close"
                onClick={onTryAgainClickWrapper}
                color="primary"
              >
                Close
              </StyledButton>
            ),
          },
        ]}
      />
    ) : (
      <RacwaDetailedDialog
        open={isOpen}
        onClickClose={onTryAgainClickWrapper}
        title="Something went wrong we could not process your payment"
        titleId="try-again-dialog"
        maxWidth="xs"
        customChildren={[
          {
            dialogueContent: false,
            node: <PaymentProcessingFailureContent policyNumber={policyNumber} />,
          },
          {
            dialogueContent: false,
            node: <Actions onDoneClick={onDoneClickWrapper} onTryAgainClick={onTryAgainClickWrapper} />,
          },
        ]}
      />
    );

  return isOpen ? dialog : null;
};

export default TryAgainDialog;
