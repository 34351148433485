import useApiClient from "../../../../hooks/useApiClient";
import { FormotivModelScoreResponse } from "../../../../hooks/useApiClient/ClientProxy";
import {
  FORMOTIV_DIALOG_ENABLED,
  FORMOTIV_INVALID_MODEL_SCORE,
  FORMOTIV_SAMPLE_PROPORTION,
  FORMOTIV_SCORE_LOWER_BOUNDARY,
  FORMOTIV_SCORE_UPPER_BOUNDARY,
  STORAGE_KEY_FORMOTIV_SHOW_DIALOG,
} from "../../constants";

const modelScoreIsWithinBoundaries = (score: FormotivModelScoreResponse["score"]) =>
  score >= FORMOTIV_SCORE_LOWER_BOUNDARY && score <= FORMOTIV_SCORE_UPPER_BOUNDARY;

const isRandomlySampled = () => Math.random() < FORMOTIV_SAMPLE_PROPORTION;

export const useSetShowFormotivDialog = () => {
  const apiClient = useApiClient();

  return async () => {
    const alreadyDetermined = !!sessionStorage.getItem(STORAGE_KEY_FORMOTIV_SHOW_DIALOG);
    if (!FORMOTIV_DIALOG_ENABLED || alreadyDetermined) {
      return;
    }

    let score = FORMOTIV_INVALID_MODEL_SCORE;

    try {
      const token = window.formotivConfig?.getValue?.("state.multipagetoken");

      if (token) {
        const response = await apiClient.getformotivmodelscore(token);
        score = response.result.score;
      }
    } catch (e) {}

    const showDialog = isRandomlySampled() && modelScoreIsWithinBoundaries(score);
    sessionStorage.setItem(STORAGE_KEY_FORMOTIV_SHOW_DIALOG, showDialog.toString());

    if (window.formotivConfig?.state) {
      window.formotivConfig.state.ptv5 = showDialog ? "Y" : "N";
      window.formotiv?.submit?.(window.formotivConfig);
    }
  };
};

export default useSetShowFormotivDialog;
