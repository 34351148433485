export const RequiredSelectionMessage = "Please select an option from above.";

export const RequiredSelection = "Please select an option.";

export const InvalidDobMessage = "You must be aged between 16 and 100";

export const RequiredAmountMessage = "Please enter an amount above.";

export const RequiredFinancierMessage = "Please enter a financier.";

export const RequiredSuburbMessage = "Please select a suburb";

export const SelectPolicyStartDateMessage = "Please select a policy start date";

export const PleaseSelectYesOrNo = "Please select Yes or No";

export const SelectLowerExcessMessage = "Please choose a lower excess";

export const pleaseEnterAValidMessage = (fieldName: string) => `Please enter a valid ${fieldName}`;

export interface ExternalLink {
  href: string;
  display: string;
}

export const Raci13PhoneNumber: ExternalLink = {
  href: "tel:131703",
  display: "13 17 03",
};

export const FindAnInsurer: ExternalLink = {
  href: "https://www.findaninsurer.com.au",
  display: "Find an Insurer",
};
