import { Grid, Typography } from "@mui/material";
import { FinePrint, RacwaEdit } from "@racwa/react-components";
import { format } from "date-fns";
import { useGetSessionState } from "raci-react-library";
import { Link as RouterLink } from "react-router-dom";
import { QuoteRoutes, quoteRoutes } from "../../../../shared/routing/routes.config";
import { AreYouAMemberPageData, RiderDetailsPageData } from "../../../types";

export interface AboutYouProps {
  isEditLocked?: boolean;
}

export const AboutYou = ({ isEditLocked }: AboutYouProps) => {
  const riderDetailsPageData = useGetSessionState<RiderDetailsPageData>(QuoteRoutes.AboutYou);

  const { areYouAMember } = useGetSessionState<AreYouAMemberPageData>(QuoteRoutes.MemberDetails);

  const bikeUsage = () => {
    switch (riderDetailsPageData.selectedBikeUsageOption) {
      case "FoodDelivery":
        return "Uses bike for food delivery services";
      case "Courier":
        return "Uses bike for courier service";
      default:
        return "No food delivery / courier service usage";
    }
  };

  const riderExperience = riderDetailsPageData.selectedRiderExperience
    ? riderDetailsPageData.selectedRiderExperience === "0-1"
      ? "less then 1 year"
      : `${riderDetailsPageData.selectedRiderExperience} years`
    : "";

  const dateOfBirth = format(new Date(riderDetailsPageData.dateOfBirth ?? new Date()), "dd/MM/yyyy");

  const renderDateOfBirth = areYouAMember ? null : (
    <Grid item xs={10}>
      <FinePrint>{`Date of birth ${dateOfBirth}`}</FinePrint>
    </Grid>
  );

  const renderEdit = isEditLocked ? null : (
    <Grid item xs={2} textAlign="right">
      <RacwaEdit link={quoteRoutes.RiderDetails.path} dataTestId="editAboutYouLink" component={RouterLink} />
    </Grid>
  );

  return (
    <Grid container spacing={1} alignItems="center" data-testid="aboutYouQuoteContainer">
      <Grid item xs={12}>
        <Typography variant="h4">About you</Typography>
      </Grid>
      {renderDateOfBirth}
      <Grid item xs={10}>
        <FinePrint>{`Held licence for ${riderExperience}`}</FinePrint>
      </Grid>
      {renderEdit}
      <Grid item xs={10}>
        <FinePrint data-testid="bikeUsage">{bikeUsage()}</FinePrint>
      </Grid>
    </Grid>
  );
};

export default AboutYou;
