import { useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { QuoteRoutes, UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL } from "../../shared/routing/routes.config";
import { AreYouAMemberPageData, RiderDetailsPageData } from "../types";
import { RiderDetailsForm, RiderDetailsFormData } from "./Form";
import useRiderDetailsQuestionsData from "./hooks/useRiderDetailsQuestionsData";
import { useBikeUsageOptions } from "./hooks/useRiderDetailsReferenceData";

const RiderDetailsPage = () => {
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const [state] = useSessionState<RiderDetailsPageData>();
  const { areYouAMember } = useGetSessionState<AreYouAMemberPageData>(QuoteRoutes.MemberDetails);
  const { onSubmit, ...riderDetailsProps } = useRiderDetailsQuestionsData(
    state.dateOfBirth ? new Date(state.dateOfBirth.toString()) : null,
    state.selectedBikeUsageOption,
    state.selectedRiderExperience,
  );
  const bikeUsageReferenceData = useBikeUsageOptions();
  const handleSubmit = async (newState: RiderDetailsFormData) => {
    setBackdrop(true);
    await onSubmit(newState);
    setBackdrop(false);
  };

  const isLoading = bikeUsageReferenceData.isLoading === true || riderDetailsProps.isLoading === true;

  useEffect(() => {
    setBackdrop(isLoading);
    return () => {
      setBackdrop(false);
    };
  }, [setBackdrop, isLoading]);

  if (riderDetailsProps.isError || bikeUsageReferenceData.isError) {
    navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
  }

  return (
    <RiderDetailsForm
      {...state}
      {...riderDetailsProps}
      disallowEditDateOfBirth={!!areYouAMember}
      bikeUsageOptions={bikeUsageReferenceData.bikeUsageOptions}
      onSubmit={handleSubmit}
    />
  );
};

export default RiderDetailsPage;
