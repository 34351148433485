import { Button, Typography } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { RacwaDetailedDialog } from "@racwa/react-components";
import { useEffect } from "react";
import useShowFormotivDialog from "./hooks/useShowFormotivDialog";

const dialogId = "formotiv-dialog";
const titleId = `${dialogId}-title`;
const closeButtonId = `${dialogId}-close-btn`;

const dialogTitle = "Did you know?";
const dialogCopy = "We guarantee the workmanship and materials used for our repairs for the life of your motorcycle.";
const closeButtonTextContent = "Close";

export const FormotivDialog = () => {
  const [show, setShown] = useShowFormotivDialog();

  useEffect(() => {
    gtm(event(`Popup display - ${dialogTitle}`));
  }, []);

  const handleClose = (closeType: "dialog" | "button") => {
    gtm(event(closeType === "dialog" ? "Popup - close" : `Popup CTA click - ${closeButtonTextContent}`));
    setShown();
  };

  return (
    <RacwaDetailedDialog
      title={dialogTitle}
      id={dialogId}
      data-testid={dialogId}
      titleId={titleId}
      maxWidth="xs"
      open={show}
      onClickClose={() => handleClose("dialog")}
      customChildren={[
        {
          dialogueContent: false,
          node: <Typography sx={{ marginBottom: "24px" }}>{dialogCopy}</Typography>,
        },
        {
          dialogueContent: false,
          node: (
            <Button
              id={closeButtonId}
              data-testid={closeButtonId}
              name="btn-close"
              onClick={() => handleClose("button")}
              color="primary"
            >
              {closeButtonTextContent}
            </Button>
          ),
        },
      ]}
    />
  );
};

export default FormotivDialog;
