import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaAutocomplete } from "@racwa/react-components";
import { Control, Controller, FieldError } from "react-hook-form";
import { RequiredSelection } from "../../../../shared/constants/helperText";
import { Manufacturer } from "../../../../shared/hooks/useApiClient/ClientProxy";

export const inputId = "manufacturer-input";
export const inputContainerId = `${inputId}-container`;

const getSelectedValue = (items: Manufacturer[], externalCode?: string | null): Manufacturer | null => {
  const matchedItems = items.filter((item) => item.externalCode === externalCode);
  return matchedItems.length > 0 ? matchedItems[0] : null;
};

export interface ManufacturerProps {
  data: Manufacturer[];
  selectedManufacturer?: string | null;
  onSelection?: (manufacturerExternalCode: Manufacturer | null) => void;
  isLoading?: boolean | false;
  isError?: boolean | false;
  control?: Control<any>;
  error?: FieldError;
}

export const ManufacturerSelection = ({
  data,
  selectedManufacturer,
  onSelection,
  isLoading,
  control,
  error,
}: ManufacturerProps) => (
  <Controller
    control={control}
    name="selectedManufacturer"
    defaultValue={selectedManufacturer}
    rules={{
      required: { value: true, message: RequiredSelection },
    }}
    render={({ field: { ref, onBlur, onChange, ...props } }) => (
      <RacwaAutocomplete
        {...props}
        id={inputId}
        data-testid={inputContainerId}
        label="Make"
        aria-label="Make"
        placeholder="Enter make"
        options={data}
        getOptionLabel={(option) => option.name}
        groupBy={(option) => (option.isPopular ? "Most popular makes" : "Other")}
        fullWidth
        autoComplete={true}
        loading={isLoading}
        autoHighlight={true}
        clearOnEscape={true}
        onChange={(event, manufacturer: Manufacturer | null) => {
          window.formotivConfig &&
            window.formotiv?.inputActivity?.(
              window.formotivConfig,
              "selectedManufacturer",
              "autocomplete",
              manufacturer,
            );
          onSelection && onSelection(manufacturer);
          onChange && onChange(event);
        }}
        onBlur={() => {
          gtm(fieldTouched("Make"));
          onBlur && onBlur();
        }}
        value={getSelectedValue(data, selectedManufacturer)}
        textFieldProps={{ name: inputId, "data-testid": inputId }}
        error={!!error}
        helperText={error?.message}
        inputRef={ref}
      />
    )}
  />
);
export default ManufacturerSelection;
