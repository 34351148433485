import { useEffect, useState } from "react";
import { selector, useRecoilValue } from "recoil";
import { useApiClient as ApiClient } from "../../../../shared/hooks/useApiClient";
import {
  ApiException,
  ReferenceDataItem,
  RiderDetailsReferenceData,
} from "../../../../shared/hooks/useApiClient/ClientProxy";

/* This recoil selector is retriggered every time a different bike selection is made (to get the correct market value) */
const riderDetailsReferenceDataQuery = selector({
  key: "riderDetailsReferenceDataQuery",
  get: async () => {
    const apiClient = ApiClient();
    return (await apiClient.getriderdetailsreferencedata())?.result;
  },
});

interface UseRiderDetailsReferenceDataResult extends RiderDetailsReferenceData {
  isError?: boolean | false;
  isLoading?: boolean | false;
}
/* This hook will be called every time a component that uses it renders, but it can refer to application state. */
export const useBikeUsageOptions = (): UseRiderDetailsReferenceDataResult => {
  const riderDetailsReferenceDataValue = useRecoilValue(riderDetailsReferenceDataQuery);

  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [bikeUsageOptions, setBikeUsageOptions] = useState<ReferenceDataItem[] | undefined>([]);

  useEffect(() => {
    let isCancelled = false;

    const fetchReferenceData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        if (!isCancelled) {
          setBikeUsageOptions(riderDetailsReferenceDataValue.bikeUsageOptions);
        }
      } catch (error) {
        const errorResponse = error as ApiException;
        if (errorResponse.status >= 500) {
          setIsError(true);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchReferenceData();
    return () => {
      isCancelled = true;
    };
  }, [riderDetailsReferenceDataValue]);

  return {
    isError,
    isLoading,
    bikeUsageOptions,
  };
};

export default useBikeUsageOptions;
