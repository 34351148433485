import { Box, FormControl, FormHelperText, Grid, FormLabel } from "@mui/material";
import { BasicTooltip } from "raci-react-library";
import React, { useState } from "react";
import CurrencyInput from "../../../../shared/components/CurrencyInput";
import format from "../../../../shared/utils/currency";

export interface SumInsuredFormControlProps {
  id?: string;
  value: number;
  min: number;
  max: number;
  onChange?: (newValue: number) => void;
  onError?: () => void;
  onBlur?: () => void;
  inputRef?: React.Ref<any>;
}

export const SumInsuredFormControl = ({
  id,
  value,
  min,
  max,
  onChange,
  onError,
  onBlur,
  inputRef,
}: SumInsuredFormControlProps) => {
  const tooltipId = id ? `${id}-tooltip` : undefined;
  const errorMessage = `Based on current market value, we will insure you for an amount between ${format(
    min,
  )} and ${format(max)}`;
  const [state, setState] = useState<string | number | undefined>(value);
  const [hasError, setHasError] = useState<boolean>(false);

  return (
    <FormControl
      error={hasError}
      component={Grid}
      direction="row"
      sx={{ flexDirection: "row", margin: 0 }}
      container
      alignItems="center"
      spacing={1}
    >
      <FormLabel sx={{ paddingLeft: 0 }} htmlFor={id} component={Grid} item xs={8}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          Choose insured value{" "}
          <BasicTooltip
            id={tooltipId}
            title="Choose insured value"
            smallButton={true}
            message="The insured value is the amount we agree to insure your bike for.
                   It includes accessories, GST, rego and any other 'on-road' costs.
                   If you adjust the insured value, between the range provided,
                   your policy premium will change."
          />
        </Box>
      </FormLabel>
      <Grid item xs={4}>
        <CurrencyInput
          id={id}
          name={id}
          data-testid={id}
          color="primary"
          fullWidth
          value={state}
          inputProps={{
            "aria-label": "Choose insured value",
          }}
          error={hasError}
          onValueChange={(newValue) => {
            setState(newValue?.floatValue);
            const isError = !newValue?.floatValue || newValue.floatValue < min || newValue.floatValue > max;
            setHasError(isError);
            if (isError) {
              onError && onError();
            }
          }}
          onBlur={() => {
            if (!hasError && typeof state === "number") {
              onChange && onChange(state);
              onBlur && onBlur();
            }
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              e.currentTarget.blur();
            }
          }}
          inputRef={inputRef}
        />
      </Grid>
      <FormHelperText sx={{ margin: 0, paddingLeft: 0 }} component={Grid} item xs={12} hidden={!hasError}>
        {errorMessage}
      </FormHelperText>
    </FormControl>
  );
};

export default SumInsuredFormControl;
