import { event, gtm } from "@racwa/analytics";
import { format } from "date-fns";
import { useSessionState, useSetBackdrop } from "raci-react-library";
import { useEffect, useState } from "react";
import { ContactMatchSyncErrorStatusCode, MultiMatchStatusCode, SuccessStatusCode } from "../../../../shared/constants";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { ApiException, MatchContact } from "../../../../shared/hooks/useApiClient/ClientProxy";
import { PolicyRoutes, QuoteRoutes } from "../../../../shared/routing/routes.config";
import { AreYouAMemberPageData, RiderDetailsPageData, TellUsMoreAboutYouPageData } from "../../../types";
import { AreYouAMemberFormData, AreYouAMemberFormProps } from "../../Form";

export interface ContactMatchingData {
  redirectToNextPage: () => void;
}

const timeout = 2000;

const getQueryFromForm = (data: AreYouAMemberFormData): MatchContact => ({
  firstName: data.firstName || "",
  // Requires to be ignored as Typescript requires Date type
  // but serialises date in the wrong format
  // @ts-ignore
  dateOfBirth: (data.dateOfBirth && format(data.dateOfBirth, "yyyy-MM-dd")) || null,
  email: data.email || "",
  mobileNumber: data.contactNumber?.replace(/\s/g, "") || "",
});

const hasMemberDetailsRecorded = (data: AreYouAMemberPageData): boolean | null => {
  let result: boolean | null = null;
  // Use != to check for both null and undefined
  if (data.areYouAMember != null) {
    result = !!(data.contactNumber && data.firstName && data.dateOfBirth && data.email);
  }

  return result;
};

const NotFound = 404;
const TooManyRequests = 429;
const isHandledErrorResponse = (exception?: any): boolean => {
  const errorResponse = exception as ApiException;
  return (
    errorResponse &&
    (errorResponse.status === NotFound ||
      errorResponse.status === MultiMatchStatusCode ||
      errorResponse.status === TooManyRequests)
  );
};

const gtmEvent = (exception?: any) => {
  const errorResponse = exception as ApiException;

  if (errorResponse && errorResponse.status === MultiMatchStatusCode) {
    gtm(event("multi match"));
  }

  if (errorResponse && errorResponse.status === NotFound) {
    gtm(event("contact not found"));
  }

  if (errorResponse && errorResponse.status === TooManyRequests) {
    gtm(event("match attempts exhausted"));
  }
};

const useContactMatching = ({ redirectToNextPage }: ContactMatchingData): AreYouAMemberFormProps => {
  const apiClient = useApiClient();
  const setBackdrop = useSetBackdrop();
  const [membershipTier, setMembershipTier] = useState("None");

  const [hasMatched, setHasMatched] = useState<boolean | null>(null);
  const [isContactMatchAttemptsExhausted, setIsContactMatchAttemptsExhausted] = useState<boolean>(false);

  const [state, setRecoilState] = useSessionState<AreYouAMemberPageData>();
  const [currentTellUsMoreAboutYouState, setTellUsMoreAboutYouPageData] = useSessionState<TellUsMoreAboutYouPageData>({
    specificKey: PolicyRoutes.PersonalInformation,
  });
  const [currentRiderDetailsState, setRiderDetailsPageData] = useSessionState<RiderDetailsPageData>({
    specificKey: QuoteRoutes.AboutYou,
  });
  const [isError, setIsError] = useState(false);

  const setState = (newState: AreYouAMemberPageData, synchronise?: boolean | null): void => {
    let newStateToSave: AreYouAMemberPageData = {
      areYouAMemberSelection: newState.areYouAMemberSelection,
      areYouAMember: newState.areYouAMember,
      isCompleted: newState.isCompleted,
    };
    let newTellUsMoreAboutYouState: TellUsMoreAboutYouPageData = {};
    const newRiderDetailsState: RiderDetailsPageData = {
      ...currentRiderDetailsState,
    };

    if (synchronise) {
      newTellUsMoreAboutYouState = {
        ...currentTellUsMoreAboutYouState,
        firstName: newState.firstName,
        email: newState.email,
        contactNumber: newState.contactNumber,
      };
      newRiderDetailsState.dateOfBirth = newState.dateOfBirth;
    }

    newStateToSave = {
      areYouAMember: newState.areYouAMember,
      areYouAMemberSelection: newState.areYouAMemberSelection,
      firstName: newState.firstName,
      contactNumber: newState.contactNumber,
      dateOfBirth: newState.dateOfBirth,
      email: newState.email,
      isCompleted: newState.isCompleted,
    };

    setTellUsMoreAboutYouPageData(newTellUsMoreAboutYouState);
    setRiderDetailsPageData(newRiderDetailsState);
    setRecoilState(newStateToSave);
  };

  const onSkip = async (areYouAMemberSelection?: string | null) => {
    await apiClient.deletematchedcontactfromsession();
    setState({
      areYouAMember: false,
      areYouAMemberSelection: areYouAMemberSelection,
      isCompleted: true,
    });
    redirectToNextPage();
  };

  const onAreYouAMemberSelection = async (newState?: string | null) => {
    if (newState && newState === "yes") {
      setState({
        areYouAMember: true,
        areYouAMemberSelection: newState,
      });
    } else if (newState === "no" || newState === "skip" || (newState === null && state.areYouAMember === false)) {
      await onSkip(newState);
    }
  };

  const onSubmit = async (details: AreYouAMemberFormData): Promise<boolean> => {
    let isSuccessful = false;
    setIsError(false);
    if (!hasMatched) {
      try {
        setBackdrop(true);
        const query = getQueryFromForm(details);
        const response = await apiClient.matchcontact(query);
        if (response.status === SuccessStatusCode) {
          setMembershipTier(response.result.membershipTier);
          response.result.status === ContactMatchSyncErrorStatusCode
            ? gtm(event("member data sync error"))
            : gtm(event("member found"));
        }
        setState({ ...state, ...details, areYouAMember: true, isCompleted: true }, true);
        setHasMatched(true);
        isSuccessful = true;
      } catch (exception) {
        if (isHandledErrorResponse(exception)) {
          gtmEvent(exception);
          setIsContactMatchAttemptsExhausted((exception as ApiException).status === TooManyRequests);
          setState({ ...state, ...details, areYouAMember: true, isCompleted: true }, true);
          setHasMatched(false);
        } else {
          setIsError(true);
          return false;
        }
      } finally {
        setBackdrop(false);
      }
    }
    return isSuccessful;
  };

  useEffect(() => {
    let isCancelled = false;

    const checkContactMatchAttemptsExhausted = async () => {
      if (!isCancelled) {
        try {
          await apiClient.contactmatchattemptsexhausted();
        } catch (exception) {
          if ((exception as ApiException)?.status === TooManyRequests) {
            gtm(event("match attempts exhausted"));
            setIsContactMatchAttemptsExhausted(true);
          } else {
            setIsContactMatchAttemptsExhausted(false);
          }
        }
      }
    };

    checkContactMatchAttemptsExhausted();

    return () => {
      isCancelled = true;
    };
  }, [apiClient]);

  useEffect(() => {
    if (!hasMatched) {
      return;
    }

    const timeoutCallback = setTimeout(() => redirectToNextPage(), timeout);
    return () => clearTimeout(timeoutCallback);
  }, [redirectToNextPage, hasMatched]);

  const dateOfBirth = state.dateOfBirth ? new Date(state.dateOfBirth) : undefined;

  return {
    ...state,
    dateOfBirth,
    isError,
    isContactMatchAttemptsExhausted,
    membershipTier,
    onSkip,
    onSubmit,
    onAreYouAMemberSelection,
  };
};

export default useContactMatching;
