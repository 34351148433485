import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { FormEvent, useRef } from "react";
import { SetValueConfig, useForm } from "react-hook-form";
import BikeModelSelection, { BikeModelProps } from "./components/BikeModelSelection";
import EngineCapacitySelection, { EngineCapacityProps } from "./components/EngineCapacity";
import ManufacturerSelection, { ManufacturerProps } from "./components/ManufacturerSelection";
import YearsSelection, { YearSelectionProps } from "./components/YearSelection";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import {useFormotiv} from 'raci-react-library'

export interface BikeSelectionFormData {
  manufacturerProps: ManufacturerProps;
  yearSelectionProps: YearSelectionProps;
  bikeModelProps: BikeModelProps;
  engineCapacityProps: EngineCapacityProps;
}

export interface BikeSelectionFormHandlers {
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void | Promise<void>;
}

export type BikeSelectionFormProps = BikeSelectionFormData & BikeSelectionFormHandlers;

export type BikeSelectionFormFields =
  | "selectedManufacturer"
  | "selectedYear"
  | "selectedModel"
  | "selectedEngineCapacity";

export const resetFormValues = (
  name: BikeSelectionFormFields,
  setValue: (name: BikeSelectionFormFields, value: any, config?: SetValueConfig) => void,
  clearErrors: (name?: BikeSelectionFormFields | BikeSelectionFormFields[]) => void,
) => {
  switch (name) {
    case "selectedManufacturer":
      setValue("selectedYear", null, { shouldDirty: true });
      setValue("selectedModel", null, { shouldDirty: true });
      setValue("selectedEngineCapacity", null, { shouldDirty: true });
      clearErrors(["selectedYear", "selectedModel", "selectedEngineCapacity"]);
      break;
    case "selectedYear":
      setValue("selectedModel", null, { shouldDirty: true });
      setValue("selectedEngineCapacity", null, { shouldDirty: true });
      clearErrors(["selectedModel", "selectedEngineCapacity"]);
      break;
    case "selectedModel":
      setValue("selectedEngineCapacity", null, { shouldDirty: true });
      clearErrors("selectedEngineCapacity");
      break;
  }
};

export const BikeSelectionForm = ({
  manufacturerProps,
  bikeModelProps,
  engineCapacityProps,
  yearSelectionProps,
  onSubmit,
}: BikeSelectionFormProps) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    clearErrors,
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      selectedManufacturer: manufacturerProps.selectedManufacturer,
      selectedYear: yearSelectionProps.selectedYear,
      selectedModel: bikeModelProps.selectedModel,
      selectedEngineCapacity: engineCapacityProps.selectedEngineCapacity,
    },
  });

  const formRef = useRef(null);
  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);
  const onSubmitWrapper = ((e: any) => {
    onSubmit && onSubmit(e);
  });

  return (
    <form ref={formRef} action="#" onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmitWrapper))}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h2">Let's start with your bike</Typography>
        </Grid>
        <Grid item xs={12}>
          <ManufacturerSelection
            {...manufacturerProps}
            control={control}
            onSelection={(manufacturer) => {
              resetFormValues("selectedManufacturer", setValue, clearErrors);
              manufacturerProps.onSelection && manufacturerProps.onSelection(manufacturer);
            }}
            error={errors.selectedManufacturer}
          />
        </Grid>
        <Grid item xs={12}>
          <YearsSelection
            {...yearSelectionProps}
            control={control}
            onSelection={(year, resetList) => {
              resetFormValues("selectedYear", setValue, clearErrors);
              yearSelectionProps.onSelection && yearSelectionProps.onSelection(year, resetList);
            }}
            error={errors.selectedYear}
          />
        </Grid>
        <Grid item xs={12}>
          <BikeModelSelection
            {...bikeModelProps}
            control={control}
            onSelection={(bikeModel, resetList) => {
              resetFormValues("selectedModel", setValue, clearErrors);
              bikeModelProps.onSelection && bikeModelProps.onSelection(bikeModel, resetList);
            }}
            error={errors.selectedModel}
          />
        </Grid>
        <Grid item xs={12}>
          <EngineCapacitySelection {...engineCapacityProps} control={control} error={errors.selectedEngineCapacity} />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" data-testid="submit" color="primary" variant="contained" fullWidth={true}>
            Next
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default BikeSelectionForm;
