import { FormotivConfigOptions } from "raci-react-library/dist/hooks/useFormotiv/types";
export const NonBreakingSpace = "\u00a0";
export const MultiMatchStatusCode = 409;
export const ContactMatchSyncErrorStatusCode = 299;
export const SuccessStatusCode = 200;
export const AccountNameValidationPattern = /(?!\s)^[a-zA-Z\s'\-—’&`]*$/;
export const AccountNameSizeLimit = 40;
export const SESSION_KEY_PREFIX = "RAC_MCO_";
export const ComprehensiveTitle = "Comprehensive";
export const ThirdPartyFireAndTheftTitle = "Third party fire and theft";
export const ThirdPartyPropertyTitle = "Third party property damage";
export const ShieldEnvironmentStorageKey = "ShieldEnvironment";
export const ShieldEnvironmentDefaultValue = "Default";
export const FeatureFlagStorageKeyPrefix = "Feature_";

export const FORMOTIV_CONFIG = {
  appId: process.env.REACT_APP_FORMOTIV_APP_ID,
  consoleUrl: process.env.REACT_APP_FORMOTIV_CONSOLE_URL,
  apiUrl: process.env.REACT_APP_FORMOTIV_API_URL,
  environment: process.env.REACT_APP_ENVIRONMENT,
  logLevel: process.env.REACT_APP_FORMOTIV_LOGGING ?? undefined,
} as FormotivConfigOptions;
