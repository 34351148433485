import CloseIcon from "@mui/icons-material/Close";
import { Snackbar } from "@mui/material";
import Slide, { SlideProps } from "@mui/material/Slide";
import React from "react";
import { useSetRecoilState } from "recoil";
import { toastNotificationData } from "../../recoil/atoms";
import { StyledIconButton, StyledSnackbarContent } from "./styled";

type TransitionProps = Omit<SlideProps, "direction">;

export interface ToastNotificationProps {
  component?: React.ReactNode | null;
}

const SlideTransition = (props: TransitionProps) => {
  return <Slide {...props} direction="up" />;
};

const ToastNotification = (props: ToastNotificationProps) => {
  const setToastDisplay = useSetRecoilState(toastNotificationData);
  const onClose = () => setToastDisplay({ component: null });

  return (
    <Snackbar
      id="toastNotification"
      data-testid="toastNotification"
      open={props.component !== null}
      autoHideDuration={10000}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      children={
        <StyledSnackbarContent
          message={props.component}
          action={
            <StyledIconButton size="small" aria-label="close" onClick={onClose}>
              <CloseIcon fontSize="small" />
            </StyledIconButton>
          }
        />
      }
      TransitionComponent={SlideTransition}
    />
  );
};

export default ToastNotification;
