import { selector, useRecoilValueLoadable } from "recoil";
import { useApiClient as ApiClient } from "../../../../shared/hooks/useApiClient";

/* This recoil selector depends on no atoms so will run once here at "application state" level and never be retriggered */
export const suburbQuery = selector({
  key: "suburbQuery",
  get: async () => {
    const apiClient = ApiClient();
    return (await apiClient.getsuburbs()).result;
  },
});

/* This hook will be called every time a component that uses it renders, but it can refer to application state. */
export const useSuburbs = () => {
  return useRecoilValueLoadable(suburbQuery);
};
