import { useEffect, useState } from "react";
import { selector, useRecoilValue } from "recoil";
import { useApiClient as ApiClient } from "../../../../shared/hooks/useApiClient";
import { ApiException, PaymentReferenceData } from "../../../../shared/hooks/useApiClient/ClientProxy";

const paymentPageReferenceData = selector({
  key: "paymentPageReferenceData",
  get: async () => {
    const apiClient = ApiClient();
    return (await apiClient.getpaymentreferencedata())?.result;
  },
});

interface UseReferenceDataResult extends Omit<PaymentReferenceData, "paymentOptions"> {
  isError?: boolean | false;
  isLoading?: boolean | false;
}

export const useReferenceData = (): UseReferenceDataResult => {
  const paymentReferenceDataValue = useRecoilValue(paymentPageReferenceData);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [westpacApiKey, setWestpacApiKey] = useState<string>("");

  useEffect(() => {
    let isCancelled = false;

    const fetchReferenceData = () => {
      setIsError(false);
      setIsLoading(true);
      try {
        if (!isCancelled) {
          setWestpacApiKey(paymentReferenceDataValue.westpacPublishableApiKey);
        }
      } catch (error) {
        const errorResponse = error as ApiException;
        if (errorResponse.status >= 500) {
          setIsError(true);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchReferenceData();

    return () => {
      isCancelled = true;
    };
  }, [paymentReferenceDataValue]);

  return {
    westpacPublishableApiKey: westpacApiKey,
    isError,
    isLoading,
  };
};

export default useReferenceData;
