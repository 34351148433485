import { FormControl, FormHelperText, FormLabel, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { Control, Controller, FieldError, UseFormSetValue } from "react-hook-form";
import { RequiredSelectionMessage } from "../../../../shared/constants/helperText";
import { ReferenceDataItem } from "../../../../shared/hooks/useApiClient/ClientProxy";
import { RiderDetailsFormData } from "../../Form";

export interface RiderExperienceProps {
  control: Control<any>;
  setValue: UseFormSetValue<RiderDetailsFormData>;
  riderExperienceOptions: ReferenceDataItem[] | undefined;
  selectedRiderExperience?: string | null;
  riderExperienceError?: FieldError;
  onChange?: (newValue: string | null) => void;
}

export const RiderExperience = ({
  control,
  setValue,
  riderExperienceOptions,
  selectedRiderExperience = "",
  riderExperienceError,
  onChange,
}: RiderExperienceProps) => {
  return riderExperienceOptions && riderExperienceOptions.length > 0 ? (
    <FormControl error={!!riderExperienceError}>
      <FormLabel data-testid="formlabelRiderExperienceSelection">
        How many years have you held your motorcycle licence?
      </FormLabel>
      <Controller
        control={control}
        name="selectedRiderExperience"
        defaultValue={selectedRiderExperience}
        rules={{
          required: {
            value: true,
            message: RequiredSelectionMessage,
          },
        }}
        render={({ field: { ref, onBlur, onChange: onComponentChange, ...props } }) => (
          <ToggleButtonGroup
            {...props}
            onChange={(_, value) => {
              window.formotivConfig &&
                window.formotiv?.inputActivity?.(
                  window.formotivConfig,
                  "selectedRiderExperience",
                  "buttongroup",
                  value,
                );
              onComponentChange && onComponentChange(value);
              onChange && onChange(value);
              setValue(props.name, value, {
                shouldDirty: true,
                shouldValidate: true,
              });
            }}
            onBlur={() => {
              onBlur && onBlur();
              gtm(fieldTouched("How many years have you held your motorcycle licence?"));
            }}
            exclusive
            id="selectedRiderExperience"
            data-testid="selectedRiderExperience"
            aria-label="selectedRiderExperience"
          >
            {riderExperienceOptions?.map((title: ReferenceDataItem, index: number) => {
              return (
                <ToggleButton
                  type="button"
                  role="button"
                  key={title.externalCode}
                  value={title.externalCode}
                  aria-label={title.description}
                  data-testid={`title-option-${title.description}`}
                  ref={index === 0 ? ref : null}
                >
                  {title.description}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        )}
      />
      <FormHelperText>{riderExperienceError ? RequiredSelectionMessage : ""}</FormHelperText>
    </FormControl>
  ) : null;
};

export default RiderExperience;
