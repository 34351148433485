import { selector, useRecoilValueLoadable } from "recoil";
import { useApiClient as ApiClient } from "../../../../shared/hooks/useApiClient";

/* This recoil selector is retriggered every time a different bike selection is made (to get the correct market value) */
export const tellUsMoreAboutYourBikeRefDataQuery = selector({
  key: "tellUsMoreAboutYourBikeRefDataQuery",
  get: async () => {
    const apiClient = ApiClient();
    return (await apiClient.gettellmoreaboutyourbikerefdata()).result;
  },
});

/* This hook will be called every time a component that uses it renders, but it can refer to application state. */
export const useTellUsMoreAboutYourBikeRefData = () => {
  return useRecoilValueLoadable(tellUsMoreAboutYourBikeRefDataQuery);
};

export default useTellUsMoreAboutYourBikeRefData;
