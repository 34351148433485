import { RacwaStandardPageTemplateProps } from "@racwa/react-components";
import { useLocation } from "react-router-dom";
import { utilityRoutes } from "../../routing/routes.config";
import usePage from "../usePage";

export const useStandardPage = (): RacwaStandardPageTemplateProps => {
  const location = useLocation();
  const commonPageProps = usePage();

  const currentStandardPageRoute = utilityRoutes.find(
    (route) => route.path.toLowerCase() === location.pathname.toLowerCase(),
  );

  const heading = currentStandardPageRoute?.heading ?? "";

  return {
    heading,
    navBreadcrumbProps: commonPageProps.responsiveHeaderProps?.NavBreadcrumbsProps,
    ...commonPageProps,
  };
};

export default useStandardPage;
