import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaAutocomplete } from "@racwa/react-components";
import { Control, Controller, FieldError } from "react-hook-form";
import { RequiredSelection } from "../../../../shared/constants/helperText";

export const inputId = "engineCapacity-input";
export const inputContainerId = `${inputId}-container`;

export interface EngineCapacityProps {
  data: string[];
  selectedEngineCapacity: string | null;
  onSelection: (engineCapacity: string | null, resetList?: boolean | false) => void;
  control?: Control<any>;
  error?: FieldError;
}
const EngineCapacitySelection = ({
  data,
  selectedEngineCapacity,
  onSelection,
  control,
  error,
}: EngineCapacityProps) => {
  const isEngineCapacityListWithMoreThanOneOption: boolean = data && data.length > 1;

  const isEngineCapacityListWithSingleOption: boolean = data && data.length === 1;

  const isDisabled = !isEngineCapacityListWithMoreThanOneOption;

  return (
    <Controller
      control={control}
      name="selectedEngineCapacity"
      defaultValue={selectedEngineCapacity}
      rules={{
        required: {
          value: !isDisabled,
          message: RequiredSelection,
        },
      }}
      render={({ field: { ref, onBlur, onChange, ...props } }) => (
        <RacwaAutocomplete
          {...props}
          id={inputId}
          data-testid={inputContainerId}
          label="Engine capacity"
          aria-label="Engine capacity"
          placeholder="CC"
          options={data}
          autoSelect={isDisabled}
          getOptionLabel={(option) => option}
          fullWidth
          autoComplete
          autoHighlight
          clearOnEscape
          disabled={!isEngineCapacityListWithMoreThanOneOption}
          value={isEngineCapacityListWithSingleOption ? data[0] : selectedEngineCapacity}
          textFieldProps={{ name: inputId, "data-testid": inputId }}
          onChange={(event, engineCapacity: string | null) => {
            window.formotivConfig &&
              window.formotiv?.inputActivity?.(
                window.formotivConfig,
                "selectedEngineCapacity",
                "autocomplete",
                engineCapacity,
              );
            onSelection && onSelection(engineCapacity);
            onChange && onChange(event);
          }}
          onBlur={() => {
            gtm(fieldTouched("Engine Capacity"));
            onBlur && onBlur();
          }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
        />
      )}
    />
  );
};

export default EngineCapacitySelection;
