import { HTTP_STATUS_CODE_OK, useSessionIdStorageKey } from "raci-react-library";
import { useEffect, useState } from "react";
import useApiClient from "../useApiClient";

export const useCreateSession = (): "loading" | "successful" | "error" => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const apiClient = useApiClient();
  const sessionIdKey = useSessionIdStorageKey();

  useEffect(() => {
    let isCancelled = false;

    const createSession = async () => {
      setIsError(false);
      try {
        if (!isCancelled && !sessionStorage.getItem(sessionIdKey)) {
          const response = await apiClient.createsession();
          sessionStorage.setItem(sessionIdKey, response.result);
          if (response.status !== HTTP_STATUS_CODE_OK) {
            setIsError(true);
          }
        }
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    createSession();

    return () => {
      isCancelled = true;
    };
  }, [apiClient, sessionIdKey]);

  return isLoading ? "loading" : isError ? "error" : "successful";
};

export default useCreateSession;
