import { FormControl, FormLabel, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { StyledSublabel } from "./styled";

export interface AreYouAMemberProps {
  areYouAMemberSelection?: string | null | undefined;
  control: Control<any>;
  areYouAMemberSelectionHandler: (value: string) => void | Promise<void>;
  disabled?: boolean;
}

export const AreYouAMember = ({
  areYouAMemberSelection,
  control,
  areYouAMemberSelectionHandler,
  disabled,
}: AreYouAMemberProps) => {
  return (
    <FormControl disabled={disabled}>
      <FormLabel data-testid="formlabelAreYouAnRACMember">
        Are you an RAC member?
        <StyledSublabel variant="body2">
          We'll use this to pre-fill your details and apply eligible discounts.
        </StyledSublabel>
      </FormLabel>
      <Controller
        control={control}
        name="areYouAMemberSelection"
        defaultValue={areYouAMemberSelection}
        render={({ field: { onChange, ...props } }) => (
          <ToggleButtonGroup
            {...props}
            onChange={(_, value) => {
              const inputNameSuffix = (value ?? props.value).toLowerCase()[0];
              window.formotivConfig &&
                window.formotiv?.inputActivity?.(
                  window.formotivConfig,
                  `areYouAMemberSelection_${inputNameSuffix}`,
                  "buttongroup",
                  value,
                );
              areYouAMemberSelectionHandler(value) && onChange(value);
            }}
            exclusive
            id="areYouAMemberButtonGroup"
            data-testid="areYouAMemberButtonGroup"
            aria-label="areYouAMemberButtonGroup"
          >
            <ToggleButton type="button" role="button" key="yes" value="yes" data-testid="yesButton" disabled={disabled}>
              Yes
            </ToggleButton>
            <ToggleButton type="button" role="button" key="no" value="no" data-testid="noButton" disabled={disabled}>
              No
            </ToggleButton>
            <ToggleButton
              type="button"
              role="button"
              key="skipButton"
              value="skip"
              data-testid="skipButton"
              disabled={disabled}
            >
              Skip
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      />
    </FormControl>
  );
};

export default AreYouAMember;
