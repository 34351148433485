import { event, gtm } from "@racwa/analytics";
import { format } from "date-fns";
import { useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useSetShowFormotivDialog from "../../../../shared/components/FormotivDialog/hooks/useSetShowFormotivDialog";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { ApiException, QuoteRequest } from "../../../../shared/hooks/useApiClient/ClientProxy";
import {
  QUOTE_YOUR_QUOTE_PAGE_URL,
  QuoteRoutes,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../../../../shared/routing/routes.config";
import { YourQuoteState } from "../../../YourQuote/types";
import {
  AreYouAMemberPageData,
  BikeDetailsPageData,
  BikeSelectionPageData,
  RiderDetailsPageData,
} from "../../../types";
import { RiderDetailsFormData, RiderDetailsFormProps } from "../../Form";
import { useBikeUsageOptions } from "../useRiderDetailsReferenceData";
import useRiderExperience from "../useRiderExperience";

const parseDate = (date: any): Date => {
  return new Date(Date.parse(date));
};

const createQuoteRequest = (
  { areYouAMember }: AreYouAMemberPageData,
  { selectedVehicleId: vehicleId }: BikeSelectionPageData,
  bikeDetailsPageData: BikeDetailsPageData,
  { dateOfBirth, selectedRiderExperience: experience, selectedBikeUsageOption: usage }: RiderDetailsFormData,
): QuoteRequest => ({
  asset: {
    vehicleId: vehicleId || -1,
    annualKilometreage: bikeDetailsPageData.selectedAnnualKmsOption || "",
    postcode: bikeDetailsPageData.selectedSuburb?.postcode || "",
    suburb: bikeDetailsPageData.selectedSuburb?.name || "",
    financier: bikeDetailsPageData.selectedFinancier?.externalFinancierNumber,
    isKeptInGarageOvernight: bikeDetailsPageData.isGaraged || false,
    securityFittings: !bikeDetailsPageData.selectedSecurityOptions ? [] : bikeDetailsPageData.selectedSecurityOptions,
    usesDashcam: bikeDetailsPageData.isDashcam,
    usage: usage || undefined,
  },
  riders: [
    {
      //@ts-ignore
      experience: experience || "",
      //@ts-ignore
      dateOfBirth:
        !areYouAMember && dateOfBirth
          ? //@ts-ignore
            format(dateOfBirth, "yyyy-MM-dd")
          : null,
    },
  ],
});

export interface UseRiderDetailsQuestionDataResult extends Omit<RiderDetailsFormProps, "control" | "onSubmit"> {
  onSubmit: (riderDetails: RiderDetailsFormData) => Promise<void>;
  showMoreInfoCard: boolean;
  showDeclinedUsageCard: boolean;
}

export const useRiderDetailsQuestionsData = (
  dateOfBirth?: Date | null,
  bikeUsageExternalCode?: string | null,
  riderExperienceExternalCode?: string | null,
): UseRiderDetailsQuestionDataResult => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const bikeUsageReferenceData = useBikeUsageOptions();
  const { list: riderExperiences, onDateOfBirthChange } = useRiderExperience(dateOfBirth);
  const setShowFormotivDialog = useSetShowFormotivDialog();
  const [, setRiderDetailsState] = useSessionState<RiderDetailsPageData>();
  const [quoteState, setQuoteState] = useSessionState<YourQuoteState>({
    specificKey: QuoteRoutes.YourQuote,
    skipPageTrackingRecalculation: true,
  });
  const areYouAMemberPageDetails = useGetSessionState<AreYouAMemberPageData>(QuoteRoutes.MemberDetails);
  const bikeSelectionPageDetails = useGetSessionState<BikeSelectionPageData>(QuoteRoutes.YourBike);
  const bikePageDetails = useGetSessionState<BikeDetailsPageData>(QuoteRoutes.BikeUsage);
  const [selectedBikeUsageOption] = useState<string | null>(bikeUsageExternalCode || null);
  const [isError, setIsError] = useState(false);
  const [showMoreInfoCard, setShowMoreInfoCard] = useState(false);
  const [showDeclinedUsageCard, setDeclinedUsageCard] = useState(false);

  const gtmExceptionEvent = () => {
    if (quoteState && quoteState.quoteNumber) {
      gtm(event("update quote failure"));
    } else {
      gtm(event("create quote failure"));
    }
  };

  const onSubmit = async (riderPageDetails: RiderDetailsFormData) => {
    if (riderPageDetails.selectedBikeUsageOption === "HiringOut") {
      gtm(event("Unable to insure motorcycles that are hired out"));
      setDeclinedUsageCard(true);
      return;
    }
    const request = createQuoteRequest(
      areYouAMemberPageDetails,
      bikeSelectionPageDetails,
      bikePageDetails,
      riderPageDetails,
    );
    try {
      setBackdrop(true);
      setDeclinedUsageCard(false);
      const { result } = await apiClient.createquote(request);
      setQuoteState({
        ...result,
        startDate: parseDate(result.startDate),
        maxStartDate: parseDate(result.maxStartDate),
        selectedPaymentFrequency: quoteState.selectedPaymentFrequency,
      });
      setRiderDetailsState({
        dateOfBirth: riderPageDetails.dateOfBirth,
        selectedBikeUsageOption: riderPageDetails.selectedBikeUsageOption,
        selectedRiderExperience: riderPageDetails.selectedRiderExperience,
        isCompleted: true,
      });

      await setShowFormotivDialog();

      navigate(QUOTE_YOUR_QUOTE_PAGE_URL);
    } catch (exception) {
      const errorResponse = exception as ApiException;
      if (errorResponse.status >= 500) {
        setIsError(true);
        gtmExceptionEvent();
        navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
      }
      setShowMoreInfoCard(true);
    } finally {
      setBackdrop(false);
    }
  };

  return {
    bikeUsageOptions: bikeUsageReferenceData.bikeUsageOptions,
    riderExperiences,
    selectedBikeUsageOption,
    selectedRiderExperience: riderExperienceExternalCode,
    isError,
    dateOfBirthChangeHandler: onDateOfBirthChange,
    onSubmit,
    showMoreInfoCard,
    showDeclinedUsageCard,
    quoteNumber: quoteState.quoteNumber,
  };
};

export default useRiderDetailsQuestionsData;
