import { useGetSessionState } from "raci-react-library";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { toastNotificationData } from "../../shared/recoil/atoms";
import { PolicyRoutes, policyRoutes, quoteRoutes } from "../../shared/routing/routes.config";
import { PaymentPageData } from "../types";
import { AreYouAMemberForm } from "./Form";
import MemberDiscountToastNotification, { DiscountTier } from "./components/MemberDiscountToastNotification";
import useContactMatching from "./hooks/useContactMatching";

export const AreYouAMemberPage = () => {
  const [isPageCompleted, setIsPageCompleted] = useState(false);
  const redirectToNextPage = () => setIsPageCompleted(true);
  const props = useContactMatching({ redirectToNextPage });
  const setToastNotification = useSetRecoilState(toastNotificationData);
  const { isAnnualCCPaymentInProgress } = useGetSessionState<PaymentPageData>(PolicyRoutes.Payment);

  if (isPageCompleted) {
    return <Navigate to={quoteRoutes.BikeSelection.path} />;
  }

  if (isAnnualCCPaymentInProgress) {
    return <Navigate to={policyRoutes.Payment.path} />;
  }

  if (
    props.membershipTier === DiscountTier.Gold ||
    props.membershipTier === DiscountTier.Silver ||
    props.membershipTier === DiscountTier.Bronze
  ) {
    setToastNotification({
      component: <MemberDiscountToastNotification membershipTier={props.membershipTier} />,
    });
  }

  return <AreYouAMemberForm {...props} />;
};

export default AreYouAMemberPage;
