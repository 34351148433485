import { Button, Grid, Typography } from "@mui/material";
import { BirthdayConfetti, DateOfBirthInput, isBirthdayToday, isValidDate, useSessionState, useFormotiv } from "raci-react-library";
import React, { useEffect, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import DeclinedUsageCardNotification from "../../shared/components/DeclinedUsageCardNotification";
import NeedMoreInformationCardNotification from "../../shared/components/NeedMoreInformationCardNotification";
import { ReferenceDataItem } from "../../shared/hooks/useApiClient/ClientProxy";
import { RiderDetailsPageData } from "../types";
import BikeUsageSelect from "./components/BikeUsageSelect";
import RiderExperience from "./components/RiderExperience";
import { FORMOTIV_CONFIG } from "../../shared/constants";

export interface RiderDetailsFormData {
  bikeUsageOptions?: ReferenceDataItem[];
  riderExperiences: ReferenceDataItem[] | undefined;
  dateOfBirth?: Date | null;
  selectedRiderExperience?: string | null;
  selectedBikeUsageOption?: string | null;
  isError?: boolean | false;
  isLoading?: boolean | false;
  showMoreInfoCard?: boolean;
  showDeclinedUsageCard?: boolean;
  quoteNumber?: string;
  dateOfBirthChangeHandler: (date: Date) => void;
}

export interface AdditionalRiderDetailsFormProps {
  disallowEditDateOfBirth?: boolean;
}

export interface RiderDetailsFormHandlers {
  onSubmit?: (newState: RiderDetailsFormData) => void | Promise<void>;
}

export type RiderDetailsFormProps = RiderDetailsFormData & RiderDetailsFormHandlers & AdditionalRiderDetailsFormProps;

export const RiderDetailsForm = ({
  riderExperiences,
  bikeUsageOptions,
  dateOfBirth,
  selectedRiderExperience: initialSelectedRiderExperience,
  selectedBikeUsageOption,
  disallowEditDateOfBirth,
  showMoreInfoCard,
  showDeclinedUsageCard,
  quoteNumber,
  dateOfBirthChangeHandler,
  onSubmit,
}: RiderDetailsFormProps) => {
  const [selectedRiderExperience, setSelectedRiderExperience] = useState(initialSelectedRiderExperience);
  const [dateOfBirthInput, setDateOfBirthInput] = useState(dateOfBirth);

  const [riderDetailsState] = useSessionState<RiderDetailsPageData>();
  const form = useForm<RiderDetailsFormData>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      dateOfBirth: (riderDetailsState.dateOfBirth && new Date(riderDetailsState.dateOfBirth)) || null,
    },
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    trigger,
  } = form;

  const [showBirthdayConfetti, setShowBirthdayConfetti] = useState(false);
  const formRef = useRef(null);
  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);

  const onSubmitWrapper = (() => {
    const formValues = getValues();
    if (disallowEditDateOfBirth) {
      formValues.dateOfBirth = dateOfBirth;
    }
    onSubmit &&
      onSubmit({
        ...formValues,
        dateOfBirthChangeHandler: formValues.dateOfBirthChangeHandler as (date: Date) => void,
        selectedRiderExperience: selectedRiderExperience,
      });
    trigger();
  });

  const dateOfBirthChangeHandlerWrapper = (newDate: Date | null) => {
    if (isValidDate(newDate)) {
      const currentDate = dateOfBirthInput;

      if (newDate && newDate !== currentDate) {
        setSelectedRiderExperience(null);
        setValue("selectedRiderExperience", null);
        setValue("dateOfBirth", newDate);
        setDateOfBirthInput(newDate);
      }

      window.formotivConfig &&
        window.formotiv?.inputActivity?.(window.formotivConfig, "selectedDateOfBirth", "dateOfBirthInput", newDate);
    }
  };

  const birthdayConfetti = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event?.target?.value && isBirthdayToday(event.target.value.replaceAll(" ", ""))
      ? setShowBirthdayConfetti(true)
      : setShowBirthdayConfetti(false);
  };

  useEffect(() => {
    if (riderExperiences && riderExperiences.length === 1) {
      setSelectedRiderExperience(riderExperiences[0].externalCode);
    }
  }, [setSelectedRiderExperience, riderExperiences]);

  const bikeUsageLabel = "What do you use your motorcycle for?";
  const moreInfoCard = showMoreInfoCard ? (
    <Grid item xs={12}>
      <NeedMoreInformationCardNotification quoteNumber={quoteNumber ?? ""} />
    </Grid>
  ) : null;

  const bikeUsageCoverDeclineCard = showDeclinedUsageCard ? (
    <Grid item xs={12}>
      <DeclinedUsageCardNotification />
    </Grid>
  ) : null;

  return (
    <FormProvider {...form}>
      <form action="#" onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmitWrapper))}>
        {showBirthdayConfetti ? <BirthdayConfetti /> : null}
        <Grid container spacing={1}>
          <Typography variant="h2" id="header" data-testid="header">
            Now, a bit about you
          </Typography>

          {!disallowEditDateOfBirth && (
            <Grid item xs={12}>
              <DateOfBirthInput onBlur={birthdayConfetti} onChange={dateOfBirthChangeHandlerWrapper} />
            </Grid>
          )}

          {riderExperiences && riderExperiences.length > 1 ? (
            <Grid item xs={12}>
              <RiderExperience
                control={control}
                setValue={setValue}
                selectedRiderExperience={selectedRiderExperience}
                riderExperienceOptions={riderExperiences}
                riderExperienceError={errors.selectedRiderExperience}
                onChange={setSelectedRiderExperience}
              />
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <Controller
              name="selectedBikeUsageOption"
              defaultValue={selectedBikeUsageOption ?? ""}
              control={control}
              aria-label={bikeUsageLabel}
              rules={{
                required: {
                  value: true,
                  message: bikeUsageLabel,
                },
                validate: {
                  valid: (value: string | null | undefined) => !(showDeclinedUsageCard && value === "HiringOut"),
                },
              }}
              render={({ field: { ref, onChange: onComponentChange, ...props } }) => (
                <BikeUsageSelect
                  {...props}
                  bikeUsageOptions={bikeUsageOptions}
                  label={bikeUsageLabel}
                  error={!!errors.selectedBikeUsageOption}
                  helperText={errors.selectedBikeUsageOption?.message || ""}
                  inputRef={ref}
                  onChange={(e) => {
                    const selectedBikeUsageOption = e.target.value as string;
                    window.formotivConfig &&
                      window.formotiv?.inputActivity?.(
                        window.formotivConfig,
                        "selectedBikeUsageOption",
                        "dropdown",
                        selectedBikeUsageOption,
                      );
                    onComponentChange && onComponentChange(selectedBikeUsageOption);
                  }}
                />
              )}
            />
          </Grid>

          {moreInfoCard ?? bikeUsageCoverDeclineCard}

          <Grid item xs={12}>
            <Button
              type="submit"
              data-testid="riderDetailsSubmit"
              color="primary"
              variant="contained"
              fullWidth
              disabled={showMoreInfoCard}
            >
              View your quote options
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default RiderDetailsForm;
