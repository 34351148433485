import { RacwaBackdrop, RacwaThemeProvider } from "@racwa/react-components";
import { Backdrop, SessionStatePrefixProvider, TrackPageChanges } from "raci-react-library";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { MutableSnapshot, RecoilRoot } from "recoil";
import ConnectWithRecoilValue from "./shared/components/ConnectWithRecoilValue";
import ToastNotification from "./shared/components/Notifications/ToastNotification";
import { SESSION_KEY_PREFIX } from "./shared/constants";
import { atoms, toastNotificationData } from "./shared/recoil/atoms";
import Views from "./views";

const lookupState = (key: string) => {
  // Add Atoms(units of state) into this array
  return [...atoms].filter((item) => item.key === key)[0];
};

const initialiseState = ({ set }: MutableSnapshot) => {
  // Central place to load up atoms(units of state) from sessionStorage
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i) || "";
    if (key.startsWith(SESSION_KEY_PREFIX)) {
      const value = sessionStorage.getItem(key) || "";
      const stateItem = lookupState(key);
      if (stateItem) {
        set(stateItem, JSON.parse(value));
      }
    }
  }
};

export const App = () => (
  <RacwaThemeProvider>
    <RecoilRoot initializeState={initialiseState}>
      <Suspense fallback={<RacwaBackdrop open={true} />}>
        <Backdrop />
        <BrowserRouter>
          <SessionStatePrefixProvider prefix={SESSION_KEY_PREFIX}>
            <Views />
            <ConnectWithRecoilValue component={ToastNotification} recoilValue={toastNotificationData} />
          </SessionStatePrefixProvider>
          <TrackPageChanges />
        </BrowserRouter>
      </Suspense>
    </RecoilRoot>
  </RacwaThemeProvider>
);

export default App;
