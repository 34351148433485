import { useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { Navigate, useNavigate } from "react-router-dom";
import {
  POLICY_PERSONAL_INFORMATION_PAGE_URL,
  PolicyRoutes,
  QuoteRoutes,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
  policyRoutes,
  quoteRoutes,
} from "../../shared/routing/routes.config";
import { YourQuoteState } from "../YourQuote/types";
import { LetsClarifyMoreDetailsPageData, PaymentPageData } from "../types";
import LetsClarifyMoreDetailsForm, { LetsClarifyMoreDetailsFormAnswers } from "./Form";
import { useLetsClarifyMoreDetails } from "./hooks/useLetsClarifyMoreDetails";

const LetsClarifyMoreDetailsPage = () => {
  const [quoteState, setQuoteState] = useSessionState<YourQuoteState>({ specificKey: QuoteRoutes.YourQuote });
  const {
    quoteNumber,
    startDate: selectedDate,
    maxStartDate: maxSelectableDate,
    isCompleted: previousPageCompleted,
  } = quoteState;
  const setBackdrop = useSetBackdrop();
  const [state, setState] = useSessionState<LetsClarifyMoreDetailsPageData>();
  const { onSubmit, isError } = useLetsClarifyMoreDetails();
  const navigate = useNavigate();
  const { isAnnualCCPaymentInProgress } = useGetSessionState<PaymentPageData>(PolicyRoutes.Payment);
  const handleSubmit = async (newState: LetsClarifyMoreDetailsFormAnswers) => {
    try {
      setBackdrop(true);
      if (await onSubmit(newState)) {
        const newQuoteState = {
          ...quoteState,
          startDate: newState.selectedDate,
        };
        setQuoteState(newQuoteState);
        setState({ ...newState, isCompleted: true });
        navigate(POLICY_PERSONAL_INFORMATION_PAGE_URL);
      }
    } catch {
      // DO NOTHING
    }
    setBackdrop(false);
  };

  if (isError) {
    navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
  }

  if (!previousPageCompleted) {
    return <Navigate to={quoteRoutes.Quote.path} />;
  }

  if (isAnnualCCPaymentInProgress) {
    return <Navigate to={policyRoutes.Payment.path} />;
  }

  return (
    <LetsClarifyMoreDetailsForm
      {...{ ...state, quoteNumber, selectedDate, maxSelectableDate }}
      onSubmit={handleSubmit}
    />
  );
};

export default LetsClarifyMoreDetailsPage;
