import { event, gtm } from "@racwa/analytics";
import { PaymentFrequency, useSessionState, useSetBackdrop } from "raci-react-library";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { ApiException, Cover, CoverRequest } from "../../../../shared/hooks/useApiClient/ClientProxy";
import {
  POLICY_CONFIRM_POLICY_DETAILS_PAGE_URL,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../../../../shared/routing/routes.config";
import { QuoteCardData, QuoteCardOnChangeEvent, QuoteCardOnChooseThisCoverEvent } from "../../components/QuoteCard";
import { YourQuoteState } from "../../types";

export interface UseQuoteCardResults extends QuoteCardData {
  isError: boolean;
  isBadRequestError: boolean;
  onChange: (event: QuoteCardOnChangeEvent) => void;
  onChooseThisCoverClick: (event: QuoteCardOnChooseThisCoverEvent) => Promise<void>;
}

export const useQuoteCard = (data: QuoteCardData): UseQuoteCardResults => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const initialise = useRef(true);
  const [quoteState, setQuoteState] = useSessionState<YourQuoteState>();
  const [isError, setIsError] = useState(false);
  const [isBadRequestError, setIsBadRequestError] = useState(false);
  const [quoteCard, setQuoteCard] = useState(data);
  const [inputValues, setInputValues] = useState<{
    sumInsured?: number;
    excess: number;
  }>({ sumInsured: data.sumInsured, excess: data.excess });

  const handleChange = (e: QuoteCardOnChangeEvent) => {
    if (inputValues?.sumInsured !== e.sumInsured || inputValues?.excess !== e.excess) {
      setInputValues({
        sumInsured: e.sumInsured,
        excess: e.excess,
      });
    }
  };

  const redirectToSystemUnavailable = () => {
    navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
  };

  const redirectToFurtherDetailsPage = () => {
    navigate(POLICY_CONFIRM_POLICY_DETAILS_PAGE_URL);
  };

  const handleChooseThisCoverClick = async (e: QuoteCardOnChooseThisCoverEvent): Promise<void> => {
    setBackdrop(true);
    try {
      await updateQuoteCover({
        coverType: quoteCard.coverType,
        excess: quoteCard.excess,
        sumInsured: quoteCard.sumInsured,
        hasSelectedCover: true,
      });
      setQuoteState({
        ...quoteState,
        selectedCoverType: e.coverType,
        selectedPaymentFrequency: e.paymentFrequency ?? PaymentFrequency.Annual,
        isCompleted: true,
      });
      redirectToFurtherDetailsPage();
    } catch (ex) {
      redirectToSystemUnavailable();
    } finally {
      setBackdrop(false);
    }
  };

  const updateQuoteCover = async (coverRequest: CoverRequest, updateState: boolean = false) => {
    setIsError(false);
    setIsBadRequestError(false);

    setBackdrop(true);

    try {
      const { result } = await apiClient.updatequotecover({
        coverType: coverRequest.coverType,
        excess: coverRequest.excess,
        sumInsured: coverRequest.sumInsured,
        hasSelectedCover: coverRequest.hasSelectedCover,
      });

      if (updateState) {
        updateQuoteCoverState(result);
      }
    } catch (e) {
      const { status } = e as ApiException;
      if (status === 400) {
        gtm(event("update quote failure"));
        setIsBadRequestError(true);
      } else {
        setIsError(true);
      }
    } finally {
      setBackdrop(false);
    }
  };

  const updateQuoteCoverState = (cover: Cover) => {
    const covers = [...quoteState.covers];
    const i = covers.findIndex((c) => c.coverType === cover.coverType);
    if (i >= 0) {
      covers.splice(i, 1, cover);
      setQuoteState({ ...quoteState, covers, isCompleted: false });
    }
    setQuoteCard({
      ...quoteCard,
      maxSumInsured: cover.sumInsured?.max,
      minSumInsured: cover.sumInsured?.min,
      sumInsured: cover.sumInsured?.value,
      excess: cover.selectedExcess,
      excesses: cover.availableExcesses,
      annualCost: cover.premiumDetails.annual.total,
      monthlyCost: cover.premiumDetails.monthly.installment,
      basic: cover.premiumDetails.annual.basic,
      governmentCharges: cover.premiumDetails.annual.governmentCharges,
      gst: cover.premiumDetails.annual.gst,
      monthlyTotal: cover.premiumDetails.monthly.total,
      discounts: cover.discounts || [],
      premiumDetails: {
        monthly: {
          ...cover.premiumDetails.monthly,
          instalment: cover.premiumDetails.monthly.installment,
        },
        annual: cover.premiumDetails.annual,
      },
    });
  };

  useEffect(() => {
    let isCancelled = false;

    if (inputValues) {
      if (initialise.current) {
        initialise.current = false;
        return;
      }

      if (!isCancelled) {
        updateQuoteCover(
          {
            coverType: quoteCard.coverType,
            excess: inputValues.excess,
            sumInsured: inputValues.sumInsured,
          },
          true,
        );
      }
    }

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues]);

  return {
    coverType: quoteCard.coverType,
    title: quoteCard.title,
    excess: quoteCard.excess,
    excesses: quoteCard.excesses,
    sumInsured: quoteCard.sumInsured,
    minSumInsured: quoteCard.minSumInsured,
    maxSumInsured: quoteCard.maxSumInsured,
    annualCost: quoteCard.annualCost,
    monthlyCost: quoteCard.monthlyCost,
    discounts: quoteCard.discounts,
    isError,
    isBadRequestError,
    onChange: handleChange,
    onChooseThisCoverClick: handleChooseThisCoverClick,
    basic: quoteCard.basic,
    governmentCharges: quoteCard.governmentCharges,
    gst: quoteCard.gst,
    monthlyTotal: quoteCard.monthlyTotal,
    premiumDetails: quoteCard.premiumDetails,
  };
};

export default useQuoteCard;
