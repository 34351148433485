import { Button, Card, CardActions, CardContent, Container, Grid, Paper, Typography } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { theme } from "@racwa/react-components";
import { PaymentFrequency, PremiumDetails, QuotePremium } from "raci-react-library";
import { useRef, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CoverTooltip from "../../../../shared/components/CoverTooltip";
import { Discount } from "../../../../shared/hooks/useApiClient/ClientProxy";
import { UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL } from "../../../../shared/routing/routes.config";
import useQuoteCard from "../../hooks/useQuoteCard";
import ExcessFormControl from "../Excess";
import { PremiumBreakdownProps } from "../PremiumBreakdown";
import SumInsuredFormControl from "../SumInsured";

export interface QuoteCardOnChangeEvent {
  coverType: string;
  excess: number;
  sumInsured?: number;
  hasSelectedCover?: boolean;
  paymentFrequency?: PaymentFrequency;
}

export interface QuoteCardOnChooseThisCoverEvent extends QuoteCardOnChangeEvent {}

export interface QuoteCardData extends PremiumBreakdownProps {
  coverType: string;
  title: string;
  excess: number;
  excesses: number[];
  sumInsured?: number;
  minSumInsured?: number;
  maxSumInsured?: number;
  annualCost: number;
  monthlyCost: number;
  discounts: Discount[];
  premiumDetails: PremiumDetails;
}

export interface QuoteCardProps extends QuoteCardData {
  onChange?: (event: QuoteCardOnChangeEvent) => void;
  onChooseThisCoverClick?: (event: QuoteCardOnChooseThisCoverEvent) => void;
}

export const QuoteCard = (props: QuoteCardProps) => {
  const {
    coverType,
    title,
    excess,
    excesses,
    discounts,
    sumInsured,
    minSumInsured,
    maxSumInsured,
    premiumDetails,
    isError,
    isBadRequestError,
    onChange,
    onChooseThisCoverClick,
  } = useQuoteCard(props);
  const [state, setState] = useState<QuoteCardOnChangeEvent>(
    sumInsured ? { coverType, excess, sumInsured } : { coverType, excess },
  );
  const [hasErrors, setHasErrors] = useState(false);
  const excessRef = useRef<HTMLElement>();
  const sumInsuredRef = useRef<HTMLElement>();
  const coverTooltipId = `coverTooltipId-${coverType}`;
  const sumInsuredId = `sumInsured-${coverType}`;
  const chooseThisCoverButtonId = `choose-this-cover-${coverType}-button`;
  const excessId = `excess-${coverType}`;

  const handleChange = (event: QuoteCardOnChangeEvent) => {
    onChange && onChange(event);
    props.onChange && props.onChange(event);
  };

  const { control } = useFormContext();

  const paymentFrequency = useWatch({
    control: control,
    name: `${title.toLocaleLowerCase().replace(/ /g, "")}-payment-frequency`,
  }) as PaymentFrequency;

  const handleChooseThisCoverClick = async () => {
    gtm(fieldTouched(`Choose this cover - ${title}`));
    window.formotivConfig &&
      window.formotiv?.inputActivity?.(
        window.formotivConfig,
        `selectedCoverType_${props.coverType}`,
        "button",
        props.coverType,
      );
    onChooseThisCoverClick &&
      (await onChooseThisCoverClick({
        ...state,
        paymentFrequency: paymentFrequency ?? PaymentFrequency.Annual,
      }));
    props.onChooseThisCoverClick &&
      props.onChooseThisCoverClick({
        ...state,
        paymentFrequency: paymentFrequency ?? PaymentFrequency.Annual,
      });
  };

  const navigate = useNavigate();

  if (isError) {
    navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
  }

  return (
    <>
      <Card variant="outlined">
        <QuotePremium
          id={title.toLocaleLowerCase().replace(/ /g, "")}
          quoteHeading={
            <>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="h3">{title}</Typography>
                </Grid>
                <Grid container item xs={2} justifyContent="flex-end">
                  <CoverTooltip id={coverTooltipId} data-testid={coverTooltipId} coverType={coverType} />
                </Grid>
              </Grid>
            </>
          }
          premiumDetails={premiumDetails}
          discounts={discounts}
        ></QuotePremium>
        <CardContent
          sx={{ background: theme.palette.grey["200"], borderLeftWidth: 0, borderRightWidth: 0, paddingRight: "4px" }}
          component={Paper}
          variant="outlined"
          square
        >
          <Grid container>
            <ExcessFormControl
              id={excessId}
              data-testid={excessId}
              value={excess}
              options={excesses}
              error={isBadRequestError}
              onChange={(newValue) => {
                const newState = {
                  ...state,
                  excess: newValue,
                };
                setState(newState);
                if (!hasErrors) {
                  handleChange(newState);
                }
              }}
              onBlur={() => gtm(fieldTouched(`${title} - Choose your excess`))}
              inputRef={excessRef}
            />
            {!!state.sumInsured && minSumInsured && maxSumInsured ? (
              <SumInsuredFormControl
                id={sumInsuredId}
                data-testid={sumInsuredId}
                value={state.sumInsured}
                min={minSumInsured}
                max={maxSumInsured}
                onChange={(newValue) => {
                  const newState = {
                    ...state,
                    sumInsured: newValue,
                  };
                  setState(newState);
                  handleChange(newState);
                  setHasErrors(false);
                }}
                onError={() => {
                  setHasErrors(true);
                }}
                onBlur={() => gtm(fieldTouched(`${title} - Choose your SI`))}
                inputRef={sumInsuredRef}
              />
            ) : null}
          </Grid>
        </CardContent>
        <CardActions sx={{ background: theme.palette.common.white }}>
          <Container>
            <Button
              id={chooseThisCoverButtonId}
              data-testid={chooseThisCoverButtonId}
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => {
                if (!(hasErrors || isBadRequestError)) {
                  handleChooseThisCoverClick();
                }

                if (isBadRequestError) {
                  excessRef.current?.focus();
                } else if (hasErrors) {
                  sumInsuredRef.current?.focus();
                }
              }}
            >
              Choose this cover
            </Button>
          </Container>
        </CardActions>
      </Card>
    </>
  );
};

export default QuoteCard;
