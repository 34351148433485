import { useMsal } from "@azure/msal-react";
import { useAccount } from "@racwa/react-adb2c";
import { LookupServiceApiClient } from "raci-lookup-service-clientproxy";
import { useSessionIdStorageKey } from "raci-react-library";
import {
  DEFAULT_SHIELD_ENVIRONMENT,
  HTTP_HEADER_KEY_RACWA_NPE_AUTHORIZATION,
  HTTP_HEADER_KEY_SHIELD_ENVIRONMENT,
  useFeatureToggles,
  useShieldEnvironment,
} from "raci-react-library-npe";
import { useMemo } from "react";
import { UseApiClientProps } from "../../shared/hooks/useApiClient";
import { MotorcycleClient as ApiClient } from "../../shared/hooks/useApiClient/ClientProxy";

const useApi = <T>({ Client, apiBaseUrl }: UseApiClientProps<T>) => {
  const sessionIdKey = useSessionIdStorageKey();

  const { instance } = useMsal();
  const account = useAccount({
    clientId: process.env.REACT_APP_NPE_OAUTH_CLIENT_ID ?? undefined,
  });

  const { selected: selectedShieldEnv } = useShieldEnvironment();
  const [toggles] = useFeatureToggles();

  return useMemo(() => {
    const npeLoginRequest = {
      scopes: [],
      redirectUri: "/",
      account: account,
    };

    const fetchWrapper = async (url: RequestInfo, init?: RequestInit): Promise<Response> => {
      let token = "";

      try {
        const tokenResponse = await instance.acquireTokenSilent(npeLoginRequest);
        token = tokenResponse.idToken;
      } catch (ex) {
        await instance.acquireTokenRedirect(npeLoginRequest);
      }

      const headers: any = { ...init?.headers };
      const sessionId = sessionStorage.getItem(sessionIdKey);
      if (sessionId) {
        headers.SessionId = sessionId;
      }
      toggles?.forEach((toggle) => {
        if (toggle.enabled !== null) {
          headers[toggle.headerName] = toggle.enabled;
        }
      });
      if (selectedShieldEnv && selectedShieldEnv !== DEFAULT_SHIELD_ENVIRONMENT) {
        headers[HTTP_HEADER_KEY_SHIELD_ENVIRONMENT] = selectedShieldEnv;
      }
      if (token) {
        headers[HTTP_HEADER_KEY_RACWA_NPE_AUTHORIZATION] = token;
      }
      const response = await fetch(url, {
        ...init,
        headers,
      });
      return response;
    };

    return new Client(apiBaseUrl, {
      fetch: fetchWrapper,
    });
    // eslint-disable-next-line
  }, [account, sessionIdKey, toggles, selectedShieldEnv, instance]);
};

let useApiClient = () => {
  return useApi({ Client: ApiClient, apiBaseUrl: process.env.REACT_APP_API_BASE_URL || "" });
};

let useLookupApiClient = () => {
  return useApi({ Client: LookupServiceApiClient, apiBaseUrl: process.env.REACT_APP_LOOKUP_SERVICE_API_URL || "" });
};

if (process.env.REACT_APP_ENVIRONMENT === "UAT") {
  useApiClient = require("./uat").useApiClient;
  useLookupApiClient = require("./uat").useLookupApiClient;
}

export { useApiClient, useLookupApiClient };
export default useApiClient;
