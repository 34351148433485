import { useEffect, useState } from "react";
import { TitleReferenceDataItem } from "../../../../shared/hooks/useApiClient/ClientProxy";
import { TitleProps } from "../../components/Titles";

interface UseTitleResult extends Omit<TitleProps, "control" | "error"> {}

export const useTitles = (
  referenceDataTitles: TitleReferenceDataItem[] | null,
  titleExternalCode?: string | null,
): UseTitleResult => {
  const [titles, setTitles] = useState<TitleReferenceDataItem[] | null | undefined>([]);
  const [title, setTitle] = useState<string | null>(titleExternalCode || null);
  const [gender, setGender] = useState<string | null>(null);
  const [, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onSelection = (selectedTitle: string | null) => {
    setTitle(selectedTitle);
  };

  useEffect(() => {
    const titleData = titles && titles.find((x) => x.externalCode === title);
    setGender(titleData?.gender?.externalCode || null);
  }, [title, titles]);

  useEffect(() => {
    let isCancelled = false;

    const fetchTitles = () => {
      setIsError(false);
      setIsLoading(true);
      try {
        if (!isCancelled) {
          setTitles(referenceDataTitles);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchTitles();

    return () => {
      isCancelled = true;
    };
  }, [referenceDataTitles]);

  return {
    data: titles ?? null,
    title,
    gender,
    isLoading,
    titleSelectionHandler: onSelection,
  };
};

export default useTitles;
