import {
  dialogTitleClasses,
  Pagination,
  PaginationItem,
  paginationItemClasses,
  styled,
  Typography,
} from "@mui/material";
import { RacwaDetailedDialog } from "@racwa/react-components";

export const StyledCornerRibbonTypography = styled(Typography)(({ theme }) => ({
  width: "200px",
  background: `${theme.palette.primary.main}`,
  position: "absolute",
  top: "25px",
  left: "-50px",
  textAlign: "center",
  lineHeight: "40px",
  color: "33424D",
  transform: "rotate(-45deg)",
  [theme.breakpoints.down("sm")]: {
    lineHeight: "22px",
    top: "17px",
    left: "-70px",
    fontSize: "12px",
  },
}));

export const StyledRacwaDetailedDialog = styled(RacwaDetailedDialog)({
  [`& .${dialogTitleClasses.root}`]: {
    textAlign: "center",
  },
});

export const StyledDialogSubheading = styled(Typography)({
  marginBottom: "18px",
  flex: "1",
});

export const StyledPagination = styled(Pagination)({
  flex: "1",
  alignSelf: "center",
  marginTop: "16px",
  [`& .${paginationItemClasses.selected}`]: {
    backgroundColor: "#425563 !important",
    color: "black",
  },
});

export const StyledPaginationItem = styled(PaginationItem)({
  fontSize: "0 !important",
  height: "12px !important",
  width: "12px",
  minWidth: "0 !important",
  border: "1px solid black",
  margin: "2px !important",
});
