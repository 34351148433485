import { useState } from "react";
import {
  FORMOTIV_DIALOG_ENABLED,
  STORAGE_KEY_FORMOTIV_DIALOG_SHOWN,
  STORAGE_KEY_FORMOTIV_SHOW_DIALOG,
} from "../../constants";

export const useShowFormotivDialog = () => {
  const [shown, setShown] = useState(!!sessionStorage.getItem(STORAGE_KEY_FORMOTIV_DIALOG_SHOWN));

  const setShownWrapper = () => {
    sessionStorage.setItem(STORAGE_KEY_FORMOTIV_DIALOG_SHOWN, true.toString());
    setShown(true);
  };

  let shouldShow = sessionStorage.getItem(STORAGE_KEY_FORMOTIV_SHOW_DIALOG) === true.toString();

  if (
    process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
    process.env.REACT_APP_ENVIRONMENT === "DEV" ||
    process.env.REACT_APP_ENVIRONMENT === "SIT"
  ) {
    const showFormotivDialogToggleValue =
      require("../../../../../__NPE__/ShowFormotivDialogToggle").getShowFormotivDialogToggleValue();

    if (showFormotivDialogToggleValue !== null) {
      shouldShow = showFormotivDialogToggleValue;
    }
  }

  const show = FORMOTIV_DIALOG_ENABLED && !shown && shouldShow;

  return [show, setShownWrapper] as const;
};

export default useShowFormotivDialog;
