import { event, gtm } from "@racwa/analytics";
import { useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MultiMatchStatusCode } from "../../../../shared/constants";
import useApiClient from "../../../../shared/hooks/useApiClient";
import {
  ApiException,
  ApiResponse,
  Contact,
  ContactGender,
  ContactTitle,
  Cover,
  QuoteResponse,
  UpdateContactDetailsRequest,
} from "../../../../shared/hooks/useApiClient/ClientProxy";
import { POLICY_PAYMENT_PAGE_URL, PolicyRoutes, QuoteRoutes } from "../../../../shared/routing/routes.config";
import { YourQuoteState } from "../../../YourQuote/types";
import { AreYouAMemberPageData, TellUsMoreAboutYouPageData } from "../../../types";
import { PremiumChangedDialogProps } from "../../components/PremiumChangeDialog";
import useQuestions from "../useQuestions";

const createMemberMatchedRequest = (
  data: TellUsMoreAboutYouPageData,
  titleQuestionForced: boolean,
  genderQuestionForced: boolean,
): UpdateContactDetailsRequest => {
  const contact: Contact = {
    mailingAddress: data.mailingAddress?.moniker || "",
  };

  if (titleQuestionForced) {
    contact.title = ContactTitle[data.title as keyof typeof ContactTitle];
  }

  if (genderQuestionForced) {
    if (data.gender) {
      contact.gender = ContactGender[data.gender as keyof typeof ContactGender];
    } else {
      contact.gender = deriveGenderFromTitle(data.title);
    }
  }

  return {
    contacts: [contact],
  };
};

const createAnonymousContactRequest = (data: TellUsMoreAboutYouPageData): UpdateContactDetailsRequest => {
  const contact: Contact = {
    mailingAddress: data.mailingAddress?.moniker || "",
  };

  if (data.title) {
    contact.title = ContactTitle[data.title as keyof typeof ContactTitle];
  }
  if (data.gender) {
    contact.gender = ContactGender[data.gender as keyof typeof ContactGender];
  } else {
    contact.gender = deriveGenderFromTitle(data.title);
  }
  if (data.firstName) {
    contact.firstName = data.firstName;
  }
  if (data.lastName) {
    contact.surname = data.lastName;
  }
  if (data.contactNumber) {
    contact.contactNumber = data.contactNumber.replace(/\s/g, "");
  }
  if (data.email) {
    contact.email = data.email;
  }

  return {
    contacts: [contact],
  };
};

const deriveGenderFromTitle = (title: string | null | undefined) => {
  if (title === ContactTitle.Mr) {
    return ContactGender.Male;
  } else if (title === ContactTitle.Miss || title === ContactTitle.Mrs || title === ContactTitle.Ms) {
    return ContactGender.Female;
  }
};

const getCoverIndexOffsetFromQuotePageData = (covers: Cover[], coverType: string): number => {
  for (let i = 0; i < covers.length; i++) {
    if (covers[i].coverType === coverType) {
      return i;
    }
  }
  throw Error("Unable to find cover");
};

const getCoverFromQuotePageData = (covers: Cover[], coverType: string) => {
  return covers[getCoverIndexOffsetFromQuotePageData(covers, coverType)];
};

const switchCover = (quote: QuoteResponse, cover: ApiResponse<Cover>) => {
  const index = getCoverIndexOffsetFromQuotePageData(quote.covers, cover.result.coverType);
  const covers = [...quote.covers];
  covers[index] = { ...cover.result };
  quote.covers = covers;
};

const hasPremiumDifference = (covers: Cover[], updated: ApiResponse<Cover>) => {
  const oldCost = getCoverFromQuotePageData(covers, updated.result.coverType).premiumDetails.annual.total;
  const newCost = updated.result.premiumDetails.annual.total;
  return oldCost !== newCost;
};

const createPopupProps = (covers: Cover[], updated: ApiResponse<Cover>): PremiumChangedDialogProps => {
  const results: PremiumChangedDialogProps = {
    show: hasPremiumDifference(covers, updated),
  };
  if (results.show) {
    const premiumDetails = updated.result.premiumDetails;
    results.annual = premiumDetails.annual.total;
    results.monthly = premiumDetails.monthly.installment;
  }
  return results;
};

export interface UseTellUsMoreAboutYou {
  showMoreInfoCard: boolean;
  popupProps: PremiumChangedDialogProps;
  save: (data: TellUsMoreAboutYouPageData) => Promise<void>;
  isError?: boolean;
}

export const useTellUsMoreAboutYou = (redirectToNextPageFunction: () => void): UseTellUsMoreAboutYou => {
  const apiClient = useApiClient();
  const setBackdrop = useSetBackdrop();
  const [isError, setIsError] = useState(false);
  const [showMoreInfoCard, setShowMoreInfoCard] = useState(false);
  const [popupProps, setPopupProps] = useState({});

  const [quote, setQuote] = useSessionState<YourQuoteState>({
    specificKey: QuoteRoutes.YourQuote,
    skipPageTrackingRecalculation: true,
  });
  const [, setState] = useSessionState<TellUsMoreAboutYouPageData>({
    specificKey: PolicyRoutes.PersonalInformation,
  });
  const { areYouAMember } = useGetSessionState<AreYouAMemberPageData>(QuoteRoutes.MemberDetails);
  const navigate = useNavigate();
  const { ...questionProps } = useQuestions(areYouAMember || false);

  const save = async (data: TellUsMoreAboutYouPageData) => {
    const request = areYouAMember
      ? createMemberMatchedRequest(data, questionProps.forceTitle, questionProps.forceGender)
      : createAnonymousContactRequest(data);

    try {
      setIsError(false);
      setBackdrop(true);
      const response = await apiClient.updatecontactdetails(request);
      const isMultiMatchResult = response.status === MultiMatchStatusCode;
      const popupProps = {
        ...createPopupProps(quote.covers, response),
        onClose: redirectToNextPageFunction,
      };

      const redirectToNextPage = !popupProps.show && !isMultiMatchResult;

      if (redirectToNextPage || popupProps.show) {
        setState({ ...data, isCompleted: true });
      }

      if (redirectToNextPage) {
        navigate(POLICY_PAYMENT_PAGE_URL);
        return;
      }

      if (popupProps.show) {
        const newQuoteState = { ...quote };
        switchCover(newQuoteState, response);
        setQuote(newQuoteState);
      }

      setShowMoreInfoCard(isMultiMatchResult);
      setPopupProps(popupProps);
    } catch (exception) {
      const errorResponse = exception as ApiException;
      if (errorResponse.status === MultiMatchStatusCode) {
        gtm(event("Multi match"));
      }
      if (errorResponse.status >= 500) {
        setIsError(true);
        return;
      }
      setShowMoreInfoCard(true);
    } finally {
      setBackdrop(false);
    }
  };

  return {
    showMoreInfoCard,
    popupProps,
    save,
    isError,
  };
};

export default useTellUsMoreAboutYou;
