import { MenuItem } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaSelect } from "@racwa/react-components";
import React from "react";
import { isMobileOnly } from "react-device-detect";
import { ControllerRenderProps } from "react-hook-form";
import { ReferenceDataItem } from "../../../../shared/hooks/useApiClient/ClientProxy";

const inputId = "select-bike-usage";
const inputContainerId = `${inputId}-container`;

export interface BikeUsageSelectProps extends Omit<ControllerRenderProps<Record<string, any>>, "ref"> {
  id?: string;
  bikeUsageOptions?: ReferenceDataItem[];
  error?: boolean;
  helperText?: string;
  label?: string;
  inputRef?: React.Ref<any>;
}

export const BikeUsageSelect = ({ bikeUsageOptions, label = "", ...props }: BikeUsageSelectProps) => {
  const options = bikeUsageOptions?.length
    ? bikeUsageOptions?.map((option) => {
        const optionProps = {
          id: option.externalCode,
          key: option.externalCode,
          value: option.externalCode,
          "data-testid": `bike-Usage-Option-${option.externalCode}`,
        };
        return isMobileOnly ? (
          <option {...optionProps}>{option.description}</option>
        ) : (
          <MenuItem {...optionProps}>{option.description}</MenuItem>
        );
      })
    : null;

  if (options && isMobileOnly) {
    options.unshift(
      <option key="disabled" disabled selected hidden aria-hidden value="">
        Please select
      </option>,
    );
  } else if (options && !isMobileOnly) {
    options.unshift(
      <MenuItem key="disabled" disabled selected hidden aria-hidden value="">
        Please select
      </MenuItem>,
    );
  }

  return (
    <RacwaSelect
      {...props}
      id={inputId}
      label={label}
      data-testid={inputContainerId}
      native={isMobileOnly}
      displayEmpty
      onBlur={() => {
        props.onBlur && props.onBlur();
        gtm(fieldTouched(label));
      }}
      value={props.value ?? ""}
    >
      {options}
    </RacwaSelect>
  );
};

export default BikeUsageSelect;
