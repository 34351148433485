import { useEffect, useState } from "react";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { YearSelectionProps } from "../../components/YearSelection";

const orderDescending = (data: number[]) => [...data].sort().reverse();

export interface UseYearsResult extends YearSelectionProps {}

export const useYears = (manufacturer: string | null, year?: number | null): UseYearsResult => {
  const apiClient = useApiClient();
  const [years, setYears] = useState<number[]>([]);
  const [selectedYear, setYear] = useState<number | null>(year || null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const onSelection = (newValue: number | null, resetList?: boolean | false) => {
    if (resetList) {
      setYears([]);
    }

    setYear(newValue);
  };

  useEffect(() => {
    let isCancelled = false;

    const fetchYears = async () => {
      setIsLoading(true);
      setIsError(false);

      try {
        if (manufacturer && !isCancelled) {
          const response = await apiClient.getmanufactureryears(manufacturer);
          if (!isCancelled) {
            const data = response.result;
            setYears(orderDescending(data));

            if (data.length === 1) {
              setYear(data[0]);
            }
          }
        }
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchYears();

    return () => {
      isCancelled = true;
    };
  }, [apiClient, manufacturer, year]);

  return {
    data: years,
    selectedYear,
    onSelection,
    isError,
    isLoading,
  };
};

export default useYears;
