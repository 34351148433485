import { useEffect, useState } from "react";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { ApiResponse, Model } from "../../../../shared/hooks/useApiClient/ClientProxy";
import { BikeModelProps } from "../../components/BikeModelSelection";

export interface UseBikeModelsResults extends BikeModelProps {
  filteredModels: Model[];
  isError: boolean;
}

const extractNameFromModels = (models: Model[]) => models?.map((model) => model.name) || [];

const getDistinctNames = (models: string[]) => models.filter((value, idx, self) => self.indexOf(value) === idx) || [];

export const useModels = (
  manufacturer: string | null,
  selectedYear: number | null,
  selectedModel?: string | null,
): UseBikeModelsResults => {
  const apiClient = useApiClient();
  const [apiResponse, setApiResponse] = useState<ApiResponse<Model[]> | null>(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bikeModel, setBikeModel] = useState<string | null>(selectedModel || null);
  const [bikeModelList, setBikeModelList] = useState<Model[]>([]);
  const [modelNameList, setModelNameList] = useState<string[]>([]);
  const [filteredBikeModels, setFilteredBikeModels] = useState<Model[]>([]);
  const handleBikeModelChange = (name: string | null, resetList?: boolean | false) => {
    const matchedModels = bikeModelList.filter((item) => item.name === name);
    if (resetList) {
      setBikeModelList([]);
      setModelNameList([]);
    }
    setBikeModel(name);
    setFilteredBikeModels(matchedModels);
  };

  useEffect(() => {
    let mounted = true;

    const fetchBikeModels = async () => {
      setIsError(false);
      if (manufacturer && selectedYear) {
        try {
          setIsLoading(true);
          const response = await apiClient.getmodels(manufacturer, selectedYear);
          if (mounted) {
            setApiResponse(response);
          }
        } catch {
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchBikeModels();

    // return function will be called on component unmount
    return () => {
      mounted = false;
    };
  }, [apiClient, manufacturer, selectedYear]);

  useEffect(() => {
    if (apiResponse === null) {
      return;
    }

    const models = apiResponse.result;

    if (models?.length) {
      let list = extractNameFromModels(models);
      list = getDistinctNames(list);
      setBikeModelList(models);
      setModelNameList(list);

      if (list.length === 1) {
        const selectedBikeModel = list[0];
        setFilteredBikeModels(models.filter((item) => item.name === selectedBikeModel));
        setBikeModel(selectedBikeModel);
      }
    }
  }, [apiResponse]);

  return {
    data: modelNameList || [],
    selectedModel: bikeModel,
    filteredModels: filteredBikeModels || [],
    isError,
    isLoading,
    onSelection: handleBikeModelChange,
  };
};

export default useModels;
