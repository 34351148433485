import { getInsurancePhoneButton } from "raci-react-library";
import { CommonPageProps } from "..";

export const getPageTemplateBannerAndButtons = (): Pick<
  CommonPageProps,
  "headerEndAction" | "notificationBannerContent"
> => {
  const phoneButton = getInsurancePhoneButton();
  let headerEndAction = phoneButton;

  let notificationBannerContent;

  if (
    process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
    process.env.REACT_APP_ENVIRONMENT === "DEV" ||
    process.env.REACT_APP_ENVIRONMENT === "SIT" ||
    process.env.REACT_APP_ENVIRONMENT === "UAT"
  ) {
    const { TestBanner, HeaderButtons } = require("raci-react-library-npe");
    const { ShowFormotivDialogToggle } = require("../../../../__NPE__/ShowFormotivDialogToggle");

    notificationBannerContent = <TestBanner />;

    headerEndAction = (
      <HeaderButtons
        phoneButton={phoneButton}
        npeButtons={[]}
        isUat={process.env.REACT_APP_ENVIRONMENT === "UAT"}
        appConfig={{
          toggles: process.env.REACT_APP_ENVIRONMENT !== "UAT" ? [<ShowFormotivDialogToggle />] : undefined,
        }}
      />
    );
  }

  return {
    headerEndAction,
    notificationBannerContent,
  };
};

export default getPageTemplateBannerAndButtons;
