import { fieldTouched, gtm } from "@racwa/analytics";
import { startOfDay } from "date-fns";
import { KeyboardDateInput } from "raci-react-library";
import { SelectPolicyStartDateMessage } from "../../constants/helperText";

export interface PolicyStartDateProps {
  selectedDate: Date;
  maxSelectableDate: Date;
}

export const PolicyStartDate = ({ selectedDate, maxSelectableDate }: PolicyStartDateProps) => {
  return (
    <KeyboardDateInput
      onBlur={() => {
        gtm(fieldTouched("When would you like your policy to start?"));
      }}
      onDateChange={(date) => {
        window.formotivConfig &&
          window.formotiv?.inputActivity?.(
            window.formotivConfig,
            "selectedPolicyStartDate",
            "keyboardDatePicker",
            date,
          );
      }}
      id={"policyStartDate"}
      name={"selectedDate"}
      minDate={startOfDay(new Date())}
      maxDate={new Date(maxSelectableDate)}
      defaultValue={new Date(selectedDate)}
      clickAnywhereToShowCalendar
      readOnlyTextField
      label="When would you like your policy to start?"
      errorMessage={SelectPolicyStartDateMessage}
    />
  );
};

export default PolicyStartDate;
