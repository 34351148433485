import { Grid, Typography } from "@mui/material";
import { FinePrint, RacwaEdit } from "@racwa/react-components";
import { useGetSessionState } from "raci-react-library";
import { Link as RouterLink } from "react-router-dom";
import { QuoteRoutes, quoteRoutes } from "../../../../shared/routing/routes.config";
import useSelectedBikeValue from "../../../YourBike/hooks/useSelectedBikeValue";
import { BikeSelectionPageData } from "../../../types";

export interface YourBikeProps {
  isEditLocked?: boolean;
}

export const YourBike = ({ isEditLocked }: YourBikeProps) => {
  const { manufacturer } = useSelectedBikeValue();
  const bikeSelection = useGetSessionState<BikeSelectionPageData>(QuoteRoutes.YourBike);
  const modelDescription = `${bikeSelection.selectedYear} ${manufacturer?.name} ${bikeSelection.selectedModel} ${bikeSelection.selectedEngineCapacity}`;

  const renderEdit = isEditLocked ? null : (
    <Grid item xs={2} textAlign="right">
      <RacwaEdit link={quoteRoutes.BikeSelection.path} dataTestId="editBikeDetailsLink" component={RouterLink} />
    </Grid>
  );

  return (
    <Grid container spacing={1} alignItems="center" data-testid="yourBikeQuoteContainer">
      <Grid item xs={12}>
        <Typography variant="h4">Your bike</Typography>
      </Grid>
      <Grid item xs={10}>
        <FinePrint data-testid="bikeModelDescription">{modelDescription}</FinePrint>
      </Grid>
      {renderEdit}
    </Grid>
  );
};

export default YourBike;
