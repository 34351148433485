import { Grid, Link, Typography } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import {
  BasicTooltip,
  BirthdayConfetti,
  ContactNumberInput,
  DateOfBirthInput,
  EmailInput,
  FirstNameInput,
  isBirthdayToday,
  useFormotiv
} from "raci-react-library";
import React, { useRef, useState } from "react";
import { FieldError, FormProvider, useForm } from "react-hook-form";
import { PrivacyPolicy } from "../../shared/components/PrivacyPolicy";
import { InvalidDobMessage } from "../../shared/constants/helperText";
import { AreYouAMemberPageData } from "../types";
import AreYouAMember from "./components/AreYouAMember";
import FindMyDetailsButton from "./components/FindMyDetailsButton";
import NotFoundCard from "./components/NotFoundCard";
import { FORMOTIV_CONFIG } from "../../shared/constants";

export interface AreYouAMemberFormData extends AreYouAMemberPageData {}

export interface AdditionalAreYouAMemberFormProps {
  isContactMatchAttemptsExhausted?: boolean;
  isError?: boolean;
  membershipTier?: string | null;
}

export interface AreYouAMemberFormHandlers {
  onSkip?: () => Promise<void>;
  onAreYouAMemberSelection?: (areYouAMember?: string | null) => Promise<void>;
  onSubmit?: (newState: AreYouAMemberFormData) => boolean | Promise<boolean>;
}

export interface AreYouAMemberFormProps
  extends AreYouAMemberFormData,
    AreYouAMemberFormHandlers,
    AdditionalAreYouAMemberFormProps {}

export const AreYouAMemberForm = ({
  areYouAMemberSelection,
  areYouAMember,
  firstName,
  dateOfBirth,
  contactNumber,
  email,
  isContactMatchAttemptsExhausted,
  onAreYouAMemberSelection,
  onSkip,
  onSubmit,
}: AreYouAMemberFormProps) => {
  const form = useForm<AreYouAMemberFormData>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      firstName,
      dateOfBirth,
      contactNumber,
      email,
    },
  });
  const {
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isSubmitSuccessful, isSubmitting: isLoading, isValid, isDirty },
    control,
  } = form;

  const [submitError, setSubmitError] = useState(false);
  const [showBirthdayConfetti, setShowBirthdayConfetti] = useState(false);
  const firstNameProvided = useRef<string>("");
  const isErrorButFormIsClean = submitError && !isDirty;
  const hasSubmittedBefore = isSubmitSuccessful;
  const isLoadingOrHasSubmittedBeforeAndWasntAnError = isLoading || (hasSubmittedBefore && !submitError);
  const disabledInputs: boolean =
    (isLoadingOrHasSubmittedBeforeAndWasntAnError && isValid) || isContactMatchAttemptsExhausted || false;

  const disabledButtons: boolean = isValid && isLoadingOrHasSubmittedBeforeAndWasntAnError;

  const formRef = useRef(null);

  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);
  const onSubmitWrapper = (async (_: AreYouAMemberFormData) => {
    onSubmit && setSubmitError(!(await onSubmit(getValues())));
    reset(getValues());
  });

  const skipLink = () => (
    <Grid container item xs={12} justifyContent="center" alignItems="center">
      <Link
        id="skipLink"
        name="skipLink"
        data-testid="skipLink"
        component="button"
        type="button"
        role="button"
        variant="body2"
        color="primary"
        onClick={async (e: React.SyntheticEvent<Element, Event>) => {
          e.preventDefault();
          onSkip && (await onSkip());
        }}
        disabled={disabledButtons}
      >
        Skip {">"}
      </Link>
    </Grid>
  );

  const birthdayConfetti = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event?.target?.value && isBirthdayToday(event.target.value.replaceAll(" ", ""))
      ? setShowBirthdayConfetti(true)
      : setShowBirthdayConfetti(false);
  };

  const onBlurForFirstName = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    firstNameProvided.current = event?.target.value;
  };

  const isInputError = (fieldError: FieldError | undefined): boolean =>
    (!!fieldError || isErrorButFormIsClean) && !isLoading && !isContactMatchAttemptsExhausted;

  const renderMemberDetails = () => {
    return (
      <>
        <Grid item xs={12}>
          <FirstNameInput
            error={isInputError(errors.firstName)}
            disabled={disabledInputs}
            helperText={errors.firstName?.message || ""}
            onBlur={onBlurForFirstName}
          />
        </Grid>
        <Grid item xs={12}>
          <DateOfBirthInput
            label="Date of birth"
            disabled={disabledInputs}
            errorMessage={InvalidDobMessage}
            onBlur={birthdayConfetti}
          />
        </Grid>
        <Grid item xs={12}>
          <ContactNumberInput
            error={isInputError(errors.contactNumber)}
            disabled={disabledInputs}
            helperText={errors.contactNumber?.message || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <EmailInput
            error={isInputError(errors.email)}
            disabled={disabledInputs}
            helperText={errors.email?.message || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <PrivacyPolicy />
        </Grid>
        <Grid item xs={12}>
          <NotFoundCard
            visible={isContactMatchAttemptsExhausted || (hasSubmittedBefore && submitError)}
            isContactMatchAttemptsExhausted={isContactMatchAttemptsExhausted}
          />
        </Grid>
        {hasSubmittedBefore && submitError ? skipLink() : null}
        {!isContactMatchAttemptsExhausted && (
          <Grid item xs={12}>
            <FindMyDetailsButton
              hasMatch={hasSubmittedBefore && !submitError}
              firstName={firstName}
              disabled={isLoading || isErrorButFormIsClean}
            />
          </Grid>
        )}
        {!hasSubmittedBefore ? skipLink() : null}
      </>
    );
  };

  return (
    <FormProvider {...form}>
      <form
        ref={formRef}
        action="#"
        onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmitWrapper))}
        data-testid="areYouAMemberForm"
        autoComplete="on"
      >
        {showBirthdayConfetti ? <BirthdayConfetti name={firstNameProvided.current} /> : null}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" id="header" data-testid="header">
              Before we get started
            </Typography>
          </Grid>
          <Grid container item xs={12} direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={11}>
              <AreYouAMember
                areYouAMemberSelection={areYouAMemberSelection}
                areYouAMemberSelectionHandler={async (selected) => {
                  gtm(fieldTouched("Are you an RAC Member?"));
                  onAreYouAMemberSelection && (await onAreYouAMemberSelection(selected));
                }}
                control={control}
                disabled={disabledButtons}
              />
            </Grid>
            <Grid item xs={1}>
              <BasicTooltip
                id="areYouAnRACMemberToolTip"
                title="Are you an RAC member?"
                message="If you have any of these RAC products you're a member:
                        Roadside Assistance, Insurance, Free2go, Life Insurance, Health Insurance,
                        Pet Insurance, Finance, Security or Rewards Membership."
              />
            </Grid>
          </Grid>
          {areYouAMember ? renderMemberDetails() : null}
        </Grid>
      </form>
    </FormProvider>
  );
};
