import { Grid, Typography } from "@mui/material";
import { CheckMarkIcon } from ".";
import { StyledCornerRibbonTypography } from "./styled";

const ComprehensiveTooltipContent = () => {
  return (
    <>
      <StyledCornerRibbonTypography variant="h4">
        <b>Most Popular</b>
      </StyledCornerRibbonTypography>
      <Grid item xs={12}>
        <Typography variant="h4">What's covered?</Typography>
      </Grid>
      {CheckMarkIcon}
      <Grid item xs={11}>
        <Typography variant="body2">Accidental damage to your bike (including by storm, fire and flood)</Typography>
      </Grid>
      {CheckMarkIcon}
      <Grid item xs={11}>
        <Typography variant="body2">Theft of your bike</Typography>
      </Grid>
      {CheckMarkIcon}
      <Grid item xs={11}>
        <Typography variant="body2">Damage to someone else's property</Typography>
      </Grid>
      {CheckMarkIcon}
      <Grid item xs={11}>
        <Typography variant="body2">Helmet and protective clothing</Typography>
      </Grid>
      {CheckMarkIcon}
      <Grid item xs={11}>
        <Typography variant="body2">Other riders using your bike</Typography>
      </Grid>
      {CheckMarkIcon}
      <Grid item xs={11}>
        <Typography variant="body2">Your choice of repairer</Typography>
      </Grid>
      {CheckMarkIcon}
      <Grid item xs={11}>
        <Typography variant="body2">And much more!</Typography>
      </Grid>
    </>
  );
};

export default ComprehensiveTooltipContent;
