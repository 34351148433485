import { Button, FormControl, FormHelperText, FormLabel, Grid, Typography } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaCardNotification, RacwaTextInput } from "@racwa/react-components";
import { YesNoButtonGroup, useFormotiv, trackCustomFormotivInput } from "raci-react-library";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import { useRef } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import NeedMoreInformationCardNotification from "../../shared/components/NeedMoreInformationCardNotification";
import PolicyStartDate from "../../shared/components/PolicyStartDate";
import { pleaseEnterAValidMessage } from "../../shared/constants/helperText";
import { LetsClarifyMoreDetailsPageData } from "../types";
import { getYesNoLabel } from "../../shared/utils/helpers";

export interface LetsClarifyMoreDetailsFormAnswers extends LetsClarifyMoreDetailsPageData {
  quoteNumber: string;
}
export const BikeRegistrationValidationPattern = /^( ?[a-zA-Z0-9]*$){1,10}$/;
const bikeRegistrationErrorMessage = pleaseEnterAValidMessage("registration number");
export const BikeRegistrationNumberSizeLimit = 10;

export interface LetsClarifyMoreDetailsFormHandlers {
  onSubmit?: (data: LetsClarifyMoreDetailsFormAnswers) => void | Promise<void>;
}

export type LetsClarifyMoreDetailsFormProps = LetsClarifyMoreDetailsFormAnswers & LetsClarifyMoreDetailsFormHandlers;

export default function LetsClarifyMoreDetailsForm({
  quoteNumber = "",
  isHadAccident,
  isRegistrationKnown,
  bikeRegistration = "",
  selectedDate = new Date(),
  maxSelectableDate: maxDate = new Date(),
  isLicenceCancelled,
  onSubmit = () => void 0,
}: LetsClarifyMoreDetailsFormProps) {
  const form = useForm<LetsClarifyMoreDetailsFormAnswers>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = form;

  const bikeRegistrationId = "bikeRegistration";

  const dutyOfDisclosureCard = (
    <Grid item xs={12}>
      <RacwaCardNotification data-testid="dutyOfDisclosureCardTest" title="Important information" severity="info">
        <Typography variant="inherit">
          When answering our questions you have a duty to answer them honestly, accurately and to the best of your
          knowledge. The duty applies to you and anyone else insured under the policy. If you answer for another person,
          we will treat your answers as theirs. Your duty continues until we insure you.
          <br />
          <br />
          If you do not meet the duty your policy may be cancelled, or treated as if it never existed and your claim may
          be rejected or not paid in full.
          <br />
          <br />
          This insurance is a consumer insurance contract.
        </Typography>
      </RacwaCardNotification>
      <br />
    </Grid>
  );

  const watchIsHadAccident = watch("isHadAccident", isHadAccident);
  const isHadAccidentFormotiv = "isHadAccidentFormotiv";
  const watchIsLicenceCancelled = watch("isLicenceCancelled", isLicenceCancelled);
  const isLicenceCancelledFormotiv = "isLicenceCancelledFormotiv";
  const watchIsRegoKnown = watch("isRegistrationKnown", isRegistrationKnown);

  const renderAccidentOrCancelledCard = () => {
    return (
      <Grid item xs={12}>
        <NeedMoreInformationCardNotification quoteNumber={quoteNumber} />
      </Grid>
    );
  };

  return (
    <FormProvider {...form}>
       <form onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmit))} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" id="header" data-testid="header">
              Let's clarify a few more details
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <PolicyStartDate selectedDate={selectedDate} maxSelectableDate={maxDate} />
          </Grid>

          <Grid item xs={12}>
            <FormControl error={!!errors.isRegistrationKnown}>
              <FormLabel>Do you know your bike's registration number?</FormLabel>
              <YesNoButtonGroup
                name="isRegistrationKnown"
                id="isRegistrationKnown"
                defaultValue={isRegistrationKnown}
                data-testid="isRegoKnownYesNoButton"
                onChange={() => {
                  const value = form.getValues("isRegistrationKnown");
                  if (value !== true) {
                    form.setValue("bikeRegistration", "");
                  }
                }}
                onBlur={() => {
                  gtm(fieldTouched("Do you know your bike's registration number?"));
                }}
              />
              <FormHelperText>{errors.isRegistrationKnown?.message || ""}</FormHelperText>
            </FormControl>
          </Grid>

          <Controller
            control={control}
            name={bikeRegistrationId}
            defaultValue={bikeRegistration}
            rules={{
              required: {
                value: watchIsRegoKnown,
                message: bikeRegistrationErrorMessage,
              },
              pattern: {
                value: BikeRegistrationValidationPattern,
                message: bikeRegistrationErrorMessage,
              },
            }}
            render={({ field: { ref, onBlur, ...props } }) => {
              return watchIsRegoKnown ? (
                <Grid item xs={12}>
                  <RacwaTextInput
                    {...props}
                    id="bikeRegistrationInput"
                    data-testid="bikeRegistrationInput"
                    color="primary"
                    label="What is your bike's registration number?"
                    fullWidth
                    placeholder="e.g. RAC123"
                    type="text"
                    inputRef={ref}
                    inputProps={{
                      maxLength: BikeRegistrationNumberSizeLimit,
                    }}
                    onBlur={() => {
                      gtm(fieldTouched("What is your bike's registration number?"));
                      onBlur && onBlur();
                    }}
                    error={!!errors.bikeRegistration}
                    helperText={errors.bikeRegistration?.message || ""}
                    data-hj-suppress
                  />
                </Grid>
              ) : (
                <></>
              );
            }}
          />

          {dutyOfDisclosureCard}

          <Grid item xs={12}>
            <Typography variant="h3" id="header" data-testid="secondheader">
              In the past 3 years, have you had...
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControl error={!!errors.isHadAccident}>
              <FormLabel>Any motorcycle accidents or made any claims regardless of blame?</FormLabel>
              <YesNoButtonGroup
                name="isHadAccident"
                id="isHadAccident"
                defaultValue={isHadAccident}
                data-testid="isHadAccidentTest"
                onChange={(_, value) => trackCustomFormotivInput(getYesNoLabel(isHadAccidentFormotiv, value), "button", value)}
                onBlur={() => {
                  gtm(fieldTouched("Any motorcycle accidents or made any claims regardless of blame?"));

                }}
              />
              <FormHelperText>{errors.isHadAccident?.message || ""}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl error={!!errors.isLicenceCancelled}>
              <FormLabel>Your motorcycle licence cancelled, suspended or any special conditions applied?</FormLabel>
              <YesNoButtonGroup
                name="isLicenceCancelled"
                id="isLicenceCancelled"
                defaultValue={isLicenceCancelled}
                data-testid="isLicenceCancelledTest"
                onChange={(_, value) => trackCustomFormotivInput(getYesNoLabel(isLicenceCancelledFormotiv, value), "button", value)}
                onBlur={() => {
                  gtm(fieldTouched("Your motorcycle licence cancelled, suspended or any special conditions applied?"));
                }}
              />
              <FormHelperText>{errors.isLicenceCancelled?.message || ""}</FormHelperText>
            </FormControl>
          </Grid>

          {(watchIsLicenceCancelled || watchIsHadAccident) && renderAccidentOrCancelledCard()}

          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              data-testid="submit"
              fullWidth={true}
              disabled={watchIsLicenceCancelled || watchIsHadAccident}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
