import { RacwaBackdrop } from "@racwa/react-components";
import { useGetSessionState } from "raci-react-library";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import { Cover, PaymentFrequency, PaymentMethod } from "../../shared/hooks/useApiClient/ClientProxy";
import {
  PolicyRoutes,
  QuoteRoutes,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
  policyRoutes,
  quoteRoutes,
} from "../../shared/routing/routes.config";
import useTellUsMoreAboutYourBikeRefData from "../BikeUsage/hooks/useTellUsMoreAboutYourBikeRefData";
import useSelectedBikeValue from "../YourBike/hooks/useSelectedBikeValue";
import { YourQuoteState } from "../YourQuote/types";
import { BikeSelectionPageData, PaymentPageData, TellUsMoreAboutYouPageData } from "../types";
import PaymentForm, { PaymentFormData } from "./Form";
import { BankAccountData } from "./components/BankAccount";
import TryAgainDialog from "./components/TryAgainDialog";
import useBankAccount from "./hooks/useBankAccount";
import useReferenceData from "./hooks/useReferenceData";
import useWestpacScript from "./hooks/useWestpacScript";

export const PaymentPage = () => {
  const tellUsMoreAboutYourBikeDetails = useTellUsMoreAboutYourBikeRefData();
  const form = useForm<PaymentFormData & BankAccountData>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const bikeSelection = useGetSessionState<BikeSelectionPageData>(QuoteRoutes.YourBike);
  const { isCompleted: isPreviousPageCompleted } = useGetSessionState<TellUsMoreAboutYouPageData>(
    PolicyRoutes.PersonalInformation,
  );

  const { watch } = form;

  const { isAnnualCCPaymentInProgress } = useGetSessionState<PaymentPageData>(PolicyRoutes.Payment);
  const quoteState = useGetSessionState<YourQuoteState>(QuoteRoutes.YourQuote);

  const [pageCompleted, setPageCompleted] = useState(false);
  const [, setPaymentFrequency] = useState<PaymentFrequency | null>(
    isAnnualCCPaymentInProgress ? PaymentFrequency.Annual : null,
  );
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(
    isAnnualCCPaymentInProgress ? PaymentMethod.Card : null,
  );

  const navigate = useNavigate();
  const { onSubmit: onSubmitBankAccount, isError: isBankAccountError, onBsbLookup } = useBankAccount();
  const { manufacturer } = useSelectedBikeValue();
  const { ...referenceDataProps } = useReferenceData();
  const [showTryAgainDialog, setShowTryAgainDialog] = useState(false);
  const paymentFrequencyWatch = watch("paymentFrequency");

  const westpacPaymentFrequency = () => {
    if (paymentFrequencyWatch) {
      return paymentFrequencyWatch === PaymentFrequency.Annual;
    }

    return !(quoteState.selectedPaymentFrequency === PaymentFrequency.Annual);
  };

  const { showAuthorisationCard, isFormFilledOut, ...westpacScriptProps } = useWestpacScript(
    paymentMethod === PaymentMethod.Card,
    westpacPaymentFrequency(),
    referenceDataProps.westpacPublishableApiKey,
    () => setPageCompleted(true),
    (openTryAgainDialog: boolean) => setShowTryAgainDialog(openTryAgainDialog),
  );

  const modelDescription = `${manufacturer?.name} ${bikeSelection.selectedYear} ${bikeSelection.selectedModel}`;

  const cover = quoteState.covers?.find((x: Cover) => x.coverType === quoteState.selectedCoverType);
  const isMissingSelectedCover = !cover;

  if (isMissingSelectedCover) {
    return <Navigate to={quoteRoutes.Quote.path} />;
  }

  const handleSubmit = () => {
    if (paymentMethod === PaymentMethod.Bank_account && !isBankAccountError) {
      setPageCompleted(true);
    }
  };

  if (isBankAccountError || westpacScriptProps.isError || referenceDataProps.isError) {
    navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
  }

  if (pageCompleted) {
    return <Navigate to={policyRoutes.Confirmation.path} />;
  }

  if (!isPreviousPageCompleted) {
    return <Navigate to={policyRoutes.TellUsMoreAboutYou.path} />;
  }

  return referenceDataProps.isLoading ? (
    <RacwaBackdrop open />
  ) : (
    <>
      <PaymentForm
        form={form}
        modelDescription={modelDescription}
        cover={cover}
        onSubmitBankAccount={onSubmitBankAccount}
        onSubmitCreditCard={westpacScriptProps.onSubmitCreditCard}
        trustedFrame={westpacScriptProps.trustedFrame}
        paymentFrequency={quoteState.selectedPaymentFrequency}
        onPaymentFrequencyChange={setPaymentFrequency}
        paymentMethod={paymentMethod}
        onPaymentMethodChange={setPaymentMethod}
        showAuthorisationCard={(quoteState.selectedPaymentFrequency && paymentMethod && showAuthorisationCard) ?? false}
        isCreditCardFormFilledOut={isFormFilledOut}
        isEditLocked={westpacScriptProps.isEditLocked || isAnnualCCPaymentInProgress}
        onSubmit={handleSubmit}
        tellUsMoreAboutYourBikeDetails={tellUsMoreAboutYourBikeDetails}
        onBsbLookup={onBsbLookup}
      />
      <TryAgainDialog
        open={showTryAgainDialog}
        policyNumber={westpacScriptProps.policyNumber}
        onDoneClick={() => westpacScriptProps.onDoneClick()}
        onTryAgainClick={() => setShowTryAgainDialog(false)}
      />
    </>
  );
};

export default PaymentPage;
