import { Grid, Typography } from "@mui/material";
import { FormProvider } from "react-hook-form";
import FormotivDialog from "../../shared/components/FormotivDialog";
import { ImportantInfoStatement } from "./components/ImportantInfoStatement";
import QuoteCard from "./components/QuoteCard";
import { QuoteFormProps } from "./types";

export const YourQuoteForm = ({ form, quoteNumber, quoteCards }: QuoteFormProps) => {
  const { handleSubmit } = form;

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(() => {})} action="#" noValidate>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h2" id="header" data-testid="header">
                Here are your quotes
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" sx={{ marginBottom: "10px" }}>
                Your quote number is <strong id="quoteNumber">{quoteNumber}</strong>
              </Typography>
            </Grid>
            {quoteCards.map((props) => (
              <Grid item xs={12} key={props.coverType}>
                <QuoteCard {...props} />
              </Grid>
            ))}
          </Grid>
          <ImportantInfoStatement />
        </form>
      </FormProvider>
      <FormotivDialog />
    </>
  );
};

export default YourQuoteForm;
