import { OutlinedInput, OutlinedInputProps } from "@mui/material";
import { NumericFormat, NumericFormatProps } from "react-number-format";

const NumericOutlinedInput = (props: OutlinedInputProps) => (
  <OutlinedInput {...props} inputProps={{ type: "text", inputMode: "numeric" }} />
);

export interface CurrencyInputProps extends NumericFormatProps<OutlinedInputProps> {
  withDecimals?: boolean | null;
}

export const CurrencyInput = ({ withDecimals, ...props }: CurrencyInputProps) => {
  return (
    <NumericFormat
      thousandSeparator
      decimalScale={withDecimals ? 2 : 0}
      fixedDecimalScale={!!withDecimals}
      valueIsNumericString
      prefix="$"
      customInput={NumericOutlinedInput}
      {...props}
    />
  );
};

export default CurrencyInput;
