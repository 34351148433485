import { faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonProps } from "@mui/material";

export interface FindMyDetailsButtonProps extends ButtonProps {
  hasMatch?: boolean | null;
  firstName?: string | null;
}

export const FindMyDetailsButton = ({ hasMatch, firstName, ...props }: FindMyDetailsButtonProps) => (
  <Button
    {...props}
    id="submit"
    name="submit"
    type={hasMatch ? "button" : "submit"}
    data-testid="submit"
    startIcon={hasMatch ? <FontAwesomeIcon icon={faUserCheck} aria-hidden /> : null}
    color={hasMatch ? "secondary" : "primary"}
    variant="contained"
    fullWidth={true}
  >
    {hasMatch ? `We found you ${firstName}` : "Find my details"}
  </Button>
);

export default FindMyDetailsButton;
