import { Button, Checkbox, FormControlLabel, FormGroup, FormLabel, Grid, Typography } from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaAutocomplete } from "@racwa/react-components";
import { BasicTooltip, trackCustomFormotivInput, useFormotiv, YesNoButtonGroup } from "raci-react-library";
import { useRef } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Loadable } from "recoil";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import { RequiredFinancierMessage, RequiredSuburbMessage } from "../../shared/constants/helperText";
import { Financier, TellUsMoreAboutYourBikeRefData } from "../../shared/hooks/useApiClient/ClientProxy";
import { getYesNoLabel } from "../../shared/utils/helpers";
import { BikeDetailsPageData } from "../types";
import AnnualKilometresSelect from "./components/AnnualKilometresSelect";

export interface Suburb {
  cityName: string;
  name: string;
  postcode: string;
}

export interface BikeDetailsQuoteQuestionsData {
  suburbList: Loadable<Suburb[]>;
  tellUsMoreAboutYourBikeRefData: Loadable<TellUsMoreAboutYourBikeRefData>;
}

export interface BikeDetailsAnswers extends BikeDetailsPageData {
  selectedManufacturer: string;
}

export interface BikeDetailsHandlers {
  onSubmit?: (data: BikeDetailsAnswers) => void | Promise<void>;
}

export type BikeDetailsProps = BikeDetailsQuoteQuestionsData & BikeDetailsAnswers & BikeDetailsHandlers;

export default function BikeDetailsForm({
  tellUsMoreAboutYourBikeRefData,
  isFinanced,
  isDashcam,
  suburbList,
  selectedSuburb = null,
  isGaraged,
  selectedSecurityOptions,
  selectedAnnualKmsOption = "",
  selectedFinancier = null,
  selectedManufacturer,
  onSubmit = () => void 0,
}: BikeDetailsProps) {
  const form = useForm<BikeDetailsAnswers>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = form;

  const { ref, ...rest } = register("selectedSecurityOptions");

  const formRef = useRef(null);
  //const onSubmitWrapper = useFormotiv(formRef, onSubmit);
  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);

  const watchIsFinancedYes = watch("isFinanced", isFinanced);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: Suburb) => option.cityName,
  });

  const filterFinancierOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option: Financier) => option.financierName,
  });

  const garageLabel = "Is your bike usually kept in a garage overnight?";
  const howFarYouRideLabel = "Please estimate how far you ride every year";
  const whatSuburbLabel = "What suburb is your bike usually kept in?";
  const dashcamLabel = "Do you use a helmet camera or dashcam?";
  const isGaragedFormotiv = "isGaragedFormotiv";
  const isDashcamFormotiv = "isDashcamFormotiv";
  const isFinancedFormotiv = "isFinancedFormotiv";

  const renderFinancierList = () => {
    return (
      <Grid item xs={12}>
        <Controller
          control={control}
          name="selectedFinancier"
          defaultValue={selectedFinancier}
          rules={{
            required: {
              value: true,
              message: RequiredFinancierMessage,
            },
          }}
          render={({ field: { ref, onChange, onBlur, ...props }, fieldState: { error } }) => (
            <RacwaAutocomplete
              {...props}
              label="Who are you currently financed with?"
              aria-label="Who are you currently financed with?"
              placeholder="Enter your financier"
              labelProps={{ "data-testid": "formlabel-financier" }}
              options={
                tellUsMoreAboutYourBikeRefData.state === "hasValue"
                  ? tellUsMoreAboutYourBikeRefData.contents.financiers
                  : []
              }
              getOptionLabel={(option) => option.financierName}
              fullWidth
              autoComplete
              autoHighlight
              clearOnEscape
              loading={tellUsMoreAboutYourBikeRefData.state !== "hasValue"}
              filterOptions={filterFinancierOptions}
              onChange={(_, value) => {
                onChange(value);
              }}
              onBlur={() => {
                onBlur && onBlur();
                gtm(fieldTouched("Who are you currently financed with?"));
              }}
              data-testid="isSelectedFinancierTest"
              error={!!errors.selectedFinancier}
              helperText={error?.message}
              textFieldProps={{
                name: "selectedFinancier",
                "data-testid": "textField-Financier-Selection",
              }}
              inputRef={ref}
            />
          )}
        />
      </Grid>
    );
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmit))} action="#">
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2" data-testid="header">
              Tell us more about your {selectedManufacturer}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Controller
              name="selectedAnnualKmsOption"
              defaultValue={selectedAnnualKmsOption}
              control={control}
              aria-label={howFarYouRideLabel}
              rules={{
                required: {
                  value: true,
                  message: howFarYouRideLabel,
                },
              }}
              render={({ field: { ref, onChange, ...props } }) => (
                <AnnualKilometresSelect
                  {...props}
                  onChange={(ev) => {
                    window.formotivConfig &&
                      window.formotiv?.inputActivity?.(
                        window.formotivConfig,
                        "selectedAnnualKmsOption",
                        "autocomplete",
                        ev.target?.value,
                      );
                    onChange(ev);
                  }}
                  tellUsMoreAboutYourBikeRefData={tellUsMoreAboutYourBikeRefData}
                  label={howFarYouRideLabel}
                  error={!!errors.selectedAnnualKmsOption}
                  helperText={errors.selectedAnnualKmsOption?.message || ""}
                  inputRef={ref}
                />
              )}
            />
          </Grid>
          <Grid item xs={1}>
            <BasicTooltip
              id="annualKmsTooltip"
              title="How far do you ride every year?"
              message="Please estimate the number of kilometres you ride on your motorcycle per year."
            />
          </Grid>
          <Grid item xs={11}>
            <Controller
              control={control}
              name="selectedSuburb"
              defaultValue={selectedSuburb}
              rules={{
                required: { value: true, message: RequiredSuburbMessage },
              }}
              render={({ field: { ref, onChange, onBlur, ...props }, fieldState: { error } }) => (
                <RacwaAutocomplete
                  {...props}
                  label={whatSuburbLabel}
                  aria-label={whatSuburbLabel}
                  placeholder="Enter suburb"
                  labelProps={{ "data-testid": "formlabel-suburb" }}
                  options={suburbList.state === "hasValue" && suburbList.contents.length ? suburbList.contents : []}
                  getOptionLabel={(option) => option.cityName}
                  fullWidth
                  autoComplete
                  autoHighlight
                  clearOnEscape
                  loading={suburbList.state !== "hasValue"}
                  filterOptions={filterOptions}
                  onChange={(_, value) => {
                    window.formotivConfig &&
                      window.formotiv?.inputActivity?.(window.formotivConfig, "selectedSuburb", "autocomplete", value);
                    onChange(value);
                  }}
                  data-testid="isSelectedSuburbTest"
                  error={!!errors.selectedSuburb}
                  onBlur={() => {
                    onBlur && onBlur();
                    gtm(fieldTouched(whatSuburbLabel));
                  }}
                  helperText={error?.message}
                  textFieldProps={{
                    name: "selectedSuburb",
                    "data-testid": "textfield-suburb-selection",
                  }}
                  data-hj-suppress
                  inputRef={ref}
                />
              )}
            />
          </Grid>
          <Grid item xs={11}>
            <FormLabel>{garageLabel}</FormLabel>
            <YesNoButtonGroup
              name="isGaraged"
              id="isGaraged"
              defaultValue={isGaraged}
              data-testid="isGaragedTest"
              onChange={(_, value) =>
                trackCustomFormotivInput(getYesNoLabel(isGaragedFormotiv, value), "button", value)
              }
            />
          </Grid>
          <Grid item xs={11}>
            <FormLabel>Does your bike have any of the following?</FormLabel>
            <FormGroup>
              {tellUsMoreAboutYourBikeRefData.state === "hasValue"
                ? tellUsMoreAboutYourBikeRefData.contents.bikeSecurityOptions.map((option) => (
                    <FormControlLabel
                      key={option.externalCode}
                      aria-label={option.externalCode}
                      control={
                        <Checkbox
                          {...rest}
                          name="selectedSecurityOptions"
                          data-testid={`securityOptionTest${option.externalCode}`}
                          value={option.externalCode}
                          defaultChecked={
                            !!selectedSecurityOptions && selectedSecurityOptions.includes(option.externalCode)
                          }
                          inputRef={ref}
                          onBlur={() => gtm(fieldTouched(option.description))}
                        />
                      }
                      label={option.description}
                    />
                  ))
                : "Loading ..."}
            </FormGroup>
          </Grid>
          <Grid item xs={11}>
            <FormLabel>{dashcamLabel}</FormLabel>
            <YesNoButtonGroup
              name="isDashcam"
              id="isDashcam"
              defaultValue={isDashcam}
              data-testid="isDashcamTest"
              onChange={(_, value) =>
                trackCustomFormotivInput(getYesNoLabel(isDashcamFormotiv, value), "button", value)
              }
            />
          </Grid>
          <Grid item xs={11}>
            <FormLabel>Is your bike financed?</FormLabel>
            <YesNoButtonGroup
              name="isFinanced"
              id="isFinanced"
              defaultValue={isFinanced}
              data-testid="isFinancedTest"
              onChange={(_, value) =>
                trackCustomFormotivInput(getYesNoLabel(isFinancedFormotiv, value), "button", value)
              }
            />
          </Grid>
          <Grid item xs={11}>
            {watchIsFinancedYes === true && renderFinancierList()}
          </Grid>
          <Grid item xs={11}>
            <Button type="submit" data-testid="submit" color="primary" variant="contained" fullWidth>
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
