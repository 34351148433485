import { IconButton, SnackbarContent, styled } from "@mui/material";

export const StyledIconButton = styled(IconButton)({
  color: "ffffff",
});

export const StyledSnackbarContent = styled(SnackbarContent)(({ theme }) => ({
  width: "600px",
  height: "48px",
  background: theme.palette.primary.contrastText,
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "3px",
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));
