import { Grid, Typography } from "@mui/material";
import { FinePrint, RacwaEdit } from "@racwa/react-components";
import { useGetSessionState } from "raci-react-library";
import { Link as RouterLink } from "react-router-dom";
import { Loadable } from "recoil";
import { TellUsMoreAboutYourBikeRefData } from "../../../../shared/hooks/useApiClient/ClientProxy";
import { QuoteRoutes, quoteRoutes } from "../../../../shared/routing/routes.config";
import { capitalizeFirstLetter } from "../../../../shared/utils/string";
import { BikeDetailsPageData } from "../../../types";

export interface BikeUsageProps {
  isEditLocked?: boolean;
  tellUsMoreAboutYourBikeDetails: Loadable<TellUsMoreAboutYourBikeRefData>;
}

export const BikeUsage = ({ isEditLocked, tellUsMoreAboutYourBikeDetails }: BikeUsageProps) => {
  const bikeDetailsPageData = useGetSessionState<BikeDetailsPageData>(QuoteRoutes.BikeUsage);

  const kmPerYear =
    bikeDetailsPageData.selectedAnnualKmsOption && tellUsMoreAboutYourBikeDetails.state === "hasValue"
      ? tellUsMoreAboutYourBikeDetails.contents.annualKmsOptions.find(
          (i) => i.externalCode === bikeDetailsPageData.selectedAnnualKmsOption,
        )?.description
      : "";

  const garage = bikeDetailsPageData.isGaraged ? "In garage overnight" : "Not in garage overnight";

  const tracker =
    bikeDetailsPageData.selectedSecurityOptions &&
    bikeDetailsPageData.selectedSecurityOptions.some((i: string) => i === "Tracker")
      ? "Has tracker"
      : "No tracker";

  const immobiliser =
    bikeDetailsPageData.selectedSecurityOptions &&
    bikeDetailsPageData.selectedSecurityOptions.some((i: string) => i === "Immobiliser")
      ? "Has immobiliser"
      : "No immobiliser";

  const dashcam = bikeDetailsPageData.isDashcam ? "Has dashcam" : "No dashcam";

  const financier = bikeDetailsPageData.isFinanced
    ? `Financier ${bikeDetailsPageData.selectedFinancier?.financierName}`
    : "No finance";

  const suburb = `${capitalizeFirstLetter(bikeDetailsPageData.selectedSuburb?.name)} ${bikeDetailsPageData
    .selectedSuburb?.postcode}`;

  const renderEdit = isEditLocked ? null : (
    <Grid item xs={2} textAlign="right">
      <RacwaEdit link={quoteRoutes.BikeDetails.path} dataTestId="editBikeUsageLink" component={RouterLink} />
    </Grid>
  );

  return (
    <Grid container spacing={1} alignItems="center" data-testid="yourBikeUsageContainer">
      <Grid item xs={12}>
        <Typography variant="h4">Bike usage</Typography>
      </Grid>
      <Grid item xs={10}>
        <FinePrint>{kmPerYear}</FinePrint>
      </Grid>
      <Grid item xs={10}>
        <FinePrint>{garage}</FinePrint>
      </Grid>
      <Grid item xs={10}>
        <FinePrint>{immobiliser}</FinePrint>
      </Grid>
      <Grid item xs={10}>
        <FinePrint>{tracker}</FinePrint>
      </Grid>
      {renderEdit}
      <Grid item xs={10}>
        <FinePrint textTransform="capitalize" data-testid="suburb" data-hj-suppress>
          {suburb}
        </FinePrint>
      </Grid>
      <Grid item xs={10}>
        <FinePrint>{dashcam}</FinePrint>
      </Grid>
      <Grid item xs={10}>
        <FinePrint>{financier}</FinePrint>
      </Grid>
    </Grid>
  );
};

export default BikeUsage;
