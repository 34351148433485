import { theme } from "@racwa/react-components";
import { useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import React, { useEffect, useState } from "react";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { ApiException, PaymentFrequency, PaymentResponse } from "../../../../shared/hooks/useApiClient/ClientProxy";
import { PolicyRoutes, QuoteRoutes } from "../../../../shared/routing/routes.config";
import { AreYouAMemberPageData, PaymentPageData, TellUsMoreAboutYouPageData } from "../../../types";

const NUMBER_OF_INPUT_FIELDS_WITHOUT_CVC = 4;
const NUMBER_OF_INPUT_FIELDS_WITH_CVC = 5;

let changedFields: string[] = [];

declare global {
  interface Window {
    QuickstreamAPI: any;
  }
}

const labelStyle = {
  color: theme.palette.text.secondary,
  "font-weight": 400,
  "font-family": "'Stag Sans Web', Helvetica, Arial, sans-serif",
  "font-size": "1.125rem",
  "margin-bottom": "0.5rem",
};

const inputStyle = {
  ...labelStyle,
  "border-radius": "3px",
  "box-shadow": "rgba(0, 0, 0, 0.075)",
  border: `1px solid ${theme.palette.grey["300"]}`,
  color: "rgb(24, 45, 60)",
  "font-size": "1.125rem",
  "font-weight": "300",
  "line-height": "21.3768px",
  padding: "10.5px 10px 10.5px 10px",
  margin: 0,
  height: "44.375px",
};

export const handleIssuePolicyException = (
  ex: any,
  setError: (isError: boolean) => void,
  onUnsuccessfulSubmit: (policyNumber: string) => void,
  onTryAgainCallback: (policyNumber?: string) => void,
) => {
  const apiException = ex as ApiException;

  if (apiException.status === 422) {
    const policyNumber = (apiException.result as PaymentResponse)?.policyNumber;
    const hasRetryAttempt = !!Object.keys(apiException.headers).find((key) => key.toLowerCase() === "retry-after");
    if (hasRetryAttempt) {
      onTryAgainCallback(policyNumber);
      return;
    } else if (policyNumber) {
      onUnsuccessfulSubmit(policyNumber);
      return;
    }
  }

  setError(true);
};

export interface WestpacScriptResult {
  trustedFrame: React.MutableRefObject<TrustedFrame | null>;
  onSubmitCreditCard: (frequency: PaymentFrequency) => void;
  showAuthorisationCard: boolean;
  isFormFilledOut: boolean;
  isError: boolean;
  isEditLocked: boolean;
  policyNumber: string;
  onDoneClick: () => void;
}

interface QuickStreamEvent {
  eventType: string;
  fieldName: string;
  errorMessages?: string[];
}

interface TrustedFrame extends React.MutableRefObject<any | null> {
  submitForm(callBack: any): () => void;
  teardown: (callback?: (errors: Error[], data: any) => void) => void;
  setEventHandler: (
    fieldName: "cardholderName" | "cardNumber" | "expiryDateMonth" | "expiryDateYear" | "cvn",
    event: "focus" | "change" | "error" | "blur",
    handler: (event: QuickStreamEvent) => void,
  ) => void;
}

function onlyUnique(value: any, index: number, self: any[]) {
  return self.indexOf(value) === index;
}

const useWestpacScript = (
  isCreditCardSelected: boolean,
  isPaymentFrequencyMonthly: boolean,
  publishableApiKey: string,
  redirectFunction: () => void,
  openTryAgainDialogFunction: (openTryAgainDialog: boolean) => void,
): WestpacScriptResult => {
  const apiClient = useApiClient();
  const initialTrustedFrameState = React.createRef<TrustedFrame | null>();
  const trustedFrame = React.useRef<TrustedFrame | null>(initialTrustedFrameState.current);
  const [policyNumber, setPolicyNumber] = useState("");
  const setBackdrop = useSetBackdrop();
  const [showAuthorisationCard, setShowAuthorisationCard] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isEditLocked, setIsEditLocked] = useState(false);
  const [, setPaymentState] = useSessionState<PaymentPageData>();
  const tellUsMoreAboutYou = useGetSessionState<TellUsMoreAboutYouPageData>(PolicyRoutes.PersonalInformation);
  const areYouAMember = useGetSessionState<AreYouAMemberPageData>(QuoteRoutes.MemberDetails);

  const handleUnsuccessfulSubmit = (policyNumber: string) => {
    setPaymentState({
      isCompleted: true,
      isSuccessful: false,
      policyNumber,
    });
    redirectFunction();
  };

  const onSubmitCreditCard = async (frequency: PaymentFrequency) => {
    if (trustedFrame && trustedFrame.current) {
      trustedFrame.current.submitForm((errors: any, data: any) => {
        if (errors) {
          console.log(JSON.stringify(errors));
        } else {
          const singleUseTokenId = data.singleUseToken.singleUseTokenId;
          const form = document.getElementById("payment-form");
          window.QuickstreamAPI.creditCards.appendTokenToForm(form, singleUseTokenId);

          const memberName = tellUsMoreAboutYou.firstName
            ? tellUsMoreAboutYou.firstName
            : areYouAMember.firstName
            ? areYouAMember.firstName
            : "";

          const performPurchase = async () => {
            try {
              setBackdrop(true);
              const response = await apiClient.creditcardpayment({
                frequency,
                accountDetails: {
                  token: singleUseTokenId,
                },
              });
              setPaymentState({
                isCompleted: true,
                isSuccessful: true,
                policyNumber: response.result.policyNumber,
                riderFirstName: memberName,
              });
              setIsError(false);
              redirectFunction();
            } catch (ex) {
              const handleTryAgain = (policyNumber?: string) => {
                setPolicyNumber(policyNumber ?? "");
                openTryAgainDialogFunction(true);
                setIsEditLocked(!!policyNumber);
                setPaymentState({
                  isAnnualCCPaymentInProgress: !isPaymentFrequencyMonthly,
                });
              };
              handleIssuePolicyException(ex, setIsError, handleUnsuccessfulSubmit, handleTryAgain);
            } finally {
              setBackdrop(false);
            }
          };

          performPurchase();
        }
      });
    }
  };

  useEffect(() => {
    setShowAuthorisationCard(false);
    if (!isCreditCardSelected && trustedFrame.current) {
      trustedFrame.current.teardown();
      trustedFrame.current = null;
      changedFields = [];
    }
  }, [isCreditCardSelected]);

  useEffect(() => {
    let isMounted = true;

    const trackCompletion = (frame: TrustedFrame | null) => {
      if (!frame) {
        return;
      }

      changedFields = [];

      const trackChanges = (event: QuickStreamEvent) => {
        const newChangedFields = [...changedFields, event.fieldName].filter(onlyUnique);
        changedFields = newChangedFields;
        const fieldsCount = isPaymentFrequencyMonthly
          ? NUMBER_OF_INPUT_FIELDS_WITHOUT_CVC
          : NUMBER_OF_INPUT_FIELDS_WITH_CVC;
        setShowAuthorisationCard(changedFields.length === fieldsCount);
      };

      frame.setEventHandler("cardNumber", "change", trackChanges);
      frame.setEventHandler("cardholderName", "change", trackChanges);
      frame.setEventHandler("cvn", "change", trackChanges);
      frame.setEventHandler("expiryDateMonth", "change", trackChanges);
      frame.setEventHandler("expiryDateYear", "change", trackChanges);
    };

    const createTrustedFrameHandler = (errors: any, data: any) => {
      if (errors) {
        // Handle errors here
        console.log(`errors: ${JSON.stringify(errors)}`);
      } else {
        trustedFrame.current = data.trustedFrame;
        trackCompletion(trustedFrame.current);
      }
    };

    if (isMounted && window?.QuickstreamAPI && isCreditCardSelected && publishableApiKey) {
      changedFields = [];
      trustedFrame?.current?.teardown();
      window.QuickstreamAPI.init({ publishableApiKey });
      const height = isPaymentFrequencyMonthly ? "320px" : "420px";
      const options = {
        config: {
          supplierBusinessCode: "RACIAPI",
        },
        body: {
          style: {
            width: "100%",
            height: "100%",
          },
        },
        labels: {
          style: {
            ...labelStyle,
            "margin-bottom": "0.5rem",
          },
        },
        iframe: {
          width: "100%",
          height,
          style: {
            width: "100%",
            height,
          },
        },
        cardholderName: {
          label: "Name on card",
          placeholder: "e.g. John Smith",
          style: inputStyle,
        },
        cardNumber: {
          label: "Card number",
          placeholder: "e.g. 1234 5678 1234 5678",
          style: inputStyle,
        },
        expiryDateMonth: {
          style: inputStyle,
        },
        expiryDateYear: {
          style: inputStyle,
        },
        cvn: {
          hidden: isPaymentFrequencyMonthly,
          label: "CVC",
          showHelp: false,
          placeholder: "xxx",
          style: inputStyle,
        },
      };
      window.QuickstreamAPI.creditCards.createTrustedFrame(options, createTrustedFrameHandler);
    }

    return () => {
      isMounted = false;
    };
  }, [isCreditCardSelected, isPaymentFrequencyMonthly, publishableApiKey]);

  return {
    trustedFrame: trustedFrame,
    onSubmitCreditCard,
    showAuthorisationCard,
    isFormFilledOut: showAuthorisationCard,
    isError,
    isEditLocked,
    policyNumber,
    onDoneClick: () => handleUnsuccessfulSubmit(policyNumber),
  };
};

export default useWestpacScript;
