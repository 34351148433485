import { atom, RecoilState } from "recoil";
import { suburbQuery } from "../../../views/BikeUsage/hooks/useSuburbs";
import { tellUsMoreAboutYourBikeRefDataQuery } from "../../../views/BikeUsage/hooks/useTellUsMoreAboutYourBikeRefData";
import { SESSION_KEY_PREFIX } from "../../constants";
import { Manufacturer, Suburb, TellUsMoreAboutYourBikeRefData } from "../../hooks/useApiClient/ClientProxy";
import { manufacturersList } from "../selectors";

export interface ToastNotificationData {
  component: React.ReactNode;
}

export interface FormotivQuoteNumber {
  hasBeenPushed: boolean;
}

export const manufacturersData = atom<Manufacturer[]>({
  key: `${SESSION_KEY_PREFIX}ManufacturersData`,
  default: manufacturersList,
});

export const suburbListData = atom<Suburb[]>({
  key: `${SESSION_KEY_PREFIX}SuburbList`,
  default: suburbQuery,
});

export const tellUsMoreAboutYouData = atom<TellUsMoreAboutYourBikeRefData>({
  key: `${SESSION_KEY_PREFIX}TellUsMoreAboutYouData`,
  default: tellUsMoreAboutYourBikeRefDataQuery,
});

export const toastNotificationData = atom<ToastNotificationData>({
  key: `${SESSION_KEY_PREFIX}toastNotificationData`,
  default: { component: null },
});

export const formotivQuoteNumber = atom<FormotivQuoteNumber>({
  key: `${SESSION_KEY_PREFIX}formotiveQuoteNumber`,
  default: { hasBeenPushed: false },
});

export const atoms: RecoilState<any>[] = [manufacturersData];
