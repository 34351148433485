import { useEffect, useRef, useState } from "react";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { AddressSummary, ApiException } from "../../../../shared/hooks/useApiClient/ClientProxy";
import useDebounce from "../../../../shared/hooks/useDebounce";
import { MailingAddressProps } from "../../components/MailingAddress";

interface UseMailingAddressResult extends Omit<MailingAddressProps, "control"> {}

export const useMailingAddress = (mailingAddress?: AddressSummary | null): UseMailingAddressResult => {
  const apiClient = useApiClient();
  const [searchTerm, setSearchTerm] = useState<string>(mailingAddress?.displayAddress || "");
  const [addresses, setAddresses] = useState<AddressSummary[]>([]);
  const [address, setAddress] = useState<AddressSummary | null>(mailingAddress || null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onSelection = (selectedAddress: AddressSummary | null) => {
    setSearchTerm(selectedAddress?.displayAddress || "");
    setAddress(selectedAddress || null);
    setAddresses([]);
  };
  const onInputChange = (value: string) => {
    if (!value) {
      setAddress(null);
      setAddresses([]);
    }

    if (
      addresses.length > 0 &&
      addresses.some((a) => a.displayAddress.toLocaleLowerCase().includes(value.toLocaleLowerCase())) === false
    ) {
      setAddresses([]);
    }

    setSearchTerm(value);
  };

  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const mailingAddressRef = useRef(mailingAddress);

  useEffect(() => {
    let isCancelled = false;

    if (debouncedSearchTerm) {
      if (debouncedSearchTerm.length >= 10 && debouncedSearchTerm !== address?.displayAddress) {
        setIsLoading(true);

        const fetchAddresses = async () => {
          setIsError(false);
          setIsLoading(true);
          try {
            if (!isCancelled) {
              const response = await apiClient.getaddresses(debouncedSearchTerm);
              setAddresses(response.result);
            }
          } catch (exception) {
            const errorResponse = exception as ApiException;
            if (errorResponse.status >= 500) {
              setIsError(true);
              return;
            }
            setAddresses([]);
            setIsError(true);
          } finally {
            setIsLoading(false);
          }
        };

        fetchAddresses();
      }
    } else {
      setAddress(null);
      setAddresses([]);
    }

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    mailingAddressRef.current = mailingAddress;
  }, [mailingAddress]);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled && mailingAddressRef.current) {
      setAddress(mailingAddressRef.current);
    }
    return () => {
      isCancelled = true;
    };
  }, [mailingAddressRef.current?.displayAddress]);

  return {
    data: addresses,
    selectedValue: address,
    isError,
    isLoading,
    onSelection,
    onInputChange,
  };
};

export default useMailingAddress;
