import { lazy } from "react";
import RiderDetailsPage from "../../views/AboutYou";
import BikeDetailsPage from "../../views/BikeUsage";
import LetsClarifyMoreDetailsPage from "../../views/ConfirmPolicyDetails";
import AreYouAMemberPage from "../../views/MemberDetails";
import PaymentPage from "../../views/Payment";
import TellUsMoreAboutYouPage from "../../views/PersonalInformation";
import BikeSelectionPage from "../../views/YourBike";
import QuotePage from "../../views/YourQuote";
const SessionTimeout = lazy(() => import("../../views/SessionTimeout"));
const SystemUnavailable = lazy(() => import("../../views/SystemUnavailable"));
const ConfirmationPage = lazy(() => import("../../views/Confirmation"));

export enum QuoteRoutes {
  MemberDetails = "AreYouAMember",
  YourBike = "BikeSelection",
  BikeUsage = "BikeDetails",
  AboutYou = "RiderDetails",
  YourQuote = "Quote",
}

export enum PolicyRoutes {
  ConfirmPolicyDetails = "LetsClarifyMoreDetails",
  PersonalInformation = "TellUsMoreAboutYou",
  Payment = "Payment",
  Confirmation = "Confirmation",
}

export enum UtilityPageHeading {
  SessionTimeout = "Uh oh!",
  SystemUnavailable = "Uh oh!",
}

const sessionTimeoutPage = "408";
const systemUnavailablePage = "Error";

export const QUOTE_OR_POLICY_SUB_ROUTE = "Quote";

export const QUOTE_MEMBER_DETAILS_PAGE_URL = `${process.env.PUBLIC_URL}/${QuoteRoutes.MemberDetails}`;
export const QUOTE_BIKE_SELECTION_PAGE_URL = `${process.env.PUBLIC_URL}/${QuoteRoutes.YourBike}`;
export const QUOTE_BIKE_DETAILS_PAGE_URL = `${process.env.PUBLIC_URL}/${QuoteRoutes.BikeUsage}`;
export const QUOTE_RIDER_DETAILS_PAGE_URL = `${process.env.PUBLIC_URL}/${QuoteRoutes.AboutYou}`;
export const QUOTE_YOUR_QUOTE_PAGE_URL = `${process.env.PUBLIC_URL}/${QuoteRoutes.YourQuote}`;

export const POLICY_CONFIRM_POLICY_DETAILS_PAGE_URL = `${process.env.PUBLIC_URL}/${PolicyRoutes.ConfirmPolicyDetails}`;
export const POLICY_PERSONAL_INFORMATION_PAGE_URL = `${process.env.PUBLIC_URL}/${PolicyRoutes.PersonalInformation}`;
export const POLICY_PAYMENT_PAGE_URL = `${process.env.PUBLIC_URL}/${PolicyRoutes.Payment}`;
export const POLICY_CONFIRMATION_PAGE_URL = `${process.env.PUBLIC_URL}/${PolicyRoutes.Confirmation}`;

export const UTILITY_SESSION_TIMEOUT_PAGE_URL = `${process.env.PUBLIC_URL}/${sessionTimeoutPage}`;
export const UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL = `${process.env.PUBLIC_URL}/${systemUnavailablePage}`;

export const RAC_HOME_PAGE_URL = `${process.env.REACT_APP_RAC_HOME_PAGE}/`;
export const HOME_PAGE_URL = `${process.env.PUBLIC_URL}/`;
export const ALIVE_PAGE_URL = `${HOME_PAGE_URL}Alive`;

export interface RouteInformation {
  key: string;
  path: string;
  name: string;
  element: JSX.Element;
  heading?: string;
}

export const quoteRoutes: Record<QuoteRoutes, RouteInformation> = {
  [QuoteRoutes.MemberDetails]: {
    key: QuoteRoutes.MemberDetails,
    path: QUOTE_MEMBER_DETAILS_PAGE_URL,
    name: "Member details",
    element: <AreYouAMemberPage />,
  },
  [QuoteRoutes.YourBike]: {
    key: QuoteRoutes.YourBike,
    path: QUOTE_BIKE_SELECTION_PAGE_URL,
    name: "Your bike",
    element: <BikeSelectionPage />,
  },
  [QuoteRoutes.BikeUsage]: {
    key: QuoteRoutes.BikeUsage,
    path: QUOTE_BIKE_DETAILS_PAGE_URL,
    name: "Bike usage",
    element: <BikeDetailsPage />,
  },
  [QuoteRoutes.AboutYou]: {
    key: QuoteRoutes.AboutYou,
    path: QUOTE_RIDER_DETAILS_PAGE_URL,
    name: "About you",
    element: <RiderDetailsPage />,
  },
  [QuoteRoutes.YourQuote]: {
    key: QuoteRoutes.YourQuote,
    path: QUOTE_YOUR_QUOTE_PAGE_URL,
    name: "Your quote",
    element: <QuotePage />,
  },
};

export const policyRoutes: Record<PolicyRoutes, RouteInformation> = {
  [PolicyRoutes.ConfirmPolicyDetails]: {
    key: PolicyRoutes.ConfirmPolicyDetails,
    path: POLICY_CONFIRM_POLICY_DETAILS_PAGE_URL,
    name: "Confirm policy details",
    element: <LetsClarifyMoreDetailsPage />,
  },
  [PolicyRoutes.PersonalInformation]: {
    key: PolicyRoutes.PersonalInformation,
    path: POLICY_PERSONAL_INFORMATION_PAGE_URL,
    name: "Personal information",
    element: <TellUsMoreAboutYouPage />,
  },
  [PolicyRoutes.Payment]: {
    key: PolicyRoutes.Payment,
    path: POLICY_PAYMENT_PAGE_URL,
    name: "Payment",
    element: <PaymentPage />,
  },

  [PolicyRoutes.Confirmation]: {
    key: PolicyRoutes.Confirmation,
    path: POLICY_CONFIRMATION_PAGE_URL,
    name: "Confirmation",
    element: <ConfirmationPage />,
  },
};

export const quoteRoutesArray: RouteInformation[] = Object.values(quoteRoutes);
export const policyRoutesArray: RouteInformation[] = Object.values(policyRoutes);
export const allRoutes = [...quoteRoutesArray, ...policyRoutesArray];

export const utilityRoutes: RouteInformation[] = [
  {
    key: sessionTimeoutPage,
    path: UTILITY_SESSION_TIMEOUT_PAGE_URL,
    name: "Session timed out",
    element: <SessionTimeout />,
    heading: UtilityPageHeading.SessionTimeout,
  },
  {
    key: systemUnavailablePage,
    path: UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
    name: "System unavailable",
    element: <SystemUnavailable />,
    heading: UtilityPageHeading.SystemUnavailable,
  },
];
