import { Grid, Typography } from "@mui/material";
import { FinePrint, RacwaEdit } from "@racwa/react-components";
import { format } from "date-fns";
import { useGetSessionState } from "raci-react-library";
import { Link as RouterLink } from "react-router-dom";
import { PolicyRoutes, policyRoutes } from "../../../../shared/routing/routes.config";
import { LetsClarifyMoreDetailsPageData } from "../../../types";

export interface AdditionalDetailsProps {
  isEditLocked?: boolean;
}

export const AdditionalDetails = ({ isEditLocked }: AdditionalDetailsProps) => {
  const letsClarifyMoreDetailsPageData = useGetSessionState<LetsClarifyMoreDetailsPageData>(
    PolicyRoutes.ConfirmPolicyDetails,
  );

  const bikeRegistration =
    (letsClarifyMoreDetailsPageData.isRegistrationKnown && letsClarifyMoreDetailsPageData.bikeRegistration) ??
    "unknown";

  const policyStartDate = format(new Date(letsClarifyMoreDetailsPageData.selectedDate ?? new Date()), "dd/MM/yyyy");

  const renderAccidents = !letsClarifyMoreDetailsPageData.isHadAccident ? (
    <Grid item xs={10}>
      <FinePrint data-testid="noAccidents">No motorcycle accidents or claims in the past 3 years</FinePrint>
    </Grid>
  ) : null;

  const renderLicenceCancelled = !letsClarifyMoreDetailsPageData.isLicenceCancelled ? (
    <Grid item xs={10}>
      <FinePrint data-testid="LicenceCancelled">
        No cancellation, suspension or special conditions applied to motorcycle licence in the past 3 years
      </FinePrint>
    </Grid>
  ) : null;

  const renderEdit = isEditLocked ? null : (
    <Grid item xs={2} textAlign="right">
      <RacwaEdit
        link={policyRoutes.LetsClarifyMoreDetails.path}
        dataTestId="editAdditionalDetailsLink"
        component={RouterLink}
      />
    </Grid>
  );

  return (
    <Grid container spacing={1} alignItems="center" data-testid="aboutAdditionalDetailsContainer">
      <Grid item xs={12}>
        <Typography variant="h4">Additional details</Typography>
      </Grid>
      <Grid item xs={10}>
        <FinePrint>{`Policy start date ${policyStartDate}`}</FinePrint>
      </Grid>
      <Grid item xs={10}>
        <FinePrint>{`Bike registration ${bikeRegistration}`}</FinePrint>
      </Grid>
      {renderEdit}
      {renderAccidents}
      {renderLicenceCancelled}
    </Grid>
  );
};

export default AdditionalDetails;
