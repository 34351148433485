import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { Manufacturer } from "../../../../shared/hooks/useApiClient/ClientProxy";
import { manufacturersList } from "../../../../shared/recoil/selectors";
import { ManufacturerProps } from "../../components/ManufacturerSelection";

interface UseManufacturersResult extends ManufacturerProps {}

export const useManufacturers = (manufacturerExternalCode?: string | null): UseManufacturersResult => {
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const [manufacturer, setManufacturer] = useState<string | null>(manufacturerExternalCode || null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onSelection = (selectedManufacturer: Manufacturer | null) => {
    setManufacturer(selectedManufacturer?.externalCode || null);
  };

  const manufacturersListData = useRecoilValue(manufacturersList);

  useEffect(() => {
    let isCancelled = false;

    const fetchManufacturers = () => {
      setIsError(false);
      setIsLoading(true);
      try {
        if (!isCancelled) {
          setManufacturers(manufacturersListData);
        }
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchManufacturers();

    return () => {
      isCancelled = true;
    };
  }, [manufacturersListData]);

  return {
    data: manufacturers,
    selectedManufacturer: manufacturer,
    isError,
    onSelection,
    isLoading,
  };
};

export default useManufacturers;
