import { selector } from "recoil";
import { useApiClient as ApiClient } from "../../hooks/useApiClient";

export const manufacturersList = selector({
  key: "manufacturersList",
  get: async () => {
    const apiClient = ApiClient();
    return (await apiClient.getmanufacturers())?.result;
  },
});
