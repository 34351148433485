import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "@mui/material";
import { gtm, phoneCall } from "@racwa/analytics";
import { RacwaCardNotification } from "@racwa/react-components";
import { Raci13PhoneNumber } from "../../constants/helperText";

export interface NeedMoreInformationCardNotificationProps {
  quoteNumber: string;
  show?: boolean | null;
}

export const NeedMoreInformationCardNotification = ({
  quoteNumber,
  show = true,
  ...props
}: NeedMoreInformationCardNotificationProps) =>
  show ? (
    quoteNumber ? (
      <RacwaCardNotification
        {...props}
        title="We need some more information"
        data-testid="needMoreInfoCard"
        icon={<FontAwesomeIcon icon={faPhone} />}
      >
        Please call{" "}
        <Link href={Raci13PhoneNumber.href} onClick={() => gtm(phoneCall("131703"))}>
          {Raci13PhoneNumber.display}
        </Link>
        , quoting{" "}
        <strong id="need-more-info-quote-number" data-testid="needMoreInfoQuoteNumber">
          {quoteNumber}
        </strong>{" "}
        for assistance to complete your policy.
      </RacwaCardNotification>
    ) : (
      <RacwaCardNotification
        {...props}
        title="We need some more information"
        data-testid="needMoreInfoCard"
        icon={<FontAwesomeIcon icon={faPhone} />}
      >
        Please call{" "}
        <Link href={Raci13PhoneNumber.href} onClick={() => gtm(phoneCall("131703"))}>
          {Raci13PhoneNumber.display}
        </Link>{" "}
        for assistance to complete your policy.
      </RacwaCardNotification>
    )
  ) : null;

export default NeedMoreInformationCardNotification;
