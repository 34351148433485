import { SESSION_KEY_PREFIX } from "../../../constants";

type ScoreBoundary = {
  lower: number;
  upper: number;
};

type QuantileScoreBoundaries = {
  group1: ScoreBoundary;
  group2: ScoreBoundary;
  group3: ScoreBoundary;
  group4: ScoreBoundary;
  group5: ScoreBoundary;
};

export const FormotivScoreBoundaries: QuantileScoreBoundaries = {
  group1: {
    lower: 0.072214,
    upper: 0.180032,
  },
  group2: {
    lower: 0.180127,
    upper: 0.250754,
  },
  group3: {
    lower: 0.250804,
    upper: 0.31522,
  },
  group4: {
    lower: 0.315541,
    upper: 0.385712,
  },
  group5: {
    lower: 0.385724,
    upper: 0.575846,
  },
};

export const FORMOTIV_DIALOG_ENABLED = process.env.REACT_APP_FORMOTIV_DIALOG_ENABLED === "true";
export const FORMOTIV_INVALID_MODEL_SCORE = -1;
export const FORMOTIV_SAMPLE_PROPORTION = 0.8;
export const FORMOTIV_SCORE_LOWER_BOUNDARY = FormotivScoreBoundaries.group1.lower;
export const FORMOTIV_SCORE_UPPER_BOUNDARY = FormotivScoreBoundaries.group2.upper;

export const STORAGE_KEY_FORMOTIV_SHOW_DIALOG = `${SESSION_KEY_PREFIX}FORMOTIV_SHOW_DIALOG`;
export const STORAGE_KEY_FORMOTIV_DIALOG_SHOWN = `${SESSION_KEY_PREFIX}FORMOTIV_DIALOG_SHOWN`;
