import { useGetSessionState } from "raci-react-library";
import { useRecoilValue } from "recoil";
import { Manufacturer } from "../../../../shared/hooks/useApiClient/ClientProxy";
import { manufacturersData } from "../../../../shared/recoil/atoms";
import { QuoteRoutes } from "../../../../shared/routing/routes.config";
import { BikeSelectionPageData } from "../../../types";

interface UseSelectedBikeValue {
  manufacturer?: Manufacturer;
}

export const useSelectedBikeValue = (): UseSelectedBikeValue => {
  const manufacturers = useRecoilValue(manufacturersData);
  const { selectedManufacturer } = useGetSessionState<BikeSelectionPageData>(QuoteRoutes.YourBike);

  const manufacturer = manufacturers.find((x) => x.externalCode === selectedManufacturer);

  return {
    manufacturer,
  };
};

export default useSelectedBikeValue;
