import { MenuItem } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaSelect } from "@racwa/react-components";
import React from "react";
import { isMobileOnly } from "react-device-detect";
import { ControllerRenderProps } from "react-hook-form";
import { Loadable } from "recoil";
import { TellUsMoreAboutYourBikeRefData } from "../../../../shared/hooks/useApiClient/ClientProxy";

const dataTestId = "travelDistanceInputTest";

export interface AnnualKilometresSelectProps extends Omit<ControllerRenderProps<Record<string, any>>, "ref"> {
  tellUsMoreAboutYourBikeRefData: Loadable<TellUsMoreAboutYourBikeRefData>;
  error?: boolean;
  helperText?: string;
  label?: string;
  inputRef?: React.Ref<any>;
}

export const AnnualKilometresSelect = ({
  tellUsMoreAboutYourBikeRefData,
  label = "",
  ...props
}: AnnualKilometresSelectProps) => {
  const options =
    tellUsMoreAboutYourBikeRefData.state === "hasValue"
      ? tellUsMoreAboutYourBikeRefData.contents.annualKmsOptions.map((option) => {
          const optionProps = {
            id: option.externalCode,
            key: option.externalCode,
            value: option.externalCode,
            "data-testid": `travelDistanceOptionTest${option.externalCode}`,
          };
          return isMobileOnly ? (
            <option {...optionProps}>{option.description}</option>
          ) : (
            <MenuItem {...optionProps}>{option.description}</MenuItem>
          );
        })
      : null;

  if (options && isMobileOnly) {
    options.unshift(
      <option key="disabled" disabled selected hidden aria-hidden value="">
        Please select
      </option>,
    );
  } else if (options && !isMobileOnly) {
    options.unshift(
      <MenuItem key="disabled" disabled selected hidden aria-hidden value="">
        Please select
      </MenuItem>,
    );
  }

  return (
    <RacwaSelect
      {...props}
      label={label}
      data-testid={dataTestId}
      native={isMobileOnly}
      displayEmpty
      onBlur={() => {
        props.onBlur && props.onBlur();
        gtm(fieldTouched(label));
      }}
    >
      {options}
    </RacwaSelect>
  );
};

export default AnnualKilometresSelect;
