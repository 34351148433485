import { useEffect, useState } from "react";
import { selector, useRecoilValue } from "recoil";
import { useApiClient as ApiClient } from "../../../../shared/hooks/useApiClient";
import {
  AddressSummary,
  ApiException,
  TellUsMoreAboutYouReferenceData,
  TitleReferenceDataItem,
} from "../../../../shared/hooks/useApiClient/ClientProxy";

const tellUsMoreAboutYouPageReferenceData = selector({
  key: "tellUsMoreAboutYouPageReferenceData",
  get: async () => {
    const apiClient = ApiClient();
    return (await apiClient.gettellmoreaboutyoureferencedata())?.result;
  },
});

interface UseReferenceDataResult extends TellUsMoreAboutYouReferenceData {
  isError?: boolean | false;
  isLoading?: boolean | false;
}

export const useReferenceData = (): UseReferenceDataResult => {
  const tellUsMoreAboutYouReferenceDataValue = useRecoilValue(tellUsMoreAboutYouPageReferenceData);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isContactMatched, setHasContactMatched] = useState<boolean>(false);
  const [titles, setTitles] = useState<TitleReferenceDataItem[]>([]);
  const [address, setAddress] = useState<AddressSummary | undefined>(undefined);
  useEffect(() => {
    let isCancelled = false;
    const fetchReferenceData = () => {
      setIsError(false);
      setIsLoading(true);
      try {
        if (!isCancelled) {
          setHasContactMatched(tellUsMoreAboutYouReferenceDataValue.hasContactMatched);
          setTitles(tellUsMoreAboutYouReferenceDataValue.titles);
          setAddress(tellUsMoreAboutYouReferenceDataValue.postalAddress);
        }
      } catch (error) {
        const errorResponse = error as ApiException;
        if (errorResponse.status >= 500) {
          setIsError(true);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchReferenceData();
    return () => {
      isCancelled = true;
    };
  }, [tellUsMoreAboutYouReferenceDataValue]);
  return {
    isError,
    isLoading,
    hasContactMatched: isContactMatched,
    titles,
    postalAddress: address,
  };
};
export default useReferenceData;
