import { FormControl, FormHelperText, ToggleButton, ToggleButtonGroup, FormLabel } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { Control, Controller, FieldError, UseFormSetValue } from "react-hook-form";
import { RequiredSelectionMessage } from "../../../../shared/constants/helperText";
import { PaymentMethod as PaymentMethodOptions } from "../../../../shared/hooks/useApiClient/ClientProxy";

export interface PaymentMethodProps {
  paymentMethod: PaymentMethodOptions | null;
  control: Control<any>;
  paymentMethodError?: FieldError;
  disabled?: boolean;
  paymentMethodSelectionHandler: (paymentMethod: PaymentMethodOptions | null) => void;
  setValue: UseFormSetValue<any>;
}

export const PaymentMethod = ({
  paymentMethod,
  control,
  paymentMethodError,
  disabled,
  paymentMethodSelectionHandler,
  setValue,
}: PaymentMethodProps) => {
  const requiredValidationRule = {
    required: {
      value: true,
      message: RequiredSelectionMessage,
    },
  };

  return (
    <FormControl error={!!paymentMethodError}>
      <FormLabel data-testid="paymentMethodSelection">Payment method</FormLabel>
      <Controller
        control={control}
        name="paymentMethod"
        defaultValue={paymentMethod}
        rules={requiredValidationRule}
        render={({ field: { ref, ...props } }) => (
          <ToggleButtonGroup
            {...props}
            onChange={(_, value) => {
              window.formotivConfig &&
                window.formotiv?.inputActivity?.(window.formotivConfig, "paymentMethod", "buttongroup", value);
              paymentMethodSelectionHandler(value);
              props.onChange(value);
              setValue(props.name, value, {
                shouldDirty: true,
                shouldValidate: true,
              });
            }}
            onBlur={() => {
              props.onBlur && props.onBlur();
              gtm(fieldTouched("Payment method"));
            }}
            exclusive
            id="paymentMethodButtonGroup"
            data-testid="paymentMethodButtonGroup"
            aria-label="paymentMethodButtonGroup"
          >
            {Object.keys(PaymentMethodOptions).map((item, index) => {
              const paymentMethodOption = Object.values(PaymentMethodOptions)[index];
              return (
                <ToggleButton
                  key={item}
                  value={paymentMethodOption}
                  type="button"
                  role="button"
                  data-testid={`${item}Option`}
                  disabled={disabled}
                  ref={index === 0 ? ref : null}
                >
                  {paymentMethodOption}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        )}
      />
      <FormHelperText>{paymentMethodError?.message || ""}</FormHelperText>
    </FormControl>
  );
};

export default PaymentMethod;
