const locale = "en-AU";
const style = "currency";
const currency = "AUD";

const formatterWithoutDecimal = new Intl.NumberFormat(locale, {
  style,
  currency,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
const formatterWithDecimal = new Intl.NumberFormat("en-AU", {
  style,
  currency,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const format = (value: number, withDecimals: boolean = false): string =>
  withDecimals ? formatterWithDecimal.format(value) : formatterWithoutDecimal.format(value);

export default format;
