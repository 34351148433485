import { useEffect, useState } from "react";
import { Model } from "../../../../shared/hooks/useApiClient/ClientProxy";
import { EngineCapacityProps } from "../../components/EngineCapacity";

export interface UseEngineCapacityResult extends EngineCapacityProps {
  filteredModels: Model[];
}

const extractEngineCapacitiesFromModels = (models: Model[]) => models?.map((model) => model.engineCapacity) || [];

const getDistinctEngineCapacities = (engineCapacities: string[]) =>
  engineCapacities.filter((value, idx, self) => self.indexOf(value) === idx) || [];

const engineCapacityComparer = (a: string, b: string) => {
  if (parseInt(a) && parseInt(b)) {
    return parseInt(a) - parseInt(b);
  } else if (!parseInt(a) && parseInt(b)) {
    return -1;
  } else if (parseInt(a) && !parseInt(b)) {
    return 1;
  }

  return a.localeCompare(b);
};

const orderEngineCapacities = (engineCapacities: string[]) => engineCapacities.sort(engineCapacityComparer);

export const useEngineCapacity = (
  modelList: Model[],
  selectedEngineCapacity?: string | null,
): UseEngineCapacityResult => {
  const [engineCapacity, setEngineCapacity] = useState<string | null>(selectedEngineCapacity || null);
  const [orderedEngineCapacities, setOrderedEngineCapacities] = useState<string[]>([]);
  const [filteredModels, setFilteredModels] = useState<Model[]>([]);
  const onSelectionHandler = (engineCapacity: string | null, resetList?: boolean | false) => {
    const models = modelList.filter((item) => item.engineCapacity === engineCapacity);

    if (resetList) {
      setOrderedEngineCapacities([]);
    }

    setFilteredModels(models);
    setEngineCapacity(engineCapacity);
  };

  useEffect(() => {
    const engineCapacities = extractEngineCapacitiesFromModels(modelList);
    const uniqueEngineCapacities = getDistinctEngineCapacities(engineCapacities);
    const orderedEngineCapacities = orderEngineCapacities(uniqueEngineCapacities);

    setOrderedEngineCapacities(orderedEngineCapacities);

    if (orderedEngineCapacities.length === 1) {
      const selectedEngineCapacity = orderedEngineCapacities[0];
      setFilteredModels(modelList.filter((item) => item.engineCapacity === selectedEngineCapacity));
      setEngineCapacity(selectedEngineCapacity);
    }
  }, [modelList]);

  return {
    data: orderedEngineCapacities,
    selectedEngineCapacity: engineCapacity,
    filteredModels: filteredModels || [],
    onSelection: onSelectionHandler,
  };
};

export default useEngineCapacity;
