import { Grid, Typography } from "@mui/material";
import { CheckMarkIcon } from ".";

const TpftTooltipContent = () => (
  <>
    <Grid item xs={12}>
      <Typography variant="h4">What's covered?</Typography>
    </Grid>
    {CheckMarkIcon}
    <Grid item xs={11}>
      <Typography variant="body2">Damage to your bike caused by fire or uninsured vehicles</Typography>
    </Grid>
    {CheckMarkIcon}
    <Grid item xs={11}>
      <Typography variant="body2">Theft of your bike</Typography>
    </Grid>
    {CheckMarkIcon}
    <Grid item xs={11}>
      <Typography variant="body2">Damage to someone else's property</Typography>
    </Grid>
    {CheckMarkIcon}
    <Grid item xs={11}>
      <Typography variant="body2">Other riders using your bike</Typography>
    </Grid>
  </>
);

export default TpftTooltipContent;
