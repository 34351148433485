import { useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { suburbListData, tellUsMoreAboutYouData } from "../../shared/recoil/atoms";
import {
  PolicyRoutes,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
  policyRoutes,
  quoteRoutes,
} from "../../shared/routing/routes.config";
import useSelectedBikeValue from "../YourBike/hooks/useSelectedBikeValue";
import { BikeDetailsPageData, PaymentPageData } from "../types";
import BikeDetailsForm, { BikeDetailsAnswers } from "./Form";
import { useSuburbs } from "./hooks/useSuburbs";
import { useTellUsMoreAboutYourBikeRefData } from "./hooks/useTellUsMoreAboutYourBikeRefData";

const BikeDetailsPage = () => {
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const [pageCompleted, setPageCompleted] = useState(false);
  const { manufacturer } = useSelectedBikeValue();
  const [state, setState] = useSessionState<BikeDetailsPageData>();
  const { isAnnualCCPaymentInProgress } = useGetSessionState<PaymentPageData>(PolicyRoutes.Payment);

  const suburbList = useSuburbs();
  const [, setSuburbList] = useRecoilState(suburbListData);
  const isSuburbLoading = suburbList.state === "loading";
  const suburbHasError = suburbList.state === "hasError";
  const suburbHasValue = suburbList.state === "hasValue";

  const tellUsMoreAboutYourBikeDetails = useTellUsMoreAboutYourBikeRefData();
  const [, setTellUsMoreAboutYou] = useRecoilState(tellUsMoreAboutYouData);
  const isTellUsLoading = tellUsMoreAboutYourBikeDetails.state === "loading";
  const tellUsHasError = tellUsMoreAboutYourBikeDetails.state === "hasError";
  const tellUsHasValue = tellUsMoreAboutYourBikeDetails.state === "hasValue";

  useEffect(() => {
    if (suburbHasValue && !isSuburbLoading && !suburbHasError) {
      setSuburbList(suburbList.contents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suburbList.state]);

  useEffect(() => {
    if (tellUsHasValue && !isTellUsLoading && !tellUsHasError) {
      setTellUsMoreAboutYou(tellUsMoreAboutYourBikeDetails.contents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tellUsMoreAboutYourBikeDetails.state]);

  const handleSubmit = (newState: BikeDetailsAnswers) => {
    setState({
      ...newState,
      isCompleted: true,
    });
    setPageCompleted(true);
  };

  useEffect(() => {
    if (isTellUsLoading || isSuburbLoading) {
      setBackdrop(true);
    } else {
      setBackdrop(false);
    }

    return () => {
      setBackdrop(false);
    };
  }, [setBackdrop, isTellUsLoading, isSuburbLoading]);

  if (suburbHasError && tellUsHasError) {
    navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
  }

  if (pageCompleted) {
    return <Navigate to={quoteRoutes.RiderDetails.path} />;
  }

  if (isAnnualCCPaymentInProgress) {
    return <Navigate to={policyRoutes.Payment.path} />;
  }

  return tellUsHasValue && suburbHasValue ? (
    <BikeDetailsForm
      {...state}
      suburbList={suburbList}
      tellUsMoreAboutYourBikeRefData={tellUsMoreAboutYourBikeDetails}
      selectedManufacturer={manufacturer?.name ?? ""}
      onSubmit={handleSubmit}
    />
  ) : null;
};

export default BikeDetailsPage;
