import { Button, Grid, Typography } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { ContactNumberInput, EmailInput, FirstNameInput, useFormotiv } from "raci-react-library";
import { useEffect, useRef } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import NeedMoreInformationCardNotification from "../../shared/components/NeedMoreInformationCardNotification";
import { PrivacyPolicy } from "../../shared/components/PrivacyPolicy";
import RacwaNameInput from "../../shared/components/RacwaNameInput";
import { LastNameSizeLimit, NameValidationPattern } from "../../shared/components/RacwaNameInput/constants";
import { pleaseEnterAValidMessage } from "../../shared/constants/helperText";
import { AddressSummary } from "../../shared/hooks/useApiClient/ClientProxy";
import Gender from "./components/Gender";
import MailingAddress, { MailingAddressProps } from "./components/MailingAddress";
import PremiumChangedDialog, { PremiumChangedDialogProps } from "./components/PremiumChangeDialog";
import Titles, { TitleProps } from "./components/Titles";
import { FORMOTIV_CONFIG } from "../../shared/constants";

export interface TellUsMoreAboutYouFormData {
  contactNumber?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  title?: string | null;
  gender?: string | null;
  titleProps: TitleProps;
  mailingAddress?: AddressSummary | null;
  hasContactMatched?: boolean | null;
}

export interface TellUsMoreAboutYouFormHandlers {
  onSubmit?: (newState: TellUsMoreAboutYouFormData) => void | Promise<void>;
}

export type TellUsMoreAboutYouFormProps = TellUsMoreAboutYouFormData &
  TellUsMoreAboutYouFormHandlers & {
    mailingAddressProps: MailingAddressProps;
    premiumChangeDialogProps?: PremiumChangedDialogProps;
    showMoreInfoCard?: boolean;
    quoteNumber: string;
    forceTitleQuestion?: boolean | false;
    forceGenderQuestion?: boolean | false;
  };

export const TellUsMoreAboutYouForm = ({
  title,
  firstName,
  lastName,
  contactNumber,
  email,
  gender,
  titleProps,
  mailingAddressProps,
  hasContactMatched,
  showMoreInfoCard,
  premiumChangeDialogProps,
  quoteNumber,
  onSubmit,
  forceTitleQuestion,
  forceGenderQuestion,
}: TellUsMoreAboutYouFormProps) => {
  const form = useForm<TellUsMoreAboutYouFormData>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      mailingAddress: mailingAddressProps.selectedValue,
      title,
      titleProps,
      firstName,
      lastName,
      contactNumber,
      email,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = form;

  useEffect(() => {
    const isMounted = true;

    if (isMounted && mailingAddressProps.selectedValue) {
      setValue("mailingAddress", mailingAddressProps.selectedValue);
    }
  }, [setValue, mailingAddressProps.selectedValue]);

  const formRef = useRef(null);
  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);
  const onSubmitWrapper = (() => {
    const formValues: TellUsMoreAboutYouFormData = {
      ...getValues(),
      titleProps,
    };
    onSubmit && onSubmit(formValues);
  });

  const showGender = () => {
    if (!titleProps.title) {
      return false;
    }
    return !titleProps.gender;
  };

  if (hasContactMatched) {
    mailingAddressProps.label = "Please confirm your mailing address";
  }

  const moreInfoCard = showMoreInfoCard ? (
    <Grid item xs={12}>
      <NeedMoreInformationCardNotification quoteNumber={quoteNumber} />
    </Grid>
  ) : null;

  const titleQuestion = (
    <Grid item xs={12}>
      <Titles {...titleProps} error={!!errors.title} />
    </Grid>
  );

  const genderQuestion = (
    <Gender
      error={!!errors.gender}
      helperText={(errors.gender as any)?.message || ""}
      value={gender || null}
      show={showGender()}
    />
  );

  const mailingAddress = (
    <Grid item xs={12}>
      <MailingAddress
        {...mailingAddressProps}
        error={!!errors.mailingAddress}
        helperText={(errors.mailingAddress as any)?.message || ""}
      />
    </Grid>
  );

  const shortPageDisplay = (
    <>
      {forceTitleQuestion ? titleQuestion : null}
      {forceGenderQuestion ? genderQuestion : null}
      {mailingAddress}
    </>
  );

  const fullPageDisplay = (
    <>
      <Grid item xs={12}>
        <Titles {...titleProps} error={!!errors.title} />
      </Grid>
      {genderQuestion}
      <Grid item xs={12}>
        <FirstNameInput />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="lastName"
          defaultValue={lastName}
          rules={{
            required: {
              value: true,
              message: pleaseEnterAValidMessage("last name"),
            },
            pattern: {
              value: NameValidationPattern,
              message: pleaseEnterAValidMessage("last name"),
            },
          }}
          render={({ field: { ref, ...props } }) => (
            <RacwaNameInput
              {...props}
              color="primary"
              id="lastName"
              data-testid="lastName"
              label="Last name"
              autoComplete="family-name"
              placeholder="e.g. Smith"
              fullWidth
              inputProps={{
                maxLength: LastNameSizeLimit,
              }}
              inputRef={ref}
              onBlur={() => gtm(fieldTouched("Last name"))}
              error={!!errors.lastName}
              helperText={errors.lastName?.message || ""}
              data-hj-suppress
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <ContactNumberInput />
      </Grid>
      <Grid item xs={12}>
        <EmailInput />
      </Grid>
      {mailingAddress}
      <Grid item xs={12}>
        <PrivacyPolicy />
      </Grid>
      {moreInfoCard}
    </>
  );

  const formInputFields = hasContactMatched ? shortPageDisplay : fullPageDisplay;

  const dialog = premiumChangeDialogProps?.show ? <PremiumChangedDialog {...premiumChangeDialogProps} /> : null;

  return (
    <FormProvider {...form}>
      <form ref={formRef} action="#" onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmitWrapper))} autoComplete="on">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" id="header" data-testid="header">
              Tell us more about you
            </Typography>
          </Grid>
          {formInputFields}
          <Grid item xs={12}>
            <Button
              id="submit"
              name="submit"
              type="submit"
              data-testid="submit"
              color="primary"
              variant="contained"
              fullWidth={true}
              disabled={showMoreInfoCard}
            >
              {hasContactMatched ? `Confirm` : `Next`}
            </Button>
          </Grid>
        </Grid>
      </form>
      {dialog}
    </FormProvider>
  );
};
