import { SESSION_KEY_PREFIX } from "../constants";

export const clearSession = (...excludeKeys: (string | undefined)[]): void => {
  for (let i = sessionStorage.length - 1; i >= 0; i--) {
    const key = sessionStorage.key(i) || "";
    if (key.startsWith(SESSION_KEY_PREFIX) && !excludeKeys.includes(key)) {
      sessionStorage.removeItem(key);
    }
  }
};

// Check if duplicating tab
// Duplicating tab causes sessionStorage to
// also be copied. The below statements
// caters for this by checking for the presence
// of a lock item.
// We allow refresh page to retain session by removing the lock
// on a page refresh therefore bypassing clearSession.
const lockKey = "__lock";
const lockValue = false.toString().toLowerCase();
if (sessionStorage.getItem(lockKey)?.toLowerCase() !== lockValue) {
  clearSession();
}
sessionStorage.setItem(lockKey, lockValue);

// Due to issues with 'Back-forwards cache'
// Force reload pages instead of a 'cached' version on back navigation
window.addEventListener("pageshow", (event) => {
  if (event.persisted) {
    window.location.reload();
  }
});

// Simulate disable forward button
// https://stackoverflow.com/a/51001411
(function disableForwardButton() {
  let flag = false,
    loop = false;
  window.addEventListener("popstate", function () {
    if (flag) {
      loop = window.history.state && Object.prototype.hasOwnProperty.call(window.history.state, "page");
      window.history.go(-1);
    } else {
      window.history.pushState(
        {
          page: true,
        },
        "",
        null,
      );
    }
    flag = loop ? true : !flag;
  });

  window.onclick = function () {
    flag = false;
  };
})();

export {};
