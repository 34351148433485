import { Button, DialogActions, styled } from "@mui/material";

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  "& > :not(:first-child)": {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "19.5px",
    },
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    minWidth: "135px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "8px",
  },
}));
