import { useGetPreviousPageSessionState } from "raci-react-library";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { POLICY_CONFIRMATION_PAGE_URL, POLICY_PAYMENT_PAGE_URL } from "../routes.config";
import useRoutes from "../useRoutes";

export const ManuallyInitialisedPages: string[] = [];

export interface RouteGuardProps {
  disableChecks?: string[];
}

export const RouteGuard = ({ disableChecks = [] }: RouteGuardProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { path: previousPageUrl, isCompleted: isPreviousPageCompleted } = useGetPreviousPageSessionState();
  const { isPaymentInProgress, isCompleted } = useRoutes();

  /**
   * The below will check if the current page can
   * be navigated to by checking if the previous
   * page has been completed.
   */
  useEffect(() => {
    const currentLocation = location.pathname.toLowerCase();
    const bypass = disableChecks.filter((item) => item.toLowerCase() === currentLocation).length > 0;

    if (!bypass && previousPageUrl && !isPreviousPageCompleted && !isPaymentInProgress) {
      navigate(previousPageUrl, { replace: true });
    }
  }, [navigate, disableChecks, previousPageUrl, isPreviousPageCompleted, location.pathname, isPaymentInProgress]);

  /**
   * If user has completed their flow then transition to a different page (eg. quote or payment),
   */
  useEffect(() => {
    if (isPaymentInProgress) {
      navigate(POLICY_PAYMENT_PAGE_URL, { replace: true });
    }
  }, [navigate, isPaymentInProgress]);

  useEffect(() => {
    if (isCompleted) {
      navigate(POLICY_CONFIRMATION_PAGE_URL, { replace: true });
    }
  }, [navigate, isCompleted, isPaymentInProgress]);

  useEffect(() => {
    try {
      const pageName = location.pathname.split("/").pop() ?? "";
      if (window.formotivConfig && !ManuallyInitialisedPages.includes(pageName)) {
        window.formotiv?.init?.(window.formotivConfig);
      }
    } catch {}
  }, [location.pathname]);

  return <></>;
};

export default RouteGuard;
