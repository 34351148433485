import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { theme } from "@racwa/react-components";
import { useEffect, useState } from "react";
import { format } from "../../../../shared/utils/currency";

export interface PremiumChangedDialogProps {
  show?: boolean | null;
  annual?: number | null;
  monthly?: number | null;
  onClose?: () => void;
}

export const PremiumChangedDialog = ({ show, annual, monthly, onClose }: PremiumChangedDialogProps) => {
  const [open, setOpen] = useState(show || false);
  useEffect(() => {
    setOpen(show || false);
  }, [show]);

  return show ? (
    <Dialog
      open={open}
      aria-labelledby="premium-changed-title"
      aria-describedby="premium-changed-content-text"
      PaperProps={{ sx: { borderTop: `4px solid ${theme.palette.primary.main}` } }}
      id="premium-changed-pop-up"
    >
      <DialogTitle id="premium-changed-title">
        <Typography variant="h1">Your quote has been updated</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="premium-changed-content-text" variant="body1">
          <strong>We've updated your quote based on the information you changed.</strong>
        </DialogContentText>
        <Card
          variant="outlined"
          sx={{ backgroundColor: theme.palette.grey["100"], marginTop: "60px", marginBottom: "40px" }}
        >
          <CardContent sx={{ paddingTop: "16px", paddingBottom: "16px" }}>
            <Grid container sx={{ marginBottom: "5px" }}>
              <Grid item xs={8}>
                <Typography variant="h4" align="left">
                  Updated annual price
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h4" id="premium-changed-annual-cost" align="right">
                  <strong>{format(annual || 0, true)}</strong>
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={8}>
                <Typography variant="caption" align="left">
                  New monthly price
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption" align="right" id="premium-changed-monthly-cost" sx={{ display: "block" }}>
                  {format(monthly || 0, true)}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button
          id="button-premium-changed-next"
          data-testid="button-premium-changed-next"
          type="button"
          role="button"
          color="primary"
          variant="contained"
          fullWidth={true}
          onClick={onClose}
        >
          Next
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

export default PremiumChangedDialog;
