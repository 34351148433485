import { faCertificate, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import { theme } from "@racwa/react-components";

export interface MemberDiscountToastNotificationProps {
  membershipTier?: string | null;
}

export enum DiscountTier {
  Gold = "Gold",
  Silver = "Silver",
  Bronze = "Bronze",
  None = "None",
}

const getDiscountPercentage = (membershipTier?: string | null) => {
  if (membershipTier === DiscountTier.Gold) {
    return "10%";
  } else if (membershipTier === DiscountTier.Silver) {
    return "5%";
  } else if (membershipTier === DiscountTier.Bronze) {
    return "4%";
  } else {
    return "None";
  }
};

const MemberDiscountToastNotification = (props: MemberDiscountToastNotificationProps) => {
  const discountPercentage = getDiscountPercentage(props.membershipTier);

  return (
    <Typography variant="body2" sx={{ color: theme.palette.background.paper }}>
      <FontAwesomeIcon icon={faCheck} mask={faCertificate} aria-hidden transform="shrink-8" />
      {""} {discountPercentage} discount applied
    </Typography>
  );
};

export default MemberDiscountToastNotification;
