import { FeatureToggleButton, FeatureToggleButtonProps } from "raci-react-library-npe";
import { useState } from "react";
import { FORMOTIV_DIALOG_ENABLED } from "../../shared/components/FormotivDialog/constants";
import { SESSION_KEY_PREFIX } from "../../shared/constants";

export const STORAGE_KEY_FORMOTIV_DIALOG_TOGGLE = `${SESSION_KEY_PREFIX}FORMOTIV_DIALOG_TOGGLE`;

const description = "Show Formotiv Dialog";

/*
 * Exposes the underlying value of the ShowFormotivDialog feature toggle
 *
 * This allows hooks such as useShowFormotivDialog to conditionally access this value in NPE environments
 * (hooks such as useFeatureToggles cannot be called conditionally)
 */
export const getShowFormotivDialogToggleValue = () => {
  const initialValue = sessionStorage.getItem(STORAGE_KEY_FORMOTIV_DIALOG_TOGGLE);
  return initialValue === null ? null : JSON.parse(initialValue);
};

/**
 * This toggle is used to bypass the checks and random sampling that determine whether the
 * FormotivDialog component is shown on the YourQuote page.
 */
export const ShowFormotivDialogToggle = () => {
  const [enabled, setEnabled] = useState<FeatureToggleButtonProps["enabled"]>(getShowFormotivDialogToggleValue());

  const onSelect: FeatureToggleButtonProps["onSelect"] = (value) => {
    if (value === null) {
      sessionStorage.removeItem(STORAGE_KEY_FORMOTIV_DIALOG_TOGGLE);
    } else {
      sessionStorage.setItem(STORAGE_KEY_FORMOTIV_DIALOG_TOGGLE, value.toString());
    }

    setEnabled(value);
  };

  return FORMOTIV_DIALOG_ENABLED ? (
    <FeatureToggleButton name={description} description={description} enabled={enabled} onSelect={onSelect} />
  ) : (
    <></>
  );
};
