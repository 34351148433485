import { format } from "date-fns";
import { useState } from "react";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { ApiException, LetsClarifyMoreDetailsRequest } from "../../../../shared/hooks/useApiClient/ClientProxy";
import { LetsClarifyMoreDetailsFormAnswers } from "../../Form";

export interface UseLetsClarifyMoreDetails {
  onSubmit: (data: LetsClarifyMoreDetailsFormAnswers) => Promise<boolean>;
  isError?: boolean;
}

export const useLetsClarifyMoreDetails = (): UseLetsClarifyMoreDetails => {
  const apiClient = useApiClient();
  const [isError, setIsError] = useState(false);

  const onSubmit = async (data: LetsClarifyMoreDetailsFormAnswers) => {
    setIsError(false);
    let startDate: any = data.selectedDate;
    if (startDate.getMonth !== "function") {
      startDate = new Date(Date.parse(startDate));
    }

    const request: LetsClarifyMoreDetailsRequest = {
      // @ts-ignore
      startDate: format(startDate, "yyyy-MM-dd"),
    };

    if (data.isRegistrationKnown && data.bikeRegistration) {
      request.registrationNumber = data.bikeRegistration;
    }

    try {
      await apiClient.updatepolicydetails(request);
    } catch (exception) {
      const errorResponse = exception as ApiException;
      if (errorResponse.status >= 500) {
        setIsError(true);
        return false;
      }
    }

    return true;
  };

  return {
    onSubmit,
    isError,
  };
};
