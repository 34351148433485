import { useGetSessionState, useSessionState } from "raci-react-library";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Manufacturer } from "../../shared/hooks/useApiClient/ClientProxy";
import {
  PolicyRoutes,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
  policyRoutes,
  quoteRoutes,
} from "../../shared/routing/routes.config";
import { BikeSelectionPageData, PaymentPageData } from "../types";
import { BikeSelectionForm, BikeSelectionFormProps } from "./Form";
import { useEngineCapacity } from "./hooks/useEngineCapacity";
import { useManufacturers } from "./hooks/useManufacturers";
import { useModels } from "./hooks/useModels";
import useYears from "./hooks/useYears";

export interface VehicleIdData extends BikeSelectionPageData {}

export const BikeSelectionPage = () => {
  const [pageCompleted, setPageCompleted] = useState(false);
  const [pageState, setPageState] = useSessionState<BikeSelectionPageData>();
  const { isAnnualCCPaymentInProgress } = useGetSessionState<PaymentPageData>(PolicyRoutes.Payment);

  const {
    selectedManufacturer,
    onSelection: onManufacturerSelection,
    ...manufacturerProps
  } = useManufacturers(pageState.selectedManufacturer);

  const {
    selectedYear,
    onSelection: onYearSelection,
    ...yearSelectionProps
  } = useYears(selectedManufacturer || null, pageState.selectedYear);

  const {
    filteredModels,
    selectedModel,
    onSelection: onModelSelection,
    ...bikeModelProps
  } = useModels(selectedManufacturer || null, selectedYear || null, pageState.selectedModel);

  const {
    filteredModels: filteredByEngineCapacity,
    selectedEngineCapacity,
    onSelection: onEngineCapacitySelection,
    ...engineCapacityProps
  } = useEngineCapacity(filteredModels, pageState.selectedEngineCapacity);

  const selectedVehicleId =
    filteredByEngineCapacity && filteredByEngineCapacity.length > 0
      ? filteredByEngineCapacity[0].id
      : pageState.selectedVehicleId;

  const handleSubmit = () => {
    const newState = {
      ...pageState,
      selectedManufacturer,
      selectedYear,
      selectedModel,
      selectedEngineCapacity,
      selectedVehicleId,
    };
    setPageState({ ...newState, isCompleted: true });
    setPageCompleted(true);
  };

  const navigate = useNavigate();

  if (bikeModelProps.isError || yearSelectionProps.isError || manufacturerProps.isError) {
    navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
  }

  if (pageCompleted) {
    return <Navigate to={quoteRoutes.BikeDetails.path} />;
  }

  if (isAnnualCCPaymentInProgress) {
    return <Navigate to={policyRoutes.Payment.path} />;
  }

  const onManufacturerChangeWrapper = (newValue: Manufacturer | null) => {
    onEngineCapacitySelection && onEngineCapacitySelection(null, true);
    onModelSelection && onModelSelection(null, true);
    onYearSelection && onYearSelection(null, true);
    onManufacturerSelection && onManufacturerSelection(newValue);
  };

  const onYearSelectionWrapper: BikeSelectionFormProps["yearSelectionProps"]["onSelection"] = (newValue) => {
    onEngineCapacitySelection && onEngineCapacitySelection(null, true);
    onModelSelection && onModelSelection(null, true);
    onYearSelection && onYearSelection(newValue);
  };

  const onModelSelectionWrapper: BikeSelectionFormProps["bikeModelProps"]["onSelection"] = (newValue) => {
    onEngineCapacitySelection && onEngineCapacitySelection(null, true);
    onModelSelection && onModelSelection(newValue);
  };

  return (
    <BikeSelectionForm
      manufacturerProps={{
        ...manufacturerProps,
        onSelection: onManufacturerChangeWrapper,
        selectedManufacturer,
      }}
      yearSelectionProps={{
        ...yearSelectionProps,
        onSelection: onYearSelectionWrapper,
        selectedYear,
      }}
      bikeModelProps={{
        ...bikeModelProps,
        selectedModel,
        onSelection: onModelSelectionWrapper,
      }}
      engineCapacityProps={{
        ...engineCapacityProps,
        selectedEngineCapacity,
        onSelection: onEngineCapacitySelection,
      }}
      onSubmit={handleSubmit}
    />
  );
};

export default BikeSelectionPage;
