import { useBsbLookup, useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useState } from "react";
import { useApiClient, useLookupApiClient } from "../../../../shared/hooks/useApiClient";
import { ApiException, BankAccountPaymentRequest } from "../../../../shared/hooks/useApiClient/ClientProxy";
import { PolicyRoutes, QuoteRoutes } from "../../../../shared/routing/routes.config";
import { AreYouAMemberPageData, BsbLookupResponse, PaymentPageData, TellUsMoreAboutYouPageData } from "../../../types";
import { OnSubmitBankAccountEvent } from "../../components/BankAccount";

export interface UseBankAccountResults {
  isError: boolean;
  onSubmit: (event: OnSubmitBankAccountEvent) => void | Promise<void>;
  onBsbLookup: (bsbNumber: string) => Promise<BsbLookupResponse>;
}

export const useBankAccount = (): UseBankAccountResults => {
  const apiClient = useApiClient();
  const setBackdrop = useSetBackdrop();
  const [, setPaymentState] = useSessionState<PaymentPageData>();
  const [isError, setIsError] = useState(false);
  const tellUsMoreAboutYou = useGetSessionState<TellUsMoreAboutYouPageData>(PolicyRoutes.PersonalInformation);
  const areYouAMember = useGetSessionState<AreYouAMemberPageData>(QuoteRoutes.MemberDetails);

  const replaceIOSDash = (accountName: string): string => accountName.replace(/—/g, "-");

  const replaceAngledApostrophe = (accountName: string): string => accountName.replace(/’/g, "'");

  const removeTrailingApostrophe = (accountName: string): string => {
    while (accountName.substr(accountName.length - 1) === "'") {
      accountName = accountName.substring(0, accountName.length - 1);
    }

    return accountName;
  };

  const sanitizeAccountName = (accountName: string): string => {
    accountName = replaceIOSDash(accountName);
    accountName = replaceAngledApostrophe(accountName);
    accountName = removeTrailingApostrophe(accountName);
    return accountName;
  };

  const handleSubmit = async (event: OnSubmitBankAccountEvent): Promise<void> => {
    await payWithBankAccount({
      frequency: event.paymentFrequency,
      accountDetails: {
        bsb: event.bsb.trim(),
        accountNumber: event.accountNumber.trim(),
        accountName: event.accountName.trim(),
      },
    });
  };

  const payWithBankAccount = async (paymentRequest: BankAccountPaymentRequest) => {
    setBackdrop(true);
    setIsError(false);

    try {
      paymentRequest.accountDetails.accountName = sanitizeAccountName(paymentRequest.accountDetails.accountName);
      const { result } = await apiClient.bankaccountpayment(paymentRequest);

      setPaymentState({
        policyNumber: result.policyNumber,
        riderFirstName: tellUsMoreAboutYou.firstName
          ? tellUsMoreAboutYou.firstName
          : areYouAMember.firstName
          ? areYouAMember.firstName
          : "",
        isSuccessful: true,
        isCompleted: true,
      });
    } catch (exception) {
      const errorResponse = exception as ApiException;
      if (errorResponse.status >= 500) {
        setIsError(true);
      }
    } finally {
      setBackdrop(false);
    }
  };

  const lookupApiClient = useLookupApiClient();
  const { onBsbLookup } = useBsbLookup({ apiClient: lookupApiClient });

  return {
    isError,
    onSubmit: handleSubmit,
    onBsbLookup,
  };
};

export default useBankAccount;
