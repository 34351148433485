import { useGetSessionState } from "raci-react-library";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Cover } from "../../../../shared/hooks/useApiClient/ClientProxy";
import { FormotivQuoteNumber, formotivQuoteNumber } from "../../../../shared/recoil/atoms";
import { POLICY_CONFIRM_POLICY_DETAILS_PAGE_URL, QuoteRoutes } from "../../../../shared/routing/routes.config";
import { QuoteFormProps, YourQuoteState } from "../../types";

export const useYourQuote = (): QuoteFormProps => {
  const navigate = useNavigate();
  const yourQuotePageState = useGetSessionState<YourQuoteState>(QuoteRoutes.YourQuote);
  const [hasQuoteNumberBeenPreviouslyPushedToFormotiv, setPushQuoteNumberState] =
    useRecoilState<FormotivQuoteNumber>(formotivQuoteNumber);

  const quoteNumber = yourQuotePageState.quoteNumber;

  const form = useForm<YourQuoteState>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const quoteCards =
    yourQuotePageState.covers?.map((cover: Cover) => ({
      coverType: cover.coverType,
      title: cover.title,
      excess: cover.selectedExcess,
      excesses: cover.availableExcesses,
      annualCost: cover.premiumDetails.annual.total,
      monthlyCost: cover.premiumDetails.monthly.installment,
      maxSumInsured: cover.sumInsured?.max,
      minSumInsured: cover.sumInsured?.min,
      sumInsured: cover.sumInsured?.value,
      discounts: cover.discounts || [],
      basic: cover.premiumDetails.annual.basic,
      governmentCharges: cover.premiumDetails.annual.governmentCharges,
      gst: cover.premiumDetails.annual.gst,
      monthlyTotal: cover.premiumDetails.monthly.total,
      premiumDetails: {
        monthly: {
          ...cover.premiumDetails.monthly,
          instalment: cover.premiumDetails.monthly.installment,
        },
        annual: cover.premiumDetails.annual,
      },
      onChooseThisCoverClick: () => {
        navigate(POLICY_CONFIRM_POLICY_DETAILS_PAGE_URL);
      },
    })) || [];

  useEffect(() => {
    if (window.formotivConfig?.state && !hasQuoteNumberBeenPreviouslyPushedToFormotiv.hasBeenPushed) {
      window.formotivConfig.state.ptv1 = quoteNumber;
      setPushQuoteNumberState({ hasBeenPushed: true });
    }
  }, [hasQuoteNumberBeenPreviouslyPushedToFormotiv.hasBeenPushed, quoteNumber, setPushQuoteNumberState]);

  return {
    form,
    quoteNumber,
    quoteCards,
  };
};

export default useYourQuote;
