import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaAutocomplete } from "@racwa/react-components";
import { Control, Controller, FieldError } from "react-hook-form";
import { RequiredSelection } from "../../../../shared/constants/helperText";

export const inputId = "year-input";
export const inputContainerId = `${inputId}-container`;

export interface YearSelectionProps {
  data: number[];
  selectedYear?: number | null;
  onSelection?: (year: number | null, resetList?: boolean | false) => void;
  isLoading?: boolean | false;
  isError?: boolean | false;
  control?: Control<any>;
  error?: FieldError;
}

const YearSelection = ({ data, selectedYear, isLoading, onSelection, control, error }: YearSelectionProps) => {
  const isEmptyOrUndefined = data.length === 0;
  const isOnlyOneItem = data.length === 1;
  const isDisabled = !isLoading && (isEmptyOrUndefined || isOnlyOneItem);

  return (
    <Controller
      control={control}
      name="selectedYear"
      defaultValue={selectedYear}
      rules={{
        required: {
          value: !isDisabled,
          message: RequiredSelection,
        },
      }}
      render={({ field: { ref, onBlur, onChange, ...props } }) => (
        <RacwaAutocomplete
          {...props}
          id={inputId}
          data-testid={inputContainerId}
          label="Year"
          aria-label="Year"
          labelProps={{ "data-testid": "formlabel-year-selection" }}
          placeholder="YYYY"
          options={data}
          autoSelect={isOnlyOneItem}
          getOptionLabel={(option) => option.toString()}
          autoComplete={true}
          autoHighlight={true}
          clearOnEscape={true}
          loading={isLoading}
          disabled={isDisabled}
          value={selectedYear}
          textFieldProps={{ name: inputId, "data-testid": inputId }}
          inputMode="numeric"
          onChange={(event, year: number | null) => {
            window.formotivConfig &&
              window.formotiv?.inputActivity?.(window.formotivConfig, "selectedYear", "autocomplete", year);
            onSelection && onSelection(year);
            onChange && onChange(event);
          }}
          onBlur={() => {
            gtm(fieldTouched("Year"));
            onBlur && onBlur();
          }}
          error={!!error}
          helperText={error?.message as string}
          inputRef={ref}
        />
      )}
    />
  );
};

export default YearSelection;
