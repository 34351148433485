import { Link, Typography } from "@mui/material";
import { theme } from "@racwa/react-components";

export interface ImportantInfoStatementProps {
}

export const ImportantInfoStatement = ({  }: ImportantInfoStatementProps) => {
  return (
    <Typography variant="body2" sx={{ color: theme.palette.secondary.light, fontWeight: 400, paddingTop: "24px" }}>
      Please remember that this is a quote only and does not provide cover. The final information you provide to RAC
      Insurance may affect the terms and premium we offer.
      <br />
      <br />
      It is important that you read and understand the{" "}
      <Link
        id="pdsLink"
        data-testid="pdsLink"
        variant="inherit"
        color="inherit"
        href={process.env.REACT_APP_RAC_PDS_URL}
        target="_blank"
        rel="noreferrer noopener"
      >
        Product Disclosure Statement (PDS)
      </Link>{" "}
      to ensure the cover meets your needs. Please contact us if you have any difficulty understanding the{" "}
      <Link
        id="pdsLink1"
        data-testid="pdsLink1"
        variant="inherit"
        color="inherit"
        href={process.env.REACT_APP_RAC_PDS_URL}
        target="_blank"
        rel="noreferrer noopener"
      >
        PDS
      </Link>{" "}
      or your quote.
    </Typography>
  );
};
