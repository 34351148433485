import React from "react";
import { RecoilValue, useRecoilValue } from "recoil";

export interface RecoilValueConnectorProps extends React.ComponentProps<any> {
  component: React.ComponentType<any>;
  recoilValue: RecoilValue<any>;
}

const RecoilValueConnector = ({ component: Component, recoilValue, ...props }: RecoilValueConnectorProps) => {
  const stateValue = useRecoilValue(recoilValue);
  return <Component {...props} {...stateValue} />;
};

export default RecoilValueConnector;
