import { useGetSessionState, useSessionStateInitialiser } from "raci-react-library";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { atom, useRecoilState } from "recoil";
import { YourQuoteState } from "../../../views/YourQuote/types";
import { PaymentPageData } from "../../../views/types";
import { SESSION_KEY_PREFIX } from "../../constants";
import {
  PolicyRoutes,
  QuoteRoutes,
  RouteInformation,
  allRoutes,
  policyRoutesArray,
  quoteRoutesArray,
} from "../routes.config";

interface InternalRouteStates {
  hasReachedPurchaseFlow: boolean;
}

const sessionStorageKey = `${SESSION_KEY_PREFIX}Internal_Route_State`;
const defaultInternalRouteStates: InternalRouteStates = JSON.parse(
  sessionStorage.getItem(sessionStorageKey) ?? JSON.stringify({ hasReachedPurchaseFlow: false }),
) as InternalRouteStates;

const internalRouteStates = atom<InternalRouteStates>({
  key: "InternalRouteStates",
  default: defaultInternalRouteStates,
});

export interface StepInformation {
  name: string;
  path: string;
}

export interface UseRoutesResults {
  isQuoteOrPurchaseFlow: boolean;
  hasReachedPurchaseFlow: boolean;
  steps: StepInformation[];
  formRoutes: RouteInformation[];
  activeStepIndex?: number;
  totalStepCount?: number;
  showPurchaseFlow: () => void;
  isInQuoteFlow: boolean;
  isQuoteCompleted: boolean;
  isPaymentInProgress: boolean;
  isCompleted: boolean;
  isSuccessful: boolean;
  navigateToPreviousStep?: () => void;
}

export const useRoutes = (): UseRoutesResults => {
  const location = useLocation();
  const navigate = useNavigate();

  const initialiseSessionStates = useSessionStateInitialiser();

  useEffect(() => {
    initialiseSessionStates(
      allRoutes.map(({ key, path }) => ({
        page: key,
        path,
      })),
    );
  }, [initialiseSessionStates, location.pathname]);
  const [internalState, setInternalState] = useRecoilState(internalRouteStates);
  const { hasReachedPurchaseFlow } = internalState;
  const isQuoteOrPurchaseFlow =
    [...quoteRoutesArray, ...policyRoutesArray].filter((item) => item.path === location.pathname).length > 0;
  const isInQuoteFlow = quoteRoutesArray.filter((item) => item.path === location.pathname).length > 0;
  const { isCompleted: isQuotePageCompleted } = useGetSessionState<YourQuoteState>(QuoteRoutes.YourQuote);
  const { isAnnualCCPaymentInProgress, isCompleted, isSuccessful } = useGetSessionState<PaymentPageData>(
    PolicyRoutes.Payment,
  );
  const quote = useGetSessionState<YourQuoteState>(QuoteRoutes.YourQuote);

  const hasQuoteNumber = !!quote?.quoteNumber;

  const steps: StepInformation[] = useMemo(() => {
    return (
      (hasQuoteNumber && [...allRoutes]) ||
      (quoteRoutesArray.find((route) => route.path === location.pathname) && [...quoteRoutesArray]) ||
      (policyRoutesArray.find((route) => route.path === location.pathname) && [...policyRoutesArray]) ||
      []
    );
  }, [hasQuoteNumber, location.pathname]);

  const activeStepIndex = isQuoteOrPurchaseFlow
    ? steps.findIndex((item) => item.path === location.pathname)
    : undefined;
  const totalStepCount = isQuoteOrPurchaseFlow ? steps.length : undefined;

  const showPurchaseFlow = useCallback(() => {
    if (!internalState.hasReachedPurchaseFlow) {
      const newState = { ...internalState, hasReachedPurchaseFlow: true };
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(newState));
      setInternalState(newState);
    }
  }, [setInternalState, internalState]);

  const previousIndex = allRoutes.findIndex((route) => route.path === location.pathname) - 1;
  const navigateToPreviousStep =
    previousIndex > -1
      ? () => {
          navigate(allRoutes[previousIndex].path, { replace: true });
        }
      : undefined;

  return {
    isQuoteOrPurchaseFlow,
    hasReachedPurchaseFlow,
    steps,
    formRoutes: allRoutes,
    activeStepIndex,
    totalStepCount,
    isInQuoteFlow,
    isQuoteCompleted: isQuotePageCompleted ?? false,
    isPaymentInProgress: isAnnualCCPaymentInProgress ?? false,
    isCompleted: isCompleted ?? false,
    isSuccessful: isSuccessful ?? false,
    showPurchaseFlow,
    navigateToPreviousStep,
  };
};

export default useRoutes;
