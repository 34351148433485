import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardContent, Collapse, Grid, Link, Typography } from "@mui/material";
import { FinePrint } from "@racwa/react-components";
import React, { useState } from "react";
import { Loadable } from "recoil";
import CoverTooltip from "../../../../shared/components/CoverTooltip";
import { TellUsMoreAboutYourBikeRefData } from "../../../../shared/hooks/useApiClient/ClientProxy";
import AboutYou from "../AboutYou";
import AdditionalDetails from "../AdditionalDetails";
import BikeUsage from "../BikeUsage";
import MoreAboutYou from "../MoreAboutYou";
import QuoteDetails from "../QuoteDetails";
import YourBike from "../YourBike";
import { StyledDivider } from "./styled";

export interface QuoteInformationBoxData {
  title: string;
  coverType: string;
  isEditLocked?: boolean;
  tellUsMoreAboutYourBikeDetails: Loadable<TellUsMoreAboutYourBikeRefData>;
}

export interface QuoteInformationBoxProps extends QuoteInformationBoxData {}

export const QuoteInformationBox = ({
  title,
  coverType,
  isEditLocked,
  tellUsMoreAboutYourBikeDetails,
}: QuoteInformationBoxProps) => {
  const tooltipId = "coverDetailsTooltip";
  const racwaContainerId = "racwaContainer";

  const [open, setOpen] = useState<boolean>(false);

  const handleCollapseClick = (status: boolean) => {
    setOpen(status);
  };

  const renderText = open ? "Hide information your quote is based on" : "See information your quote is based on";

  const renderIcon = open ? (
    <FontAwesomeIcon icon={faSortUp} aria-hidden style={{ verticalAlign: "bottom" }} />
  ) : (
    <FontAwesomeIcon icon={faSortDown} aria-hidden style={{ verticalAlign: "bottom" }} />
  );

  return (
    <Card variant="outlined" sx={{ borderColor: "grey.500" }}>
      <CardContent>
        <Grid container id={racwaContainerId} data-testid={racwaContainerId} spacing={1} alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h3">{title}</Typography>
          </Grid>
          <Grid container xs={2} textAlign="center" justifyContent="flex-end">
            <CoverTooltip id={tooltipId} coverType={coverType} disablePagination={true} />
          </Grid>
          <Grid item xs={12}>
            <YourBike isEditLocked={isEditLocked} />
          </Grid>
          <Grid item xs={12}>
            <StyledDivider variant="middle" />
          </Grid>
          <Grid item xs={12}>
            <QuoteDetails isEditLocked={isEditLocked} />
          </Grid>
          <Grid item xs={12}>
            <StyledDivider variant="middle" />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} textAlign="center">
            <Link
              id="quoteSummaryLink"
              data-testid="quoteSummaryLink"
              variant="inherit"
              color="inherit"
              href="#"
              underline="none"
              onClick={(e: React.SyntheticEvent<Element, Event>) => {
                e.preventDefault();
                handleCollapseClick && handleCollapseClick(!open);
              }}
            >
              {renderText} {renderIcon}
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Grid container spacing={1} alignItems="center" data-testid="quoteSummaryContainer">
                <Grid item xs={12}>
                  <BikeUsage
                    isEditLocked={isEditLocked}
                    tellUsMoreAboutYourBikeDetails={tellUsMoreAboutYourBikeDetails}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledDivider variant="middle" />
                </Grid>
                <Grid item xs={12}>
                  <AboutYou isEditLocked={isEditLocked} />
                </Grid>
                <Grid item xs={12}>
                  <StyledDivider variant="middle" />
                </Grid>
                <Grid item xs={12}>
                  <AdditionalDetails isEditLocked={isEditLocked} />
                </Grid>
                <Grid item xs={12}>
                  <StyledDivider variant="middle" />
                </Grid>
                <Grid item xs={12}>
                  <MoreAboutYou isEditLocked={isEditLocked} />
                </Grid>
                <Grid item xs={12}>
                  <FinePrint sx={{ marginTop: "10px" }}>
                    Price based on the bike, excess, insured value and cover chosen and information provided. Please
                    ensure all details are correct and meet your needs.
                  </FinePrint>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default QuoteInformationBox;
