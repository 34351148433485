import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Link, Typography } from "@mui/material";
import { fieldTouched, gtm, phoneCall } from "@racwa/analytics";
import { RacwaAutocomplete, TooltipProps } from "@racwa/react-components";
import { colors } from "@racwa/styles";
import { InsurancePhoneNumber } from "raci-react-library";
import { useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { pleaseEnterAValidMessage } from "../../../../shared/constants/helperText";
import { AddressSummary } from "../../../../shared/hooks/useApiClient/ClientProxy";

export const inputId = "mailingAddress-input";
export const inputContainerId = `${inputId}-container`;
const inputFieldName = "mailingAddress";
const tooltipId = `${inputId}-tooltip`;
const tooltipButtonId = `${tooltipId}-button`;

export interface MailingAddressProps {
  error?: boolean;
  helperText?: string;
  data: AddressSummary[];
  selectedValue: AddressSummary | null;
  label?: string | null;
  isError?: boolean | false;
  isLoading?: boolean | false;
  onSelection: (address: AddressSummary | null) => void;
  onInputChange: (value: string) => void;
  tooltipProps?: TooltipProps;
}

const maxInputLength: number = 150;

export const MailingAddress = ({
  error = false,
  helperText = "",
  data = [],
  selectedValue,
  label,
  isLoading,
  onSelection,
  onInputChange,
  tooltipProps,
}: MailingAddressProps) => {
  const { control } = useFormContext();
  const [open, setOpen] = useState(false);

  const tooltipMessage = (
    <Typography>
      Please enter the address where you prefer to receive mail. If it doesn't appear, try entering fewer details (e.g.
      remove the unit number). If you can't find your address please call us on{" "}
      <Link href={InsurancePhoneNumber.href} onClick={() => gtm(phoneCall("131703"))}>
        {InsurancePhoneNumber.display}
      </Link>{" "}
      with your quote number.
    </Typography>
  );

  const RacIconButton = useMemo(
    () => (
      <IconButton
        data-testid={tooltipButtonId}
        size="small"
        aria-label="show tooltip"
        arial-label="anchor"
        sx={{ padding: "0 0 0 3px", width: "20px" }}
      >
        <FontAwesomeIcon
          role="presentation"
          style={{ color: colors.dieselDeeper, fontSize: "20px" }}
          icon={faQuestionCircle}
          aria-hidden
        />
      </IconButton>
    ),
    [],
  );

  const defaultTooltip: TooltipProps = {
    id: tooltipId,
    open: open,
    title: "Mailing address",
    message: tooltipMessage,
    onClickClose: () => setOpen(false),
    onClick: () => setOpen(!open),
    children: RacIconButton,
  };

  const toolTip = tooltipProps ?? defaultTooltip;

  return (
    <Controller
      control={control}
      name={inputFieldName}
      defaultValue={selectedValue}
      rules={{
        required: {
          value: true,
          message: pleaseEnterAValidMessage("address"),
        },
      }}
      render={({ field: { ref, ...props } }) => (
        <RacwaAutocomplete
          {...props}
          id={inputId}
          data-testid={inputContainerId}
          label={label ?? "Mailing address"}
          placeholder="Unit/house number street name, suburb"
          options={data}
          getOptionLabel={(option) => (option as AddressSummary)?.displayAddress}
          freeSolo={!data.length}
          fullWidth
          tooltipProps={toolTip}
          filterOptions={(o) => o}
          loading={isLoading}
          autoComplete
          autoHighlight
          clearOnEscape
          value={selectedValue}
          textFieldProps={{
            name: inputId,
            "data-testid": inputId,
            inputProps: {
              maxLength: maxInputLength,
              autoComplete: "street-address",
            },
          }}
          error={error}
          helperText={helperText}
          onChange={(_, value) => {
            window.formotivConfig &&
              window.formotiv?.inputActivity?.(
                window.formotivConfig,
                "mailingAddress",
                "autocomplete",
                (value as AddressSummary)?.displayAddress,
              );
            onSelection && onSelection(value as AddressSummary);
            props.onChange(value);
          }}
          onInputChange={(_, value) => {
            onInputChange && onInputChange(value);
          }}
          onBlur={() => {
            props.onBlur && props.onBlur();
            gtm(fieldTouched("Mailing address"));
          }}
          data-hj-suppress
          inputRef={ref}
        />
      )}
    />
  );
};

export default MailingAddress;
