import { useEffect, useState } from "react";
import useApiClient from "../../../../shared/hooks/useApiClient";

export const useQuestions = (isMemberMatched: boolean) => {
  const apiClient = useApiClient();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [forceTitle, setForceTitle] = useState(false);
  const [forceGender, setForceGender] = useState(false);

  useEffect(() => {
    let isUnmounted = false;

    const fetchQuestionInformation = async () => {
      if (!isUnmounted) {
        setIsLoading(true);
        try {
          const response = await apiClient.gettellmoreaboutyouquestions();
          const questionInformation = response.result;
          setForceTitle(questionInformation.title.isRequired);
          setForceGender(questionInformation.gender.isRequired);
        } catch {
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      }
    };

    if (isMemberMatched) {
      fetchQuestionInformation();
    }

    return () => {
      isUnmounted = true;
    };
  }, [apiClient, isMemberMatched]);

  return {
    isLoading,
    isError,
    forceTitle,
    forceGender,
    isMemberMatched,
  };
};

export default useQuestions;
