import { Grid } from "@mui/material";
import { AccountNameInput, AccountNumberInput, BsbInput as BSB } from "raci-react-library";
import { PaymentFrequency } from "../../../../shared/hooks/useApiClient/ClientProxy";
import { BsbLookupResponse } from "../../../types";

export interface BankAccountData {
  bsb: string;
  accountNumber: string;
  accountName: string;
}

export interface OnSubmitBankAccountEvent extends BankAccountData {
  paymentFrequency: PaymentFrequency;
}

export interface BankAccountProps {
  onBsbLookup: (bsbNumber: string) => Promise<BsbLookupResponse>;
}

export const BankAccount = (props: BankAccountProps) => {
  return (
    <div data-testid="bankAccountContainer" data-quickstream-api="bankAccountContainer" data-hj-suppress>
      <Grid item xs={12}>
        <BSB onBsbLookup={props.onBsbLookup} />
      </Grid>
      <Grid item xs={12}>
        <AccountNumberInput />
      </Grid>
      <Grid item xs={12}>
        <AccountNameInput />
      </Grid>
    </div>
  );
};

export default BankAccount;
