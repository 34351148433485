import { Link, Typography } from "@mui/material";
import { gtm, phoneCall } from "@racwa/analytics";
import { RacwaCardNotification } from "@racwa/react-components";
import { FindAnInsurer, Raci13PhoneNumber } from "../../constants/helperText";

export interface DeclinedUsageCardNotificationProps {
  show?: boolean | null;
}
export const DeclinedUsageCardNotification = ({ show = true, ...props }: DeclinedUsageCardNotificationProps) => {
  return show ? (
    <RacwaCardNotification
      {...props}
      data-testid="declinedUsageCardTest"
      title="Sorry, we've hit a roadblock."
      severity="error"
      useColour={true}
    >
      <Typography variant="inherit">
        We're unable to insure motorcycles that are hired out.
        <br />
        If you'd like to discuss this with us, including why we made this decision, you can call us on{" "}
        <Link href={Raci13PhoneNumber.href} onClick={() => gtm(phoneCall("131703"))} noWrap>
          {Raci13PhoneNumber.display}
        </Link>
        {"."}
        <br />
        <br />
        To help you find appropriate cover,{" "}
        <Link
          id="find-an-insurer-link"
          data-testid="find-an-insurer-link"
          href={FindAnInsurer.href}
          target="_blank"
          rel="noreferrer noopener"
          component="a"
          color="primary"
        >
          {FindAnInsurer.display}
        </Link>{" "}
        (from the Insurance Council of Australia) has a list of insurance providers offering general insurance.
      </Typography>
    </RacwaCardNotification>
  ) : null;
};

export default DeclinedUsageCardNotification;
