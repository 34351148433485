import { MaxDateOfBirthAge, MinDateOfBirthAge } from "raci-react-library";
import { useState } from "react";
import { ReferenceDataItem, RiderExperience } from "../../../../shared/hooks/useApiClient/ClientProxy";

const riderExperienceList: ReferenceDataItem[] = Object.keys(RiderExperience).map((key) => {
  const name = RiderExperience[key as keyof typeof RiderExperience];
  return { externalCode: name, description: name };
});

const getAge = (dateOfBirth: Date) => {
  const today = new Date();
  let age = today.getFullYear() - dateOfBirth.getFullYear();
  const monthDiff = today.getMonth() - dateOfBirth.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dateOfBirth.getDate())) {
    age--;
  }
  return age;
};

const getValidRiderExperienceList = (dateOfBirth: Date | null) => {
  const age = dateOfBirth ? getAge(dateOfBirth) : MaxDateOfBirthAge;
  const allowedSelections = Math.min(Math.max(age - MinDateOfBirthAge + 1, 0), riderExperienceList.length);

  return [...riderExperienceList].slice(0, allowedSelections);
};

export interface UseRiderExperienceResult {
  list: ReferenceDataItem[];
  onDateOfBirthChange: (dateOfBirth: Date | null) => void;
}

export const useRiderExperience = (dateOfBirth?: Date | null): UseRiderExperienceResult => {
  const [list, setList] = useState<ReferenceDataItem[]>(getValidRiderExperienceList(dateOfBirth || null));

  const onDateOfBirthChange = (dob: Date | null) => {
    setList(getValidRiderExperienceList(dob));
  };

  return {
    list,
    onDateOfBirthChange,
  };
};

export default useRiderExperience;
