import { Button, Grid } from "@mui/material";
import {
  AuthorisationCard,
  LayoutVariant,
  PaymentFormDisclaimerDisplay,
  PaymentFrequency as PaymentFrequencyInterface,
  PaymentFrequencyToggle,
  useFormotiv
} from "raci-react-library";
import React, { useEffect, useRef, useState } from "react";
import { DeepMap, FieldError, FormProvider, UseFormReturn } from "react-hook-form";
import { Loadable } from "recoil";
import { RequiredSelectionMessage } from "../../shared/constants/helperText";
import {
  Cover,
  PaymentMethod as PaymentMethodOptions,
  TellUsMoreAboutYourBikeRefData,
} from "../../shared/hooks/useApiClient/ClientProxy";
//import useFormotiv from "../../shared/hooks/useFormotiv";
import { BsbLookupResponse } from "../types";
import BankAccount, { BankAccountData, OnSubmitBankAccountEvent } from "./components/BankAccount";
import CreditCard from "./components/CreditCard";
import PaymentMethod from "./components/PaymentMethod";
import QuoteInformationBox from "./components/QuoteInformationBox";
import { FORMOTIV_CONFIG } from "../../shared/constants";

export interface PaymentFormData {
  paymentFrequency: PaymentFrequencyInterface | null;
  paymentMethod: PaymentMethodOptions | null;
  trustedFrame: React.MutableRefObject<any | null>;
  paymentFormDisclaimerDisplay?: boolean | undefined;
}

export interface PaymentFormHandlers {
  onSubmit?: () => void | Promise<void>;
  onPaymentFrequencyChange: (paymentFrequency: PaymentFrequencyInterface | null) => void | Promise<void>;
  onPaymentMethodChange: (paymentMethod: PaymentMethodOptions | null) => void | Promise<void>;
  onSubmitCreditCard: (paymentFrequency: PaymentFrequencyInterface) => void;
  onSubmitBankAccount: (event: OnSubmitBankAccountEvent) => void | Promise<void>;
  onBsbLookup: (bsbNumber: string) => Promise<BsbLookupResponse>;
}

export type PaymentFormProps = PaymentFormData &
  PaymentFormHandlers & {
    form: UseFormReturn<PaymentFormData & BankAccountData>;
    modelDescription: string;
    cover?: Cover;
    showAuthorisationCard: boolean;
    isCreditCardFormFilledOut: boolean;
    isEditLocked?: boolean;
    tellUsMoreAboutYourBikeDetails: Loadable<TellUsMoreAboutYourBikeRefData>;
  };

const PaymentForm = ({
  form,
  cover,
  paymentFrequency,
  paymentMethod,
  onSubmit = () => void 0,
  onPaymentMethodChange,
  onPaymentFrequencyChange,
  onSubmitCreditCard,
  onSubmitBankAccount,
  showAuthorisationCard,
  isEditLocked,
  tellUsMoreAboutYourBikeDetails,
  onBsbLookup,
}: PaymentFormProps) => {
  const [isFirstTimeValid, setIsFirstTimeValid] = useState(false);
  const [paymentFrequencyState, setPaymentFrequencyState] = useState(
    paymentFrequency === PaymentFrequencyInterface.Annual
      ? PaymentFrequencyInterface.Annual
      : PaymentFrequencyInterface.Monthly,
  );

  const {
    formState: { errors },
    control,
    setValue,
    formState,
    getValues,
    setError,
    watch,
    handleSubmit,
  } = form;

  const cardErrors = errors as DeepMap<PaymentFormData, FieldError>;
  const bankAccountWatch = watch(["bsb", "accountNumber", "accountName"]);
  const paymentMethodWatch = watch("paymentMethod");
  const paymentFrequencyWatch = watch("paymentFrequency");
  const isCardPaymentMethod = paymentMethodWatch === PaymentMethodOptions.Card;
  const isBankAccountPaymentMethod = paymentMethodWatch === PaymentMethodOptions.Bank_account;

  const displayCreditCard = <CreditCard />;
  const displayBankAccount = <BankAccount onBsbLookup={onBsbLookup} />;

  const displayPaymentMethodContainer = () => {
    if (paymentMethod === null) {
      return <></>;
    } else if (paymentMethod === PaymentMethodOptions.Card) {
      return displayCreditCard;
    } else if (paymentMethod === (paymentMethod as PaymentMethodOptions.Bank_account)) {
      return displayBankAccount;
    }
  };

  const formRef = useRef(null);

  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);

  const handleValidSubmit = (async () => {
    if (paymentFrequencyState) {
      if (isCardPaymentMethod) {
        onSubmitCreditCard && onSubmitCreditCard(paymentFrequencyState);
      } else if (isBankAccountPaymentMethod) {
        onSubmitBankAccount &&
          (await onSubmitBankAccount({
            ...(getValues() as BankAccountData),
            paymentFrequency: paymentFrequencyState,
          }));
      }
      onSubmit && onSubmit();
    }
    onSubmit && onSubmit();
  });

  const handleInvalidSubmit = async () => {
    const { paymentFormDisclaimerDisplay } = getValues();
    if (paymentFormDisclaimerDisplay && isCardPaymentMethod) {
      paymentFrequencyState === PaymentFrequencyInterface.Annual &&
        onSubmitCreditCard(paymentFrequencyState || PaymentFrequencyInterface.Annual);
    }
  };

  const handlePaymentMethodChange = (method: PaymentMethodOptions | null) => {
    onPaymentMethodChange && onPaymentMethodChange(method);
    resetFormState(method);
    setIsFirstTimeValid(false);

    if (
      method === PaymentMethodOptions.Bank_account &&
      formState.dirtyFields.bsb &&
      formState.dirtyFields.accountNumber &&
      formState.dirtyFields.accountName &&
      bankAccountWatch[0] &&
      bankAccountWatch[1] &&
      bankAccountWatch[2] &&
      !errors.bsb &&
      !errors.accountNumber &&
      !errors.accountName
    ) {
      setIsFirstTimeValid(isFirstTimeValid || true);
    }
  };

  const resetFormState = (method: PaymentMethodOptions | null) => {
    if (!method) {
      setError("paymentMethod", { message: RequiredSelectionMessage || "" });
    }
    if (errors.paymentFrequency) {
      setError("paymentFrequency", { message: RequiredSelectionMessage || "" });
    }
  };

  useEffect(() => {
    if (
      paymentMethodWatch === PaymentMethodOptions.Bank_account &&
      formState.dirtyFields.bsb &&
      formState.dirtyFields.accountNumber &&
      formState.dirtyFields.accountName &&
      bankAccountWatch[0] &&
      bankAccountWatch[1] &&
      bankAccountWatch[2] &&
      !errors.bsb &&
      !errors.accountNumber &&
      !errors.accountName
    ) {
      setIsFirstTimeValid(true);
    }
  }, [bankAccountWatch, formState, errors, paymentMethodWatch]);

  return (
    <FormProvider {...form}>
      <form ref={formRef} action="#" onSubmit={(handleSubmit(formotivOnSubmitWrapper(handleValidSubmit), handleInvalidSubmit))} autoComplete="on">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <QuoteInformationBox
              title={cover?.title || ""}
              coverType={cover?.coverType || ""}
              isEditLocked={isEditLocked}
              tellUsMoreAboutYourBikeDetails={tellUsMoreAboutYourBikeDetails}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "16px" }}>
            <PaymentFrequencyToggle
              variant={LayoutVariant.Stacked}
              {...{
                annual: {
                  total: cover?.premiumDetails.annual.total || 0,
                  gst: cover?.premiumDetails.annual.gst || 0,
                  basic: cover?.premiumDetails.annual.basic || 0,
                  governmentCharges: cover?.premiumDetails.annual.governmentCharges || 0,
                },
                monthly: {
                  instalment: cover?.premiumDetails.monthly.installment || 0,
                  total: cover?.premiumDetails.monthly.total || 0,
                  gst: cover?.premiumDetails.monthly.gst || 0,
                  basic: cover?.premiumDetails.monthly.basic || 0,
                  governmentCharges: cover?.premiumDetails.monthly.governmentCharges || 0,
                },
              }}
              isAnnualFrequency={paymentFrequencyState === PaymentFrequencyInterface.Annual}
              setFrequency={setPaymentFrequencyState}
              disabled={isEditLocked}
              onChange={() => {
                setValue(
                  "paymentFrequency",
                  paymentFrequencyState === PaymentFrequencyInterface.Annual
                    ? PaymentFrequencyInterface.Annual
                    : PaymentFrequencyInterface.Monthly,
                );
                onPaymentFrequencyChange(
                  paymentFrequencyWatch === PaymentFrequencyInterface.Annual
                    ? PaymentFrequencyInterface.Annual
                    : PaymentFrequencyInterface.Monthly,
                );
              }}
              showLabel
            />
          </Grid>
          <Grid item xs={12}>
            <PaymentMethod
              paymentMethod={paymentMethod}
              control={control}
              setValue={setValue}
              paymentMethodError={cardErrors.paymentMethod}
              paymentMethodSelectionHandler={handlePaymentMethodChange}
              disabled={isEditLocked}
            />
          </Grid>
          <Grid item xs={12}>
            {displayPaymentMethodContainer()}
          </Grid>
          {(isFirstTimeValid || showAuthorisationCard) && (
            <Grid item xs={12} sx={{ paddingTop: "30px" }}>
              <AuthorisationCard
                paymentFrequency={paymentFrequencyState || ""}
                paymentMethod={paymentMethod || ""}
                pdsUrl={process.env.REACT_APP_RAC_PDS_URL}
                ddrsaUrl={process.env.REACT_APP_RAC_DDRSA_URL}
              />
              <PaymentFormDisclaimerDisplay paymentFrequency={paymentFrequencyState} paymentMethod={paymentMethod} />
            </Grid>
          )}
          <Grid item xs={12} sx={{ paddingTop: "20px" }}>
            <Button
              type="submit"
              data-testid="submit"
              color="primary"
              variant="contained"
              fullWidth={true}
              disabled={formState.isSubmitting}
            >
              Purchase policy
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default PaymentForm;
