import { FormControl, FormHelperText, FormLabel, Grid } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { fieldTouched, gtm } from "@racwa/analytics";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { RequiredSelectionMessage } from "../../../../shared/constants/helperText";

export interface GenderProps {
  error: boolean;
  helperText: string;
  value: string | null;
  show?: boolean;
}

export const Gender = ({ error = false, helperText = "", value, show }: GenderProps) => {
  const { setValue, control } = useFormContext();

  useEffect(() => {
    if (!show) {
      setValue("gender", null);
    }
  }, [control, show, setValue]);

  return (
    <Controller
      control={control}
      aria-label="Gender"
      name="gender"
      data-testid="gender"
      defaultValue={value}
      rules={{
        required: { value: show || false, message: RequiredSelectionMessage },
      }}
      render={({ field: { ref, onChange, onBlur, ...props } }) => {
        return show ? (
          <Grid item xs={11}>
            <FormControl error={error}>
              <FormLabel>What gender do you most identify with?</FormLabel>
              <ToggleButtonGroup
                {...props}
                exclusive
                onChange={(_, value) => {
                  onChange(value);
                  setValue(props.name, value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                onBlur={() => {
                  onBlur && onBlur();
                  gtm(fieldTouched("Gender"));
                }}
              >
                <ToggleButton
                  type="button"
                  role="button"
                  value="Male"
                  aria-label="Male"
                  data-testid="maleButton"
                  ref={ref}
                >
                  Male
                </ToggleButton>
                <ToggleButton type="button" role="button" value="Female" aria-label="Female" data-testid="femaleButton">
                  Female
                </ToggleButton>
              </ToggleButtonGroup>

              <FormHelperText>{helperText}</FormHelperText>
            </FormControl>
          </Grid>
        ) : (
          <></>
        );
      }}
    />
  );
};

export default Gender;
