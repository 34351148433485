import { useGetSessionState, useSetBackdrop } from "raci-react-library";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PolicyRoutes,
  QuoteRoutes,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
  policyRoutes,
} from "../../shared/routing/routes.config";
import { YourQuoteState } from "../YourQuote/types";
import { AreYouAMemberPageData, TellUsMoreAboutYouPageData } from "../types";
import { TellUsMoreAboutYouForm, TellUsMoreAboutYouFormData } from "./Form";
import useMailingAddress from "./hooks/useMailingAddress";
import useQuestions from "./hooks/useQuestions";
import { useReferenceData } from "./hooks/useReferenceData";
import { useTellUsMoreAboutYou } from "./hooks/useTellUsMoreAboutYou";
import useTitles from "./hooks/useTitles";

export const TellUsMoreAboutYouPage = () => {
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();

  const [pageCompleted, setPageCompleted] = useState(false);
  const { quoteNumber } = useGetSessionState<YourQuoteState>(QuoteRoutes.YourQuote);
  const state = useGetSessionState<TellUsMoreAboutYouPageData>(PolicyRoutes.PersonalInformation);
  const { areYouAMember } = useGetSessionState<AreYouAMemberPageData>(QuoteRoutes.MemberDetails);
  const referenceData = useReferenceData();

  const mailingAddress =
    (state.mailingAddress && state.mailingAddress.displayAddress && state.mailingAddress) ||
    (areYouAMember && referenceData.postalAddress) ||
    null;

  const { ...titleProps } = useTitles(referenceData.titles, state.title);
  const { ...mailingAddressProps } = useMailingAddress(mailingAddress);
  const { ...questionProps } = useQuestions(areYouAMember || false);
  const { showMoreInfoCard, popupProps, save, isError } = useTellUsMoreAboutYou(() => {
    setPageCompleted(true);
  });

  const isLoading = referenceData.isLoading === true || titleProps.isLoading === true;

  const handleSubmit = async (newState: TellUsMoreAboutYouFormData) => {
    const pageData: TellUsMoreAboutYouPageData = {
      title: newState.title,
      gender: newState.gender,
      firstName: newState.firstName,
      lastName: newState.lastName,
      contactNumber: newState.contactNumber,
      email: newState.email,
      mailingAddress: newState.mailingAddress,
    };
    await save(pageData);
  };

  useEffect(() => {
    setBackdrop(isLoading);

    return () => {
      setBackdrop(false);
    };
  }, [setBackdrop, isLoading]);

  if (mailingAddressProps.isError || referenceData.isError || questionProps.isError || isError) {
    navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
  }

  if (pageCompleted) {
    navigate(policyRoutes.Payment.path);
  }

  return referenceData.isLoading || questionProps.isLoading ? null : (
    <TellUsMoreAboutYouForm
      titleProps={titleProps}
      mailingAddressProps={mailingAddressProps}
      showMoreInfoCard={showMoreInfoCard}
      premiumChangeDialogProps={popupProps}
      quoteNumber={quoteNumber}
      {...state}
      onSubmit={handleSubmit}
      hasContactMatched={areYouAMember || false}
      forceTitleQuestion={questionProps.forceTitle}
      forceGenderQuestion={questionProps.forceGender}
    />
  );
};

export default TellUsMoreAboutYouPage;
