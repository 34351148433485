import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaAutocomplete } from "@racwa/react-components";
import { Control, Controller, FieldError } from "react-hook-form";
import { RequiredSelection } from "../../../../shared/constants/helperText";

export const inputId = "model-input";
export const inputContainerId = `${inputId}-container`;

export interface BikeModelProps {
  data: string[];
  selectedModel: string | null;
  onSelection?: (bikeModel: string | null, resetList?: boolean | false) => void;
  isLoading: boolean;
  control?: Control<any>;
  error?: FieldError;
}

export const BikeModelSelection = ({ onSelection, data, selectedModel, isLoading, control, error }: BikeModelProps) => {
  const isEmptyList = !data || data.length === 0;
  const isSingleValue = !isEmptyList && data.length === 1;
  const isDisabled = !isLoading && (isEmptyList || isSingleValue);

  return (
    <Controller
      control={control}
      name="selectedModel"
      defaultValue={selectedModel}
      rules={{
        required: {
          value: !isDisabled,
          message: RequiredSelection,
        },
      }}
      render={({ field: { ref, onBlur, onChange, ...props } }) => (
        <RacwaAutocomplete
          {...props}
          label="Model"
          placeholder="Start typing your model name"
          id={inputId}
          data-testid={inputContainerId}
          options={data}
          autoSelect={isSingleValue}
          getOptionLabel={(option) => option}
          disabled={isDisabled}
          fullWidth
          loading={isLoading}
          autoComplete
          autoHighlight
          clearOnEscape
          value={selectedModel}
          textFieldProps={{ name: inputId, "data-testid": inputId }}
          onChange={(event, model: string | null) => {
            window.formotivConfig &&
              window.formotiv?.inputActivity?.(window.formotivConfig, "selectedModel", "autocomplete", model);
            onSelection && onSelection(model);
            onChange && onChange(event);
          }}
          onBlur={() => {
            gtm(fieldTouched("Model"));
            onBlur && onBlur();
          }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
        />
      )}
    />
  );
};

export default BikeModelSelection;
