import { Grid } from "@mui/material";
import { FinePrint, RacwaEdit } from "@racwa/react-components";
import { useGetSessionState } from "raci-react-library";
import { Link as RouterLink } from "react-router-dom";
import { QuoteRoutes, quoteRoutes } from "../../../../shared/routing/routes.config";
import format from "../../../../shared/utils/currency";
import { YourQuoteState } from "../../../YourQuote/types";

export interface QuoteDetailsProps {
  isEditLocked?: boolean;
}

export const QuoteDetails = ({ isEditLocked }: QuoteDetailsProps) => {
  const yourQuoteState = useGetSessionState<YourQuoteState>(QuoteRoutes.YourQuote);
  const cover = yourQuoteState.covers?.find(
    (x: { coverType: any }) => x.coverType === yourQuoteState.selectedCoverType,
  );

  const renderInsuredFor =
    yourQuoteState.selectedCoverType === "TPO" ? null : (
      <Grid item xs={12}>
        {" "}
        <FinePrint data-testid="insuredFor">{`Insured for ${format(cover?.sumInsured?.value || 0)}`}</FinePrint>
      </Grid>
    );

  const renderEdit = isEditLocked ? null : (
    <Grid item xs={2} textAlign="right">
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <RacwaEdit link={quoteRoutes.Quote.path} dataTestId="editQuoteDetailsLink" component={RouterLink} />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container spacing={1} alignItems="center" data-testid="quoteDetailsContainer">
      <Grid item xs={10}>
        <Grid container spacing={1} alignItems="center">
          {renderInsuredFor}
          <Grid item xs={12}>
            {" "}
            <FinePrint data-testid="excessSelected">{`Excess ${format(cover?.selectedExcess || 0)}`}</FinePrint>
          </Grid>
        </Grid>
      </Grid>
      {renderEdit}
    </Grid>
  );
};

export default QuoteDetails;
