import { Box, Typography } from "@mui/material";
import { RacwaCardNotification } from "@racwa/react-components";
import { InsurancePhoneNumberLink } from "raci-react-library";
import React from "react";

/* TODO: RACDS-13 card to be provided by the design system library */
export interface NotFoundCardProps extends React.ComponentProps<any> {
  visible?: boolean | false;
  isContactMatchAttemptsExhausted?: boolean | false;
}

interface cardMessage {
  title: string;
  firstParagraph: string;
  secondParagraph?: string;
}

export const defaultMessage: cardMessage = {
  title: "We couldn't find your details",
  firstParagraph: "Please check that you have entered your personal information we have on file correctly.",
  secondParagraph: "If we can't find your details, select Skip below to continue, otherwise you can call us on ",
};

export const attemptsExhaustedMessage: cardMessage = {
  title: "We still couldn't find your details",
  firstParagraph: "Select Skip to continue.",
};

export const NotFoundCard = ({ visible, isContactMatchAttemptsExhausted }: NotFoundCardProps) => {
  const message = isContactMatchAttemptsExhausted ? attemptsExhaustedMessage : defaultMessage;

  return visible ? (
    <RacwaCardNotification data-testid="notFoundCard" severity="warning" title={message.title}>
      <Box marginBottom={1}>
        <Typography variant="body2">{message.firstParagraph}</Typography>
      </Box>
      {message.secondParagraph === defaultMessage.secondParagraph && (
        <Typography variant="body2">
          {message.secondParagraph} <InsurancePhoneNumberLink id="call us link" /> to apply eligible member discounts.
        </Typography>
      )}
    </RacwaCardNotification>
  ) : null;
};

export default NotFoundCard;
