import { faCheck, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, IconButton, Link, Typography } from "@mui/material";
import { colors } from "@racwa/styles";
import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { ComprehensiveTitle, ThirdPartyFireAndTheftTitle, ThirdPartyPropertyTitle } from "../../constants";
import ComprehensiveTooltipContent from "./ComprehensiveTooltipContent";
import TpftTooltipContent from "./TpftTooltipContent";
import TpoTooltipContent from "./TpoTooltipContent";
import { StyledDialogSubheading, StyledPagination, StyledPaginationItem, StyledRacwaDetailedDialog } from "./styled";

export interface CoverTooltipProps {
  id: string;
  coverType: string;
  disablePagination?: boolean;
}

export const CheckMarkIcon = (
  <Grid container item xs={1} justifyContent="center" alignItems="baseline">
    <FontAwesomeIcon icon={faCheck} />
  </Grid>
);

export const CoverTooltip = ({ id, coverType, disablePagination }: CoverTooltipProps) => {
  const maxPageCount = 3;
  const swipeActions = {
    onSwipedRight: () => {
      if (!disablePagination) {
        changeDialoguePage(page === 1 ? maxPageCount : page - 1);
      }
    },
    onSwipedLeft: () => {
      if (!disablePagination) {
        changeDialoguePage(page + 1);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  };
  const subheadingSwipeHandler = useSwipeable(swipeActions);
  const contentSwipeHandler = useSwipeable(swipeActions);
  const paginationSwipeHandler = useSwipeable(swipeActions);

  const getCompDialogData = () => {
    return {
      title: ComprehensiveTitle,
      subHeading: "Our most popular cover for a reason. With the highest level of protection and the most benefits.",
      content: <ComprehensiveTooltipContent />,
      page: 1,
    };
  };

  const getTpftDialogData = () => {
    return {
      title: ThirdPartyFireAndTheftTitle,
      subHeading: "Offers some protection for your bike and damage to other people's property.",
      content: <TpftTooltipContent />,
      page: 2,
    };
  };

  const getTpoDialogData = () => {
    return {
      title: ThirdPartyPropertyTitle,
      subHeading: "Great if your main concern is damage to someone else's property or your budget.",
      content: <TpoTooltipContent />,
      page: 3,
    };
  };

  const getDialogDataFromCoverType = () => {
    if (coverType === "COMP") {
      return getCompDialogData();
    } else if (coverType === "TPFT") {
      return getTpftDialogData();
    } else if (coverType === "TPO") {
      return getTpoDialogData();
    } else {
      return getCompDialogData();
    }
  };

  const getDialogDataFromPage = (page: number) => {
    if (page === 1) {
      return getCompDialogData();
    } else if (page === 2) {
      return getTpftDialogData();
    } else if (page === 3) {
      return getTpoDialogData();
    } else {
      return getCompDialogData();
    }
  };

  const closeAndResetDialogToDefault = () => {
    setOpen(false);
    const coverInformation = getDialogDataFromCoverType();
    setContent(coverInformation.content);
    setSubHeading(coverInformation.subHeading);
    setTitle(coverInformation.title);
    setPage(coverInformation.page);
  };

  const changeDialoguePage = (page: number) => {
    const coverInformation = getDialogDataFromPage(page);
    setContent(coverInformation.content);
    setSubHeading(coverInformation.subHeading);
    setTitle(coverInformation.title);
    setPage(coverInformation.page);
  };

  const [open, setOpen] = useState(false);
  const coverInformation = getDialogDataFromCoverType();
  const [title, setTitle] = useState(coverInformation.title);
  const [subHeading, setSubHeading] = useState(coverInformation.subHeading);
  const [content, setContent] = useState(coverInformation.content);
  const [page, setPage] = useState(coverInformation.page);

  const DialogSubheading = () => (
    <StyledDialogSubheading {...subheadingSwipeHandler} variant="body1" align="center" gutterBottom>
      {subHeading}
    </StyledDialogSubheading>
  );

  const DialogContent = () => (
    <Grid container spacing={2} alignItems="baseline" {...contentSwipeHandler}>
      {content}
      <Grid item xs={11}>
        <Typography variant="body2">
          <b>See</b>{" "}
          <Link
            id="pdsLink"
            data-testid="pdsLink"
            variant="inherit"
            color="inherit"
            href={process.env.REACT_APP_RAC_PDS_URL}
            target="_blank"
            rel="noreferrer noopener"
          >
            Product Disclosure Statement
          </Link>
          <b> for full cover details.</b>
        </Typography>
      </Grid>
    </Grid>
  );

  const DialogPagination = () =>
    disablePagination ? null : (
      <StyledPagination
        {...paginationSwipeHandler}
        count={maxPageCount}
        onChange={(_, val) => changeDialoguePage(val)}
        page={page}
        size="small"
        hideNextButton
        hidePrevButton
        renderItem={(item) => <StyledPaginationItem {...item} />}
      />
    );

  return (
    <>
      <IconButton
        data-testid={`${id}-Button`}
        id={`${id}-Button`}
        size="medium"
        arial-label="anchor"
        onClick={() => setOpen(!open)}
        sx={{ padding: 0 }}
      >
        <FontAwesomeIcon style={{ color: colors.dieselDeeper }} icon={faQuestionCircle} aria-hidden />
      </IconButton>
      <StyledRacwaDetailedDialog
        open={open}
        onClose={() => {
          closeAndResetDialogToDefault();
        }}
        onClickClose={() => {
          closeAndResetDialogToDefault();
        }}
        title={title}
        titleId={`${title}-cover-dialogue`}
        transitionDuration={{ exit: 0 }}
        scroll="body"
        customChildren={[
          {
            dialogueContent: false,
            node: <DialogSubheading />,
          },
          {
            dialogueContent: true,
            node: <DialogContent />,
          },
          {
            dialogueContent: false,
            node: <DialogPagination />,
          },
        ]}
      />
    </>
  );
};

export default CoverTooltip;
