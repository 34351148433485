import { FormControl, FormHelperText, FormLabel } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { fieldTouched, gtm } from "@racwa/analytics";
import { Controller, useFormContext } from "react-hook-form";
import { RequiredSelectionMessage } from "../../../../shared/constants/helperText";
import { TitleReferenceDataItem } from "../../../../shared/hooks/useApiClient/ClientProxy";

export interface TitleProps {
  error?: boolean | null;
  data: TitleReferenceDataItem[] | null;
  title: string | null;
  gender?: string | null;
  isLoading?: boolean | false;
  titleSelectionHandler: (title: string | null) => void;
}

export const Titles = ({ error = false, data, title, titleSelectionHandler }: TitleProps) => {
  const { setValue, control } = useFormContext();

  return (
    <>
      <Controller
        control={control}
        name="title"
        defaultValue={title}
        rules={{
          required: {
            value: true,
            message: RequiredSelectionMessage,
          },
        }}
        render={({
          field: { ref, onBlur, onChange: onComponentChange, ...props },
          fieldState: { error: fieldStateError },
        }) => (
          <FormControl error={!!error}>
            <FormLabel htmlFor="titleButtonGroup" data-testid="formlabelDateOfBirthSelection">
              Title
            </FormLabel>
            <ToggleButtonGroup
              {...props}
              onChange={(_, value) => {
                window.formotivConfig &&
                  window.formotiv?.inputActivity?.(window.formotivConfig, "title", "buttongroup", value);
                onComponentChange && onComponentChange(value);
                titleSelectionHandler(value);
                setValue(props.name, value, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              onBlur={() => {
                onBlur && onBlur();
                gtm(fieldTouched("Title"));
              }}
              exclusive
              id="titleButtonGroup"
              data-testid="titleButtonGroup"
              aria-label="titleButtonGroup"
            >
              {data?.map((title: TitleReferenceDataItem, index: number) => {
                return (
                  <ToggleButton
                    type="button"
                    role="button"
                    key={title.externalCode}
                    value={title.externalCode}
                    data-testid={`title-option-${title.description}`}
                    ref={index === 0 ? ref : null}
                  >
                    {title.description}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
            <FormHelperText>{fieldStateError?.message || ""}</FormHelperText>
          </FormControl>
        )}
      />
    </>
  );
};

export default Titles;
