import { Grid, SxProps, Typography } from "@mui/material";
import { FinePrint, RacwaEdit, theme } from "@racwa/react-components";
import { format } from "date-fns";
import { useGetSessionState } from "raci-react-library";
import { Link as RouterLink } from "react-router-dom";
import { PolicyRoutes, QuoteRoutes, policyRoutes } from "../../../../shared/routing/routes.config";
import { AreYouAMemberPageData, TellUsMoreAboutYouPageData } from "../../../types";

export interface MoreAboutYouProps {
  isEditLocked?: boolean;
}

export const MoreAboutYou = ({ isEditLocked }: MoreAboutYouProps) => {
  const tellUsMoreAboutYouPageData = useGetSessionState<TellUsMoreAboutYouPageData>(PolicyRoutes.PersonalInformation);

  const areYouAMemberPageData = useGetSessionState<AreYouAMemberPageData>(QuoteRoutes.MemberDetails);

  const renderName = areYouAMemberPageData.areYouAMember
    ? areYouAMemberPageData.firstName
    : `${tellUsMoreAboutYouPageData.title} ${tellUsMoreAboutYouPageData.firstName} ${tellUsMoreAboutYouPageData.lastName}`;

  const renderContactNumber = areYouAMemberPageData.areYouAMember
    ? areYouAMemberPageData.contactNumber
    : tellUsMoreAboutYouPageData.contactNumber;

  const greyOutStyle: SxProps | undefined = areYouAMemberPageData.areYouAMember
    ? { color: areYouAMemberPageData.areYouAMember ? theme.palette.grey[500] : undefined }
    : undefined;

  const renderGender = tellUsMoreAboutYouPageData.gender ? (
    <Grid item xs={10}>
      <FinePrint sx={greyOutStyle}>{tellUsMoreAboutYouPageData.gender}</FinePrint>
    </Grid>
  ) : null;

  const renderEmail = areYouAMemberPageData.areYouAMember
    ? areYouAMemberPageData.email
    : tellUsMoreAboutYouPageData.email;

  const renderEdit = isEditLocked ? null : (
    <Grid item xs={2} textAlign="right">
      <RacwaEdit link={policyRoutes.TellUsMoreAboutYou.path} dataTestId="editMoreAboutYouLink" component={RouterLink} />
    </Grid>
  );

  const dateOfBirth = format(new Date(areYouAMemberPageData.dateOfBirth ?? new Date()), "dd/MM/yyyy");

  const renderDateOfBirth = areYouAMemberPageData.areYouAMember ? (
    <Grid item xs={10}>
      <FinePrint sx={greyOutStyle} data-hj-suppress>{`Date of birth ${dateOfBirth}`}</FinePrint>
    </Grid>
  ) : null;

  return (
    <Grid container spacing={1} alignItems="center" data-testid="aboutMoreAboutYouContainer" data-hj-suppress>
      <Grid item xs={12}>
        <Typography variant="h4">More about you</Typography>
      </Grid>
      <Grid item xs={10}>
        <FinePrint data-testid="name" sx={greyOutStyle} data-hj-suppress>
          {renderName}
        </FinePrint>
      </Grid>
      {renderGender}
      {renderDateOfBirth}
      <Grid item xs={10}>
        <FinePrint sx={greyOutStyle}>{renderContactNumber}</FinePrint>
      </Grid>
      {!areYouAMemberPageData.areYouAMember ? renderEdit : null}
      <Grid item xs={10}>
        <FinePrint sx={greyOutStyle}>{renderEmail}</FinePrint>
      </Grid>
      <Grid item xs={10}>
        <FinePrint data-hj-suppress>{tellUsMoreAboutYouPageData.mailingAddress?.displayAddress}</FinePrint>
      </Grid>
      {areYouAMemberPageData.areYouAMember ? renderEdit : null}
    </Grid>
  );
};

export default MoreAboutYou;
